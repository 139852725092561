<div class="steps">
	<div class="step-item active-icon">
		<i class="icon i-manager"></i>
	</div>
		<span class="step-divider" *ngIf="isManagActive"></span>
	<div class="step-item" *ngIf="isManagActive" 
		[ngClass]="{ 'active-icon' : currStepNo >= 1 && isManagActive}">
		<i class="icon i-employee"></i>
	</div>
	<span class="step-divider"></span>
	<div class="step-item" 
		[ngClass]="{ 'active-icon' : (currStepNo == 1 && !isManagActive) || currStepNo == 2 }">
		<i class="icon i-cog"></i>
	</div>
	<div class="active-step">{{ currStepTitle }}</div>
</div>
<form class="form_case" [formGroup]="caseForm">
	<!-- page 1 -->
	<div *ngIf="currStepNo == 0" class="step" data-step="1" data-title="Manager Details">
		<div class="input-row clearfix">
			<input type="text" formControlName="firstName" name="firstName" placeholder="First Name">
			<input type="text" formControlName="contactNo" name="contactNo" placeholder="Contact Number" required>
		</div>
		<div class="input-row clearfix">
			<input type="text" formControlName="lastName" name="lastName" placeholder="Last Name" required>
			<input type="text" formControlName="email" name="email" placeholder="Email address" required>
		</div>
	</div>
	<!-- page 2 -->
	<div *ngIf="currStepNo == 1 && isManagActive" class="step" data-step="2" data-title="Employee Details">
		<div class="input-row clearfix">
			<input type="text" 
				formControlName="firstName_man" 
				name="firstName_man" 
				placeholder="Employee First Name">
			<input type="text" 
				formControlName="contactNo_man" 
				name="contactNo_man" 
				placeholder="Contact Number">
		</div>
		<div class="input-row clearfix">
			<input type="text" 
				formControlName="lastName_man" 
				name="lastName_man" 
				placeholder="Employee Last Name">
			<input type="text" 
				formControlName="email_man" 
				name="email_man" 
				placeholder="Email address">
		</div>
		<!--
		<div class="input-row clearfix">
			<input type="text" name="" placeholder="Employee Number" required>
			<input type="text" name="" placeholder="Date of Birth" required>
		</div>
		<div class="input-row clearfix">
			<input type="text" name="" placeholder="Employee Id Number" required>
		</div>-->										
	</div>				
	<!-- page 3 -->
	<div *ngIf="currStepNo == 2" class="step" data-step="3" data-title="Request for your Service">

		<!-- ALL options -->
		<div *ngIf="!isCISDActive" class="trauma-selection clearfix">
			<p>Select your required service</p>
			<div class="input-item">
				<i class="icon i-psychological"></i>
				<label>Mental Health
					<input type="radio" 
						value="EAPP057" 
						formControlName="primaryReason" 
						name="primaryReason">
				</label>
			</div>
			<div class="input-item">
				<i class="icon i-trauma"></i>
				<label>Trauma
					<input type="radio" 
						value="EAPP061" 
						formControlName="primaryReason" 
						name="primaryReason">
				</label>
			</div>
			<div class="input-item">
				<i class="icon i-legal"></i>
				<label>Legal
					<input type="radio" 
						value="EAPP056" 
						formControlName="primaryReason" 
						name="primaryReason">
				</label>
			</div>
			<div class="input-item">
				<i class="icon i-finance"></i>
				<label>Financial
					<input type="radio" 
						value="EAPP052" 
						formControlName="primaryReason" 
						name="primaryReason">
				</label>
			</div>

			<div class="input-item">
				<i class="icon i-relationship"></i>
				<label>Relationship
					<input type="radio" 
						value="EAPP058" 
						formControlName="primaryReason" 
						name="primaryReason">
				</label>
			</div>
			<div class="input-item">
				<i class="icon i-family"></i>
				<label>Family
					<input type="radio" 
						value="EAPP051" 
						formControlName="primaryReason" 
						name="primaryReason">
				</label>
			</div>
			<div class="input-item">
				<i class="icon i-work_related"></i>
				<label>Work related
					<input type="radio" 
						value="EAPP062" 
						formControlName="primaryReason" 
						name="primaryReason">
				</label>
			</div>
			<div class="input-item">
				<i class="icon i-health_lifestyle"></i>
				<label>Health & lifestyle
					<input type="radio" 
						value="EAPP053" 
						formControlName="primaryReason" 
						name="primaryReason">
				</label>
			</div>						
		</div>
		
		<!-- CISD options ONLY -->
		<div *ngIf="isCISDActive" class="trauma-selection clearfix">
			<p>Select your required service</p>
			<div class="input-item">
				<i class="icon i-trauma"></i>
				<label>Trauma
					<input type="radio" 
						value="EAPP061" 
						formControlName="primaryReason" 
						name="primaryReason">
				</label>
			</div>					
		</div>

		<!--
		<div class="trauma-dropdown">
			<select class="dd-traumaselect" formControlName="secondReason" name="secondReason">
				<option disabled value="null">Please select the type of psychological issue</option>
				<option value="EAPS386">Anger Management</option>
				<option value="EAPS389">Depression</option>
				<option value="EAPS392">Identity problems</option>
				<option value="EAPS395">Panic attacks</option>				
			</select>
		</div>-->
		<div class="consent-toggle">
			<label class="switch">
				<input type="checkbox" formControlName="consentToggle" name="consentToggle">
				<span class="slider round"></span>
			</label>
			<!-- I hereby give consent to {{isNedBank && isManagActive?'be contacted by the EWP Care Centre team':isNedBank?'be contacted by the EWP team':'request for EAP services'}} 					 -->
			{{isNedBank && isManagActive?'I hereby confirm that the employee has consented to be contacted by the EWP Care Centre team':isNedBank?'I hereby consent to be contacted by the EWP Care Centre team':'I hereby give consent to request for EAP services'}}
		</div>
	</div>
	<!-- page 4 / success -->
	<div *ngIf="currStepNo == 3" class="step" data-step="4">
		<div class="success-block">
			<i class="icon i-check_circle"></i>
			<h4>Success</h4>
			<p>Your request has been submitted succcessfully to our {{isNedBank?'EWP':'EAP'}} call center and a call center agent will be in contact.</p>
		</div>
	</div>				
</form>
<p *ngIf="errorMsgActive" class="red-error-text">
	We could not create your case. Please contact your {{isNedBank?'EWP':'EAP'}} contact centre for assistance with your case.
</p>
<div class="loader" *ngIf="isLoading"></div>
<div class="pagination" [hidden]="isLoading">
	<button class="btn prev" 
		*ngIf="currStepNo > 0" 
		(click)="goToStep('b')" 
		[hidden]="formSuccess == true">previous</button>
	<button class="btn next" 
		[disabled]="caseForm.status == 'INVALID'" 
		(click)="goToStep('n')" 
		[hidden]="currStepNo > 1 || formSuccess == true">next</button>
	<button class="btn submit" 
		*ngIf="currStepNo > 1" 
		(click)="createCase()" 
		[disabled]="caseForm.status == 'INVALID'" 
		[hidden]="formSuccess == true">submit</button>
	<button class="btn start-again" 
		*ngIf="formSuccess" 
		(click)="resetFormData()">do another case</button>		
</div>