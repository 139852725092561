<div class="calendar-events-container clearfix">
	<!--<div class="calendar-widget fRight"></div>-->
	
	<!-- [minDate]="minDate" 
		 [maxDate]="maxDate" -->

	<mat-calendar 
		#calendar 
		class="calendar-widget fRight" 
		[selected]="selectedDate" 
		[startAt]="startAt" 
		[dateFilter]="myDateFilter"
		(selectedChange)="onSelect($event)" 
		(monthSelected)="monthSelected($event)">
	</mat-calendar>

	<div class="events fRight">
		<div class="title-header">
			<!--<div class="sub-title" *ngIf="todayTitle.length > 0">{{ todayTitle }}</div>-->
			<div class="title">{{ today }}</div>
		</div>

		<div class="loader" *ngIf="isLoading"></div>

		<p *ngIf="noTodayCases" class="align-center">* You don't have appointments today</p>

		<!-- Calendar Entry -->
		<div class="cal-entry" 
			*ngFor="let item of calData; let i = index" 
			[ngClass]="{ 'open' : item.hideme == true }" 
			[hidden]="isLoading">
			<img src="../assets/img/icons/generic/arrow.svg" class="arrow" alt=""  />
			<div class="top-details clearfix" (click)="getFollowUpData(i,item.RequestID)">
				<div class="time">
					{{ item.StartDate == null ? 'TBA': item.StartDate | date: 'HH:mm' }}
				</div>
				<div class="name">
					<label><b>Case: {{ item.caseNo }}</b></label>
					<div class="cont-details">
						<span>Service: {{ item.serviceType }}</span>
						<!--<span *ngIf="item.email"> - </span>
						<span>{{ item.email }}</span>-->
					</div>					
				</div>
			</div>
			<div class="bottom-details" [hidden]="!item.hideme">
				<div class="case-title" *ngIf="item.Status != 'REALIZED'">
					<b>Location of session:</b> 
					{{ item.location == null ? 'TBA': item.location }}
				</div>
				<div class="case-title" *ngIf="item.Status == 'REALIZED'">
					<b>Session completed at:</b>
					{{ item.location == null ? 'TBA': item.location }}
				</div>
				<!--<p>{{ item.location }}</p>-->
				<div class="timeline">
					<div class="case-date">
						<div class="data">
							<div class="status-img">
								<img src="../assets/img/icons/cfu/case_complete_circle.svg" 
									*ngIf="item.Status == 'REALIZED'" 
									alt="" />
								<img src="../assets/img/icons/cfu/case_pending_circle.svg" 
									*ngIf="item.Status != 'REALIZED'" 
									alt="" />											
							</div>
							<div class="date" *ngIf="item.StartDate && item.CreatedDate">
								{{ item.StartDate | date: 'dd' }}/{{ item.StartDate | date: 'MM' }}/{{ item.StartDate | date: 'yyyy' }}
							</div>
							<div class="date" *ngIf="item.CreatedDate && !item.StartDate">
								*Date N/A
							</div>									
						</div>
						<div class="divider">
							<!--
							<img src="../assets/img/icons/cfu/case_pending_line.svg" alt="" />
							-->
						</div>
					</div>

					<!--
					<p *ngIf="caseFUSessions.length == 0">
						* There are currently no follow-up sessions available for this person
					</p>							
					<div class="case-date" *ngFor="let fus of caseFUSessions; let j = index">
						<div class="data" 
							(mouseover)="checkLocation(j)" 
							(mouseout)="deactivateActiveStates()" 
							[ngClass]="{ 'hover-active': isHoverActive == true }">
							<div class="status-img">
								<img src="../assets/img/icons/cfu/case_complete_circle.svg" 
									*ngIf="fus.Status == 'REALIZED'" 
									alt="" />
								<img src="../assets/img/icons/cfu/case_pending_circle.svg" 
									*ngIf="fus.Status != 'REALIZED'" 
									alt="" />											
							</div>
							<div class="date" *ngIf="fus.StartDate && fus.CreatedDate">
								{{ fus.StartDate | date: 'dd' }}/{{ fus.StartDate | date: 'MM' }}/{{ fus.StartDate | date: 'yyyy' }}
							</div>
							<div class="date" *ngIf="fus.CreatedDate && !fus.StartDate">
								*Date N/A
							</div>									
						</div>
						<div class="divider">
							<img *ngIf="j != caseFUSessions.length - 1" 
								src="../assets/img/icons/cfu/case_pending_line.svg" 
								alt="" />
						</div>
					</div>-->



				</div>
			</div>
		</div>


	</div>
</div>