<div class="home-top clearfix">

	<!-- Carousel -->
	<div class="carousel fLeft">
		
		<div (click)="loadView('well-being-score')" class="wellscore " [ngClass]="{'score-card-arrow ': !isWellnessView}">
	
			<h4>Wellbeing score</h4>
			<p>Your current lifestyle, health and stress factors:</p>
			<div class="set-size charts-container">
				<div class="pie-wrapper progress-{{score}} style-2">
					<span class="label" style="font-size: 16px;padding-top: 45px;font-weight: bold;color: navy;">Your Wellbeing</span>
					 <span class="label-{{labelScore}}">{{scoreRate}}</span> 
					<div class="pie">
					  <div class="left-side half-circle"></div>
					  <div class="right-side half-circle"></div>
					</div>
					<div class="shadow"></div>
				  </div>
			</div>
		</div>
		<br>
		<div class="loader" [hidden]="hdData.length > 0"></div>		
		<owl-carousel-o [options]="owlOptions" #owlCar>
			<ng-container *ngFor="let i of hdData">
				<ng-template carouselSlide [id]="i.id">
					<img [src]="i.image" [alt]="i.title" [title]="i.title">
					<h4 innerHTML="{{ i.title }}"></h4>
					<p innerHTML="{{ i.descrip }}"></p>
					<div class="read-more-btn">
						<button class="btn" (click)="openHDPost(i.link)">Read more</button>
					</div>					
				</ng-template>
			</ng-container>
		</owl-carousel-o>
		<div class="carousel-arrows" *ngIf="hdData.length > 0">
			<a class="arrow" (click)="owlCar.prev()">&lsaquo;</a>
			<a class="arrow fRight" (click)="owlCar.next()">&rsaquo;</a>
		</div>		
	</div>
	<!--Start E-Learning Card -->
	<div  (click)="loadView('e-learning')" *ngIf="showElearning">
	  <div class="elearning fRight elearning-card-arrow">
	    <!-- <h4>E-Learning</h4> -->
	    <img  class="elearning_intro" src="../../../assets/img/icons/elearning/e-learning intro.png" alt="intro">
	  </div>
	</div>
	<!--End E-Learning Card -->
	<div class="notification-container fRight">
		<!-- inject calendar widget here -->
		<app-calendar></app-calendar>
	</div> <!-- ./ notification-container -->
	<br>
</div>
<div class="home-bottom"></div>