<div class="login-banner">
	<!--<img src="../assets/img/health_profilebanner.png" alt="">-->
</div>
<div class="health-profile-hero">
	<h1>welcome back!</h1>
	<p>Complete the form below by choosing a new password.</p>
</div>
<div *ngIf="isInvalidUrl == true">
	<br /><br /><br />
	<p class="red-error-text center-text">
		You are using an incorrect link. Please check your inbox for the correct link.
	</p>
	<br /><br /><br />
</div>
<div *ngIf="tokenExpired == true">
	<br /><br /><br />
	<p class="red-error-text center-text">
		The link you are using has expired. <br /><br />
		Go to <strong><a routerLink="/renewpass">this page</a></strong> and click on <strong>"I forgot my password"</strong> to resend your activation email.
	</p>
	<br /><br /><br />
</div>
<div class="reg-form" [hidden]="hideForm">
	<!-- form -->
	<div class="step1" *ngIf="currStep == 1" [hidden]="isInvalidUrl == true">
		<form class="passwordForm" [formGroup]="passwordForm">
			<div class="input-row clearfix">
				<input type="password" formControlName="password_one" name="password_one" placeholder="New password" 
				[ngClass]="{ 'success-password-match' : passwordsMatch == true }">
				<input type="password" formControlName="password_two" name="password_two" placeholder="Confirm new password" 
				[ngClass]="{ 'success-password-match' : passwordsMatch == true }">
			</div>
		</form>
		<p class="red-error-text">{{ passMatchTxt }}</p>
		<p class="passhints" (click)="isPassHintsOpen = !isPassHintsOpen">
			<span *ngIf="isPassHintsOpen == false">></span> 
			<span *ngIf="isPassHintsOpen == true">v</span> 
			Password hints
		</p>
		<p *ngIf="isPassHintsOpen == true" class="password-warning">
			<strong>Please ensure your password contains:</strong><br>
			- at least 10 characters<br>
			- of which one is a capital letter<br>
			<!-- - and at least one should be a numeric character -->
			- and one is a special character e.g: !@#$%&*_
		</p>
		<div class="loader" *ngIf="isLoading"></div>
		<p *ngIf="userExists == false" class="red-error-text">
			Your username does not exist in our system. Please refer to your reset password email for further instructions.
		</p>	
		<p *ngIf="userValidation == false" class="red-error-text">
			Your new password does not comply to password complexity rules. Please refer to your reset password hints for further instructions.
		</p>	
		<div [hidden]="isLoading">
			<button class="submit-btn btn" 
				(click)="setPassword()" 
				[disabled]="disableSubmit != true">Submit</button>
		</div>

	</div>
	<!-- success screen -->
	<div class="step step2" *ngIf="currStep == 2" [hidden]="isInvalidUrl == true">
		<div class="success-block">
			<i class="icon i-check_circle"></i>
			<h4>Success!</h4>
			<p>You have successfully set your password. <a routerLink="/login">Click here</a> to login.</p>
		</div>		
	</div>
</div>