import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '../../shared/global-data';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {

	isNedBank:boolean=false;

	constructor(private globalService: GlobalDataService,private lsService: StorageMap) { }

	ngOnInit() {
		this.globalService.changeCurrentViewState('faqs');
		/* lets get profile data */
		this.lsService.get('profileData').subscribe((lsData) => {
			if(lsData !=undefined)
			this.isNedBank = lsData["isNedBank"]
		})
	}

}
