import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { GlobalDataService } from '../../../../shared/global-data';
import { CasesService } from '../../../../shared/services/cases.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { distinctUntilChanged } from 'rxjs/operators';
import * as _util from '../../../../shared/utilities';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

	caseForm: FormGroup;
	formSuccess: boolean = false;
	getEmployeeGuid: string;
	getEmployerGuid: string;
	errorMsgActive: boolean = false;
	isManagActive: boolean = false;
	isCISDActive: boolean = false;
	isNedBank:boolean = false;
	stepData: any = {
		self: {
			steps: [
				{ title: 'Employee Details', icon: 'employee' },
				{ title: 'Request for your service', icon: 'cog' }
			]
		},
		managerial: {
			steps: [
				{ title: 'Manager Details', icon: 'manager' },
				{ title: 'Employee Details', icon: 'employee' },
				{ title: 'Request for your service', icon: 'cog' }
			]			
		}
	}
	currStepNo: number = 0;
	currStepTitle: string = this.stepData.self.steps[0].title;
	isLoading: boolean = false;
	manager_name: string;
	manager_surname: string;
	manager_number: string;
	manager_email: string;

	constructor(private fb: FormBuilder, 
		private globalData: GlobalDataService,
		private caseServ: CasesService,
		private lsService: StorageMap) { }

	ngOnInit() {

	    /* ------------------ BASE FORM ------------------- */
	    this.caseForm = this.fb.group({
			firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+(-[a-zA-Z]+)*$')]],
			firstName_man: [''],
			lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+(-[a-zA-Z]+)*$')]],
			lastName_man: [''],
			contactNo: ['',[Validators.required, Validators.minLength(10), Validators.pattern('^[0-9]*$')]],
			contactNo_man: [''],
			email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
			email_man: [''],
			primaryReason: [''],
			consentToggle: [null]
	    });

		this.globalData.tBlockStep.pipe(distinctUntilChanged()).subscribe(val => {
			this.isManagActive = false;
			this.isCISDActive = false;
			this.setTitle(0);
			this.resetFormData();
			this.prePopulatePFData();
			if(val == 2){ // managerial referral
				this.isManagActive = true;
			} else if(val == 3){ // CISD
				this.isCISDActive = true;
			} else {}
		});

        /* lets get synergy data and set GUIDs */
        this.globalData.synergyData.subscribe(gsData => {
            this.lsService.get('synergyData').subscribe((lsData) => {
                if(typeof lsData !== 'undefined'){
					this.getEmployeeGuid = lsData['getGuid1'];
					this.getEmployerGuid = lsData['getGuid2'];
				} else {
                    if(gsData != null){
						this.getEmployeeGuid = gsData['getGuid1'];
						this.getEmployerGuid = gsData['getGuid2'];						
                    }                    
                }
            });
        });		

		this.caseForm.get('consentToggle').valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
			if(val == null){
				this.caseForm.get('consentToggle').setErrors(null);
			}
			if(!val && val != null){
				this.caseForm.get('consentToggle').setErrors({'incorrect': true});			
			}
		})
		this.lsService.get('profileData').subscribe((lsData) => {
			if(lsData !=undefined)
			this.isNedBank = lsData["isNedBank"]
		})
		// track active class for Service radio btn selection
		let inputItems = ".trauma-selection .input-item label";
		$('body').on("click", inputItems, function(){
			let activeClass = 'active-tSelection';
			$(inputItems).parent().removeClass(activeClass);
			$(this).parent().addClass(activeClass);
		});
	} /* onInit End */

	prePopulatePFData(){
		this.lsService.get('loginData').subscribe((data) => {
			// get field data
			this.manager_name = data["firstname"];
			this.manager_surname = data["surname"];
			this.manager_email = data["emailAddress"];
			this.manager_number = data["cellularNumber"];
			let sanitizeCellNo = this.manager_number.replace(/ /g,'');
			sanitizeCellNo = sanitizeCellNo.replace('+27','0');
			// set control values
			this.caseForm.controls['firstName'].setValue(this.manager_name);
			this.caseForm.controls['lastName'].setValue(this.manager_surname);
			this.caseForm.controls['contactNo'].setValue(sanitizeCellNo);
			this.caseForm.controls['email'].setValue(this.manager_email);

			this.disableFields();	
		});
	}

	disableFields(){
		this.caseForm.controls['firstName'].disable();
		this.caseForm.controls['lastName'].disable();
		this.caseForm.controls['contactNo'].disable();
		this.caseForm.controls['email'].disable();
	}

	enableFields(){
		this.caseForm.controls['firstName'].enable();
		this.caseForm.controls['lastName'].enable();
		this.caseForm.controls['contactNo'].enable();
		this.caseForm.controls['email'].enable();
	}	

	resetFormData(){
		this.errorMsgActive = false;
		this.formSuccess = false;
		this.currStepNo = 0;
		this.caseForm.reset();
		this.caseForm.controls['firstName_man'].clearValidators();
		this.caseForm.controls['lastName_man'].clearValidators();
		this.caseForm.controls['contactNo_man'].clearValidators();
		this.caseForm.controls['email_man'].clearValidators();
		this.caseForm.controls['primaryReason'].clearValidators();
		this.caseForm.controls['consentToggle'].clearValidators();
		this.caseForm.controls['primaryReason'].updateValueAndValidity();
		this.caseForm.controls['consentToggle'].updateValueAndValidity();
		this.caseForm.controls['firstName_man'].updateValueAndValidity();
		this.caseForm.controls['lastName_man'].updateValueAndValidity();
		this.caseForm.controls['contactNo_man'].updateValueAndValidity();
		this.caseForm.controls['email_man'].updateValueAndValidity();
		if(this.isManagActive){ // pre populate LS data
			//this.prePopulatePFData();
		} else { // enable input fields
			//this.enableFields();
		}
		this.prePopulatePFData();
	}

	setTitle(row){
		if(this.isManagActive){
			this.currStepTitle = this.stepData.managerial.steps[row].title;
		} else {
			this.currStepTitle = this.stepData.self.steps[row].title;
		}
	}

	createCase(){
		this.isLoading = true;
		this.errorMsgActive = false;
		let caseObj = {
			name: this.caseForm.get('firstName').value,
			surname: this.caseForm.get('lastName').value,
			contactNumber: this.caseForm.get('contactNo').value,
			email: this.caseForm.get('email').value,
			primaryReason: this.caseForm.get('primaryReason').value,
			employerGUID: this.getEmployerGuid,
			defaultValue: "259"
		}
		// set GUID and API endpoint type
		let type;
		if(this.isManagActive){
			caseObj["managerGUID"] = this.getEmployeeGuid;
			caseObj.name = this.caseForm.get('firstName_man').value;
			caseObj.surname = this.caseForm.get('lastName_man').value;
			caseObj.contactNumber = this.caseForm.get('contactNo_man').value;
			caseObj.email = this.caseForm.get('email_man').value;
			type = 'fr';
		} else {
			if(this.isCISDActive){
				type = 'cisd';
			} else {
				type = 'sr';
			}
			caseObj["employeeGUID"] = this.getEmployeeGuid;
		}
		this.caseServ.createCase(caseObj,type).subscribe((resp) => {
			if(resp.statusCodeValue == 200){
				this.isLoading = false;
				this.currStepNo++;
				this.formSuccess = true;
				// now add new case (in the background) to LS var
				this.addRecordToLSData(type);
			} else {
				this.isLoading = false;
				this.errorMsgActive = true;				
			}
		}, err => {
			this.isLoading = false;
			this.errorMsgActive = true;
		})
	}

	addRecordToLSData(type){
		this.caseServ.getCases(type).subscribe(cases => {
			let res = cases.body.results;
			if(type == 'sr'){
				type = 'eap';
			} else if(type == 'fr'){
				type = 'managerial';
			}
			if(res.length > 0){
				this.lsService.set('maincases_'+type,res).subscribe();
				setTimeout(() => {
					let tBlockStep: number;
					if(type == 'cisd'){
						tBlockStep = 3;
					} else if(type == 'managerial'){
						tBlockStep = 2;
					} else {
						tBlockStep = 1;
					}
					this.globalData.changeTBlockState(tBlockStep);
				}, 3000);
			}
		});
	}

	activateFinalPageValidation(){
		this.caseForm.controls['primaryReason'].setValidators(Validators.required);
		this.caseForm.controls['consentToggle'].setValidators(Validators.required);
		this.caseForm.controls['primaryReason'].updateValueAndValidity();
		this.caseForm.controls['consentToggle'].updateValueAndValidity();		
	}

	handleStep2Validation(activeValidation){
		if(activeValidation){
			this.caseForm.controls['firstName_man'].setValidators(Validators.required);
			this.caseForm.controls['lastName_man'].setValidators(Validators.required);
			this.caseForm.controls['contactNo_man'].setValidators([Validators.required, 
				Validators.minLength(10),
				Validators.pattern('^[0-9]*$')]);
			this.caseForm.controls['email_man'].setValidators([Validators.required,
				Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]);				
		} else {
			this.caseForm.controls['firstName_man'].clearValidators();
			this.caseForm.controls['lastName_man'].clearValidators();
			this.caseForm.controls['contactNo_man'].clearValidators();
			this.caseForm.controls['email_man'].clearValidators();
			this.caseForm.controls['consentToggle'].clearValidators();
		}
		this.caseForm.controls['firstName_man'].updateValueAndValidity();
		this.caseForm.controls['lastName_man'].updateValueAndValidity();
		this.caseForm.controls['contactNo_man'].updateValueAndValidity();
		this.caseForm.controls['email_man'].updateValueAndValidity();
		this.caseForm.controls['consentToggle'].updateValueAndValidity();
	}

	goToStep(d){
		if(d == 'n'){
			if(!this.isManagActive){
				this.currStepNo = 2;
				this.setTitle(1);
				this.activateFinalPageValidation();
			} else {
				this.currStepNo++;
				if(this.currStepNo == 1){
					setTimeout(() => { this.handleStep2Validation(true) }, 400);
				}
				if(this.currStepNo == 2){
					setTimeout(() => { this.activateFinalPageValidation() }, 400);
				}
				this.setTitle(this.currStepNo);
			}
		} else {
			if(!this.isManagActive){ // not managerial
				this.currStepNo = 0;
				this.resetFormData();
			} else { // managerial
				this.currStepNo--;
				if(this.currStepNo == 0){
					this.resetFormData();		
				}
			}
			if(this.currStepNo == 1){
				setTimeout(() => { this.handleStep2Validation(false) }, 400);
			}			
			this.setTitle(this.currStepNo);
		}
	}
}