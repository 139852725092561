<div class="tab-container">
  <div class="tab-header">
    <h1 class="tab-heading-custom">Follow these recommendations to achieve optimal wellbeing</h1>
    <div class="tab tab-suggested-resource active" data-index="0">1. Introduction</div>
    <div class="tab tab-suggested-resource" data-index="1" data-case="eap" (click)="eatwell()">2. Eat well</div>
    <div class="tab tab-suggested-resource" data-index="2" (click)="moveMore()">3. Move more</div>
    <div class="tab tab-suggested-resource" data-index="3" data-case="eap" (click)="makeSleep()">4. Make sleep a priority</div>
    <div class="tab tab-suggested-resource" data-index="4" (click)="avoidRisk()">5. Avoid risky behaviours</div>
    <div class="tab tab-suggested-resource" data-index="5" data-case="eap" (click)="knowNumber()">6. Know your numbers</div>
    <div class="tab tab-suggested-resource" data-index="6" (click)="manageStress()">7. Manage your stress</div>
  </div>
  <div class="tab-content">
    <div class="tcontent tab-1 active">
      <!-- .et_pb_section -->
                 <div class="score-mb">
                   <h1>
                     Introduction
                   </h1>
                   <p class="namelogin">Hi {{ pData[0]?.name }}!</p>
                   <p class="scorep"><strong>Thanks for taking an interest in your health…</strong> that’s the first step in moving
                     towards
                     a healthier you</p>

                     <span><span class="heart-{{scorecolor}}">&#10084; </span> <span class="scorer">Your score  = {{scorecolor}}</span></span>
                     <p class="scorep">{{scoretext}}</p>
                 </div>
            
                <div class="row score-mb">
                    <div class="column">
                      <h1 class="pleft">
                        Healthy lifestyle
                      </h1><br>
                      <p class="pleft">
                        The defintion of a healthy lifestyle is "a way of living that lowers the risk of being seriouly ill
                        or dying early”. Sounds easy enough, but where do you even start? You might
                        turn to your favourite Dr Google for help? But after typing in the words
                        “healthy lifestyle” and being met with over 1,430,000,000 results within 0,43
                        seconds, you’d be forgiven for feeling absolutely overwhelmed, throwing your
                        hands up in the air, and heading back to the couch, via the fridge, with
                        another snack.
                        <br><br>
                        The concept of healthy living has exploded. You can’t go into a bookstore,
                        pick up a magazine, or open up a website without something popping up
                        about health. There’s a new way of eating, a new way to exercise, a new
                        fitness tracker, and if-you-haven’t-tried-this-new-[insert any item here], are
                        you even healthy?
                        <br><br>
                        Fortunately, it IS possible to live fad-free in a fad-filled wellness world. And to
                        do this, you need to go back to basics. There is substantial evidence to
                        suggest that specific lifestyle behaviours not only lower your risk of
                        developing disease, but also improve your longevity and quality of life. Being
                        healthy isn’t just about avoiding disease, it’s also about improving your
                        physical, mental and social well-being.
                      </p></div>
                    <div class="column"><div><img class="img-responsive" src="assets/img/healthy-lifestye.PNG" alt="Healthy lifestyle One"></div></div>
                  </div>

                

                  <div class="row score-mb">
                    <div class="column"><img class="img-responsive" src="assets/img/healthy-lifestye2.PNG" alt="Healthy lifestyle Two"></div>
                    <div class="column">  
                        
                        <h1 class="pleft">The basic principles of a healthy lifestyle are:</h1><br>
                                
                        <ul class="tick">
                          <strong>
                          <li>&nbsp;&nbsp; Eat well</li>
                          <li>&nbsp;&nbsp; Move more</li>
                          <li>&nbsp;&nbsp; Make sleep a priority</li>
                          <li>&nbsp;&nbsp; Avoid risky behaviours</li>
                          <li>&nbsp;&nbsp; Know your numbers</li>
                        </strong>
                        </ul>     
                        <br>
                        <p class="pleft">   
                 
                           <strong>Only 5 habits? Yes, only 5.</strong> <br><br>
                              Each one of these habits have a positive spill over effect into the others. If you
                              exercise regularly and eat a balanced diet, for example, you are less likely to be
                              stressed, overweight or develop high blood pressure. If you prioritise your
                              sleep, you’re more likely to have the energy to exercise, and if you don’t smoke
                              and don’t overdo it at the bar on a Friday night, your risk for heart disease and
                              several types of cancer is significantly reduced. If you know what your current
                              blood pressure and diabetes risk is, you can take steps – like exercising and
                              eating well – to address it.
                            
                          </p>
                          <p class="pleft">   Collectively, these habits work together to add years to your life, and life to your years.</p>
                
                    </div>
                  </div>
          
    </div>
    <div class="tcontent tab-2">
      <!-- Start Eat Well -->
      <div>
        <h2 class="heading-ac">
          Eat well
        </h2>
        <p class="intro-tab">
          Unhealthy eating habits now generate more disease than physical inactivity,
          alcohol and smoking combined. Let that sink in for a minute. Your diet could be
          worse for your health than smoking? What’s more, up to 40% of people who have a
          “normal” body weight, experience metabolic problems that are usually associated
          with obesity – things like high blood pressure, diabetes and heart disease. The
          common denominator: a poor diet.
        </p>
        <p class="intro-tab"><strong>
          How and what you eat can be an all-encompassing topic, and another complicated
          one. But just like a healthy lifestyle, sticking to the basics can help to make things
          simpler.
        </strong>
        </p>
        <h2 class="intro-heading-tab">1. Out with the processed, in with the produce</h2>
        <!-- Start 1. Out with the processed, in with the produce -->
        <div class="row section-mb">
          <div class="column">
            <p>There is one piece of advice that is consistent, no matter who you ask (or listen to):
              eat more veggies. Research consistently shows that those who eat the most
              vegetables have the lowest risk of disease. Veggies offer protection to your health
              by providing fibre, potassium, magnesium, antioxidants and phenolics. Together,
              these compounds help to keep blood vessels flexible and blood pressure in a
              healthy range. Vegetables are also a rich source of fibre – important for keeping
              you full and any hunger pangs at bay.
            </p>
            <p>
              Other foods that fall under the same fresh produce umbrella are those referred to
              as “real food”. As the name suggests, real food includes any type of food that has
              been left in its original form. This would include healthy options like nuts, seeds,
              eggs, fish, beef, chicken (not an exhaustive list!)
            </p>
            <p>
              While fresh produce fuels your body with nutrient dense goodness, processed
              foods (convenience meals, fast food, sweets, chips) do the opposite. Processed
              foods remove things that are good for you (fibre and vitamins, for example) and
              add-in unhealthy ingredients such as sodium, trans fat and added sugars. These
              additions may improve the palatability and shelf life of the food but provide no
              benefit to your health.
            </p>
            <p><img src="assets/img/quickwin.PNG" alt="Out with the processed, in with the produce"></p>
          </div>
          <div class="column">

            <p>While fresh produce fuels your body with nutrient dense goodness, processed
              foods (convenience meals, fast food, sweets, chips) do the opposite. Processed
              foods remove things that are good for you (fibre and vitamins, for example) and
              add-in unhealthy ingredients such as sodium, trans fat and added sugars. These
              additions may improve the palatability and shelf life of the food but provide no
              benefit to your health.
            </p>
            <p>
              The main difference between processed and produce is the impact these foods
              have on your hormones. Eating fresh produce causes an increase in appetite suppressing hormones and a
              decrease in the hunger hormone ghrelin. This means
              you automatically reduce the amount you eat, while at the same time supporting
              what your body needs, without having to carefully count calories or purposefully go
              on a “diet”.
            </p>
            <p>
              A diet that is based on fresh produce and minimal processing is one that is rich in
              anti-inflammatory compounds and antioxidants. Together, these help to improve
              both physical and mental well-being.
            </p>
            <br><br><br>
            <div class="block_bg">
            <h1><span class="heart-{{scorecolor}}">&#10084; </span> Quick wins</h1>

            <p>Deciding which foods are processed, and to what degree, can be complicated! A simpler approach is to start from the opposite direction: pick foods that are unquestionably healthy, and more of those. Another good rule of thumb: the less packaging, the better!
            </p>
            </div>
          </div>
        </div>
        <!-- End 1. Out with the processed, in with the produce -->

        <!-- Start 2.Sugar by any other name... is still sugar! -->
        <div class="row section-mb">
          <h2 class="intro-heading-tab">2. Sugar by any other name… is still
            sugar!
          </h2>
          <div class="column">
            <p>Your body (a bit like your teeth) does not like sugar. It causes inflammation and irritation, and changes in the way the metabolic hormone insulin works. Insulin is used to regulate the amount of sugar (glucose) in your blood stream and ensures that each cell has the exact amount of energy that it needs. Once the cells had their fill, insulin stores the left-overs as fat.
            </p>
            <p>
              When you eat something that is high in sugar, insulin levels spike to keep your sugar levels constant. Initially you may feel an energy dip as your body tries to recover from the sugar hit, but if this continues over and over again, the insulin response becomes compromised. Your
              body may stop producing insulin altogether, or your cells may gradually become more resistant to it.
              
            </p>
            <p>
              You may not obviously be adding sugar to your diet by including it in your tea or coffee, but if you enjoy drinking fizzy drinks, energy drinks or fruit juice, or enjoy cereals, refined
              carbohydrates (bread, pasta, rice) and energy bars, or use tomato sauce on everything, you’re likely consuming a lot more sugar than you are aware of. 
            </p>
            <p><img src="assets/img/sugar.PNG" alt="Sugar by any other name… is still
              sugar!"></p>
          </div>
          <div class="column">
            <p>Your body does not discriminate between white sugar, brown sugar, sucrose, fructose, maltose, dextrose or anything else ending in -ose. Anything that raises your blood glucose causes a similar metabolic response.The single best change you can make to your diet is to become more aware of exactly what you are eating. 
            </p>
            <p>
              Start reading labels and remember that the harder a product’s packaging tries to convince you it’s healthy, the more important it is for you to pick it up and check its label. 
            </p>
            <p>
              Ingredients are listed in descending order, and if you see sugar (or something else that you don’t recognise) in the top 5, put down what you’re looking at and find something else.
            </p>
            <br><br><br><br>
            <div class="block_bg">
            <h1><span class="heart-{{scorecolor}}">&#10084; </span> Quick wins</h1>

            <p>When your eyelids are drooping don’t be surprised if your body starts searching for sugar. Highly processed food is seen as more rewarding to a fatigued brain which looks for a quick hit of energy. Plan ahead! Keep healthy snacks like nuts and biltong on hand for when cravings hit
            </p>
            </div>
          </div>
        </div>
        <!-- Start 2.Sugar by any other name... is still sugar! -->
        <!-- Start 3.Dont Fear Fat -->
        <div class="row section-mb">
          <h2 class="intro-heading-tab">3. Don't fear fat
          </h2>
          <div class="column">
            <p>In a series of unfortunate events, fat has been portrayed as the sole cause of obesity and poor health and, as a result, has been mostly “banished” from healthy living. The truth is that dietary fat is essential for optimal functioning. 
            </p>
            <p>
              Fats are a source of energy, an important structural building block of your cells, they carry around and help with the absorption of the fat-soluble vitamins A, D, E and K and play a “back room” role in processes like blood clotting and wound healing.
            </p>
            <p>
              Your brain alone is made up of 60% fat. So, safe to say you cannot really live without it. The real value of fat in your diet is that it creates satiety, in other words, it is filling. Including fat in a meal makes it both tasty and satisfying, meaning you are far more likely to really enjoy it, and are less likely to overeat.
            </p>
            <p><img src="assets/img/dont-fear-cake.PNG" alt="Don't fear fat"></p>
          </div>
          <div class="column">
            <p> It’s for this reason that there is growing evidence to show that full fat products, like dairy for example, are linked to healthier hearts and a lower risk of disease.
            </p>
            <p>
              Once you have finished celebrating the fact that you can enjoy fat in your diet again, take a minute to consider that not all fats are created equal.Whether you are sautéing, baking, frying, drizzling or dressing, be picky with your choice of oil. Avoid vegetable oils.
            </p>
            <p>
              A diet that is based on fresh produce and minimal processing is one that is rich in
              anti-inflammatory compounds and antioxidants. Together, these help to improve
              both physical and mental well-being
            </p>
            <br><br>
            <div class="block_bg">
            <h1><span class="heart-{{scorecolor}}">&#10084; </span> Quick wins</h1>

            <p><strong>Be nut smart. If you prefer the salty kind, buy them raw and roast and salt them yourself.</strong> This way you can control the type of oil you use for roasting as well as the amount of salt you add
            </p>
            </div>
          </div>
        </div>
        <!-- End 3.dont fear fat -->
        <!--  Start 4.Stop counting calories: focus more on colour and freshness-->
        <div class="row section-mb">
          <h2 class="intro-heading-tab">4. Stop counting calories: focus more on colour and freshness
          </h2>
          <div class="column">
            <p>There is no doubt that how much energy you eat and how much energy you use matters, but it’s the source of that energy that’s really the key.
            </p>
            <p>
              Here is a good example.A can of cola and an egg have a similar number of calories. Sugary drinks, however, have what is referred to as “empty calories”, so compared to an egg, once they leave your mouth, the pathways these calories travel are significantly different. 
            </p>
            <p>
              The drink will deliver you instant energy, spike up your blood sugar and possibly leave you feeling a bit low, and hungry, whereas the same amount of calories from the egg will keep your blood sugar constant
            </p>
            <p><img class="img-responsive" src="assets/img/stop-counting-calories.PNG" alt="Stop counting calories"></p>
          </div>
          <div class="column">
            <p>and make you feel full and satisfied. It’s not difficult to consider then that drinking a can of cola every day for 5 days will have a vastly different effect on the body, long-term energy balance, and health, compared to eating the same calories from eggs.The reason
              – a calorie isn’t really a calorie.
              
            </p>
            <p>
              Instead of weighing out portions and worrying about how many calories you’ve used, or not used, focus more on filling your plate with freshness and colour. 
            </p>
            <p>
              Colourful and fresh food is naturally more nutrient dense than processed and empty calorie options, and provides your body exactly what it needs.
              
            </p>
            <br><br><br>
            <div class="block_bg">
            <h1><span class="heart-{{scorecolor}}">&#10084; </span> Quick wins</h1>

            <p>Take a few deep breaths before you eat. Being mindful at mealtimes allows you to recognize your body’s hunger and fullness cues plus it allows you to differentiate physical cravings from emotional ones
            </p>
            </div>
          </div>
        </div>
        <!--  End 5.Stop counting calories: focus more on colour and freshness-->
        <!-- Start 5. Accept that there are no magic bullets when it comes to weight loss -->
        <div class="row section-mb">
          <h2 class="intro-heading-tab">5. Accept that there are no magic bullets when it comes to weight loss
          </h2>
          <div class="column">
            <p>What works for your cousin’s boss’s friend may not work for you, so don’t be tempted to try the next diet delivered to your inbox or Facebook feed. If it sounds too good to be true, it usually is.
            </p>
      
            <p><img class="img-responsive" src="assets/img/loosingweight.PNG" alt="loosing weight"></p>
          </div>
          <div class="column">
            <p>There is absolutely no evidence that weight loss pills, creams, shakes and tonics have any impact on weight loss. Promises about losing weight without having to change your lifestyle are not to be believed. If there were a secret ingredient that did that job, it wouldn’t remain a secret for very long. It would also likely be on every news cycle for the next few years (may even give Corona a run for its money!).
            </p>

            <br>
            <div class="block_bg">
              <h1><span class="heart-{{scorecolor}}">&#10084; </span> Quick wins</h1>

              <p>Count to 20 before grabbing seconds. The quicker you eat a meal, the less time you give your body to register fullness and the more likely you are to overindulge
              </p>
            </div>
           
          </div>
        </div>
        <!-- End 5. Accept that there are no magic bullets when it comes to weight loss -->

      </div>
      <!-- End Eat Well -->
      
    </div>
    <!-- Start move more -->
    <div class="tcontent tab-3">
      <div class="row section-mb">
        <h2 class="heading-ac">Move More</h2>
        <div class="column">
          According to the WHO, the global leading risk factors for chronic disease are high
          blood pressure, tobacco use, high blood glucose and overweight and obesity.
          There are pharmacies full of medications that aim to address all of these, but there
          is one medication that isn’t for sale, and it’s the most effective one of the lot. The
          benefits of exercising regularly stretch far beyond getting your body bikini ready for
          summer or unleashing killer abs (although those are obviously good outcomes),
          exercise is medicine.
        </div>
        <div class="column">
          Regular exercise is punted as the key to weight loss, but its role in overall health is
          often underestimated. Exercise has consistently been shown to be as powerful as
          medication used to manage chronic conditions, such as high blood pressure and
          heart disease. It also plays a fundamental role in the prevention of these same
          conditions, as well as diabetes and certain cancers. It’s a mood booster, improves
          social interaction and encourages spending time outdoors, all of which are
          important factors for overall health and well-being.</div>
      </div>
      <br>
      <div class="row">
        <div class="column">
          <h4 class="font-c">Who, what when and how?</h4><br>
          Some people swear by a 6a.m. jog to get their hearts racing and get them
          psyched up for the day. Others wouldn’t dream of breaking a sweat before noon,
          preferring to walk their dogs after work. But the truth is there isn’t any reliable
          evidence to suggest that one time of the day is any better than the other –
          morning or evening, your body won’t judge you (provided you do actually do the
          exercise) and you will still gain the health benefits.
          <br><br>
          The same goes for the type of exercise you do. Any type of exercise that you do
          will deliver positive results. If you’re not a fan of running, don’t try to force
          yourself to do it just because you read an article to say how good it is for you. If
          you have no balance, don’t try cycling and if you can’t swim… well, you know
          what you need to do. The key is to find something that you enjoy. Its this
          happiness factor that will keep you coming back for more.
        </div>
        <div class="column"><img class="img-responsive" src="assets/img/whowhatwhen.PNG" alt="whowhatandwhen"></div>
      </div>
      <br>
      <div>
        <h4 class="scorep fsize">As for how much exercise you need
          to do, there are some guidelines. For
          optimal health, the global
          recommendations are:</h4>
          <br>
        <img  style="margin-left: 31%;width: 40%;" src="assets/img/howmuchexercise.PNG" alt="how much excercise">
        <div class="intro-tab">
          <p class="section-mt">What these guidelines don’t specify is that you don’t need to do these exercise
            sessions all at one go. That’s important for when works gets crazy and you don’t
            have the time to squeeze in a full 30 minute workout. There is sufficient evidence
            now to show that breaking up your 30 minute daily allocation into 10 minute chunks
            throughout the day will provide the same health benefits as doing it all at once.
          </p>
          <p>Something the guidelines also don’t mention is that what you do for the rest of the
            day also counts. Even if you exercise for the recommended 30-60 minutes each
            day, if you spend the rest of the day sitting, your risk of diabetes and heart disease
            is elevated. That’s why prolonged sitting is now considered an independent risk
            factor, regardless of how active you are.
          </p>
          <p><strong>The solution here is a simple one: move. Move while you are purposefully
            exercising and move while you’re not. Simply moving more throughout the day has
            a long-lasting impact on your overall health.
          </strong>
          </p>
        </div>

        <div class="row section-mb section-mt">
          <div class="column"><img class="img-responsive" src="assets/img/quickwin-run.PNG" alt="run"></div>
          <div class="column">
            <div class="block_bg">
              <h1><span class="heart-{{scorecolor}}">&#10084; </span> Quick wins</h1>
    
              <p> Take advantage of worrisome waiting time by going for a run, walk or Frisbee throwing session before a stressful event(exam, important meeting, presentation). 
                  Using a workout as a prophlactic at this time is possibly the best way to take advantage of exercise's stress-reducing effects.
              </p>
              </div>
          </div>
        </div>
      
      </div>
      


    </div>
    <!-- End move more -->
    <!-- Start Make sleep a prioty -->
    <div class="tcontent tab-4">
      <div class="row">
        <h2 class="heading-ac">Make sleep a priority</h2>
        <div class="column">
          <p>Think of sleep as your body’s currency. It uses it to buy you several different health
            necessities. While it’s true that sleep makes you feel better, it’s about so much
            more than simply boosting your mood or banishing black rings around your eyes.
            Consistent good quality sleep can help protect your cognitive health and
            productivity, mental health, physical health, quality of life, and safety.
          </p>
          <p>There is no question that losing a few hours of sleep over time catches up with you.
            You’ve had a few late nights, but no biggie, you can sleep in over the weekend and
            get them all back? Not so. The reality is that it is nearly impossible to catch up on
            lost sleep. Just like your monthly debits, once it’s gone, it’s not coming back.</p>
          <p>Sleep is often forgotten in favour of getting things done, but the long term impact of
            sleep deprivation is significant, and as such, this lifestyle habit should be given as
            much attention as diet and exercise.</p>
        </div>
        <div class="column">
          <p>When it comes to sleep, it’s both the quality and the quantity of it that is important
            for your health. A good night’s sleep happens long before you nod off, which is why
            preparation is key. Build habits that will help you fall asleep faster, stay asleep
            longer, and be more comfortable while you rest. So, prep your body by exercising
            consistently, nixing those nightcaps and cutting back on late night TV watching.
            These habits get your body into sleep mode way before your head hits the pillow.</p>
          <p>
            You may also need to make changes to your sleep “habitat” that supports a good
            night of shut eye. Keeping your room cool, dark and quiet is the most important
            thing to do here. Not taking your bills, fights and spreadsheets to bed with you is
            another priority!
          </p>

          <p>
            Sleep guidelines recommend getting between 7 and 9 hours of sleep each night.
            The exact amount of sleep you need differs between people, but the key is to
            ensure consistency. Aim to go to bed and wake up at the same time every day
            (weekends too!). You could set a sleep alarm as a reminder that it’s time to hit the
            sack.
          </p>
        </div>
      </div>
      <!-- Quick wins -->
      <div class="row section-mb">
        <div class="column"><img class="img-responsive" src="assets/img/makesleep.PNG" alt="make sleep"></div>
        <div class="column"><div class="block_bg"><h1><span class="heart-{{scorecolor}}">&#10084; </span> Quick wins</h1><p>Do you suddenly find lots to do before you go to bed? Unlike insomnia, bedtime
          procrastination is when you could go to bed, but willingly delay it. Set a go-to bed
          alarm and don’t hit snooze.</p></div></div>
      </div>
      
    </div>
    <!-- End Make sleep a prioty -->
    <!-- Start Avoid risky behaviour -->
    <div class="tcontent tab-5">
        <div class="row section-mb">
            <h2 class="heading-ac">Avoid risky behaviours</h2>
            <div class="column">
            
              <p><img  class="avoid" src="assets/img/avoidrish=k.png" alt="avoid risky">Sex, drugs and rock and roll (… and drinking and smoking). Except for the rock and
                roll part, risky behaviours haven’t changed much since the 70’s when this phrase
                was first coined.</p>
              <p>Unsafe sex is ranked second among the top ten risk factors to health in terms of
                the burden of disease they cause. An estimated 340 million sexually transmitted
                infections (STIs) occur each year responsible for considerable ill-health, including
                increased risk of HIV infection. Practising safe sex is an easy change to make and
                important one to protect both your own longevity, and that of your partner.</p>  
                <p>
                    Drugs interact with chemicals in the brain and body to make you feel a certain way.
While drugs can be helpful in regulating moods, helping you sleep, and managing
pain, they can also have serious complications when misused. Misused
recreational and prescription drugs have a range of harmful effects – both short
and long term. Always follow the instructions from your doctor about prescription
medication and avoid using any type of mood-altering or recreational drugs.
                </p>         
             </div>
            <div class="column">
                <p>
                  
                  In the good ‘ole days alcohol was used for almost anything, in place of safe
                    drinking water, as a preservative or even medicine! These days, however, there is a
                    clear “dose-response” relationship between health and alcohol. This means that as
                    you consume more alcohol, the health risks greatly outweigh any benefit. It’s safe
                    to say that alcohol is both a tonic and a poison. The difference lies mostly in the
                    dose. While most people drink in moderation, there are also a lot who don’t. If you
                    don’t drink alcohol, there is no need to start now simply for the possibility of a
                    longer life (living well will take care of that for you!). If you do drink alcohol, save
                    your brain (and the rest of you) by keeping things light.</p>

                    <p>
                        Worldwide, tobacco use is the single greatest avoidable risk factor for cancer and
kills approximately 6 million people each year. Smoking doesn’t only increase the
risk of lung cancer, but several other types of cancer like oesophagus, larynx,
mouth, throat, kidney, bladder, pancreas, stomach and cervix… and it doesn’t end
there. Exposing others to secondhand smoke increases their risk too. So avoiding
all types of tobacco – whether that is in your own mouth or someone else’s who is
close by – is important for your overall health and well-being.
                    </p>

            </div>
          </div>
          
    </div>
    <!-- End Avoid risky behaviour -->
    <!-- Start Know your numbers -->
    <div class="tcontent tab-6">
     <div><h2 class="heading-ac">Know your numbers</h2><p class="intro-tab">Just because you don’t feel sick, doesn’t mean you’re healthy, so you can’t talk
        about a healthy lifestyle without knowing what the current status of yours is!. There
        are several health conditions, like high blood pressure, insulin resistance and
        diabetes that have no obvious symptoms. Left undiagnosed, and untreated, these
        conditions can cause serious health issues including heart disease, stroke, kidney
        failure, damage to eyesight and amputations. There are three important numbers
        you need to know and keep track of.</p></div>
<div class="row section-mb">
  <div class="column">
    <div>
      <h2>Blood pressure</h2>
      <p>Blood pressure is the force of blood against the walls of arteries. It’s recorded
        as two numbers—the systolic pressure (as the heart beats) over the diastolic
        pressure (as the heart relaxes between beats). Thanks to that nifty thing
        called gravity, we need a certain amount of pressure to ensure that sufficient
        blood gets back up to our heart all the way down from the tips of our toes.</p>
      <p>High blood pressure develops if the walls of the larger arteries lose their
        natural elasticity and become rigid, and the smaller blood vessels become
        narrower. This places a strain on the blood vessels and causes tiny
        scratches. As the body heals these microscopic tears, scar tissue is formed.
        Our once smooth arteries now have tiny lumps and bumps where fatty
        material and blood cells gather and stick to the artery wall. Over time, more
        and more material is deposited, causing the artery to become narrower and
        narrower. It is a vicious cycle as the blood pressure is further increased due
        to the narrowing of the arteries.</p>
    </div>
  </div>
  <div class="column"><img class="img-responsive" src="assets/img/bloodpressure.PNG" alt="blood pressure"></div>
</div>
<div class="row section-mb">
    <div class="column"><img class="img-responsive" src="assets/img/bloodglucose.PNG" alt="blood glucose"></div>
    <div class="column">    <div>
        <h2>Blood glucose</h2>
        <p>Although diabetes does have symptoms, unlike high blood pressure, its only a
            fair way down the road that these become obvious. But the problems associated
            with unregulated blood glucose start to happen a long time before any
            symptoms appear. High blood sugar concentrations are extremely irritating to
            blood vessels and cause significant, and often permanent, damage.</p>
        <p>“Pre-diabetes”, as the name suggests, occurs before a diagnosis of diabetes.
            Unregulated blood glucose cause cells to become resistant to insulin. This
            means they aren’t able to get the energy they need, and more glucose
            circulates around the blood causing more damage.</p>
      </div></div>
  </div>
  <div class="row section-mb">
    <div class="column">
      <div>
        <h2>Waist circumference</h2>
        <p>Being overweight increases your risk for developing several different
            diseases, including high blood pressure, diabetes, heart disease and cancer.
            But even those who are of “normal” weight could be a risk if they carry extra
            weight around their waist. While Body Mass Index (BMI) is a widely used
            measure of overweight and obesity, it doesn’t take into account overall
            distribution of fat (fat around your belly vs fat anywhere else on your body).
            Fat that sits around the belly produces its own set of hormones, increasing
            the risk of inflammation, insulin resistance and diabetes. This type of fat also
            builds up around organs, further increasing disease risk.</p>
    
      </div>
    </div>
    <div class="column"><img class="img-responsive" src="assets/img/waistcircumference.PNG" alt="Waist circumference"></div>
  </div>
  
    </div>
     <!-- End Know your numbers -->
     <!-- Start Manage your stress -->
    <div class="tcontent tab-7">
        <div class="row section-mb">
            <h2 class="heading-ac">Manage your stress</h2>
            <!-- Start Introduction to manage your stress -->
            <div class="column">
                <p>For such a short and sweet word, "stress" is anything but. Simply put, you feel stress when there is a percieved imbalance between a challenge (physical, mental, social or emotional) and the resources you have to deal with it.</p>
                 <p>There are the obvious things that cause stress: a tight deadline, a traffic jam, a big presentation, an uncomfortable meeting, problems with loved ones or staring at a dwinding bank balance. But there are also less obvious triggers of stress. Constant noise, bad lighting, temperatue changes, standing in a queue, more recently, wearing a mask or being confined to your home.</p>   
                 <p>Your lifestyle has an equally big role to play in how your body experiences stress.
                    Poor sleep, no exercise, a "questionable" diet, excessive alcohol comsumption and smoking are all perceived as stressors. Believe it or not, these same habits have a similar impact on your health to something as "significant" as  starting a new job, moving to a new town, or receiving a call from a debt collector.</p>
                    <p>As your body percieves stress, your brain relays warnings to the muscles, which tighten, and to the adrenal glands, which release stress harmones such as adrenline and cortisol. Once in your bloodstream, cortisol activates several physiological processes that essentially get your body ready for either a hasty get away, or a face to face encounter. Heart rate and blood pressure go up, blood is diverted to key muscles groups and glucose is freed up as an easy-to-use and on tap energy source. It is for these reasons that the stress response is also known as the "flight or fight" reaction.</p>
                
            </div>
            <div class="column">
                <p>When it comes to stress, a little goes a long way. In the short term, the stress response can help you navigate a tricky negotiation, a  detailed presentation or a public address. It does this by strengthening the connections between neurons in your brain. Indeed, moderate level of stress can be helpful - they can motivate you to get things done. Once you've met your deadline, or made it through the meeting, your stress levels subside and your body returns to a "rest and digest" state in which your cortisol levels are lowered, along with your heart rate and blood pressure.</p>
                <p>Unfortunately, the nature of a fast-paced life is that stress levels remain constantly high. Cortisol continues to be pumped out, and your body  remains on edge. This ongoing stress, also known as chronic stress, can cause long lasting physical damage. Just how much damage you ask? It has been estimated that being chronically stressed is as harmful to your health as smoking five cigarettes a day.</p>
                <p>The first step in managing your stress is recognising that you are stressed. The next step is understanding exactly why. if you know what pushes your buttons, you can actively avoid those situations, or become more proactive in the way you manage them.</p>
                <p><strong>Here are 10 science-backed ways of managing stress. Since everyone has a different response to stress, and the same stressful event can affect different people in different ways, it's important to appreciate that the way you experience stress is unique to you, and so is the best way to manage it. The trick is to try out all of these techniques and stick to the ones that work best for you.</strong></p>
            </div>
               <!-- End Introduction to manage your stress -->
        </div>
        <!-- Start Know your triggers -->
        <div class="row section-mb">
     
            <div class="column">
               <img src="assets/img/knowyourtriggers.PNG" class="img-responsive" alt="know your triggers">
            </div>
            <div class="column palign">
                <h2>1. Know your triggers </h2>
                <p>Red robots, taxis and loads of laundry aside, take a stress inventory regularly to help you unsterstand what is worrying you. Stressors can change just as much as you do. Also  be aware of major life events that can intitiate a cycle of chronic stress.</p>
            </div>
        </div>
         <!-- End Know your triggers -->

                  <!-- Start Eat sleep and move ! -->
        <div class="row section-mb">
     
          <div class="column palign">
              <h2>2. Eat, sleep and move ! </h2>
              <p>Prioritising these 3 lifestyle habits will go a way in proving your body with the physical, and mental, resources it needs to help you manage and cope with stress.</p>

          </div>
          <div class="column">
              <img src="assets/img/eatsleepmove.PNG" class="img-responsive" alt="eatsleepmovesss">
          </div>
      </div>
       <!-- End Eat sleep and move ! -->
     <!-- Start Practise mindfulness, meditation and breathing -->
     <div class="row section-mb">
     
      <div class="column">
         <img src="assets/img/pratisemindfulness.PNG" class="img-responsive" alt="Practise mindfulness, meditation and breathing">
      </div>
      <div class="column palign">
          <h2>3. Practise mindfulness, meditation and breathing</h2>
          <p>These 3 habits can all affect chemical messengers in the brain associated with stress. Belly breathing for example, also known as pace respiration or diaphragmatic breathing, helps turn off your stress response and allows your body, and mind, to relax. Since shallow breathing limits the diaphragm;s range of motion, the lowest part of your lungs dont get their full share of fresh air.
              This can cause your body to "panic", increasing your stress response.</p>
      </div>
  </div>
   <!-- End Practise mindfulness, meditation and breathing -->

         <!-- Start Eat sleep and move ! -->
         <div class="row section-mb">
     
          <div class="column palign">
              <h2>4. Learn and relax </h2>
              <p>
                  Relaxing slows your heart rate, reduces blood pressure, relieves tension and leaves you feeling like you just might be able to cope after all.  A technique called progressive muscle relaxation can be helpful. This involved tightening and relaxing muscle groups one at a time, while being mindful of the sensation of relaxation.</p>

          </div>
          <div class="column">
              <img src="assets/img/learntorelax.PNG" class="img-responsive" alt="Practise mindfulness, meditation and breathing">
          </div>
      </div>
       <!-- End Eat sleep and move ! -->


     <!-- Start Go green -->
     <div class="row section-mb">
     
      <div class="column palign">
         <img src="assets/img/gogreen.PNG" class="img-responsive" alt="go green">
      </div>
      <div class="column palign">
          <h2>5. Go green</h2>
          <p>Escaping to the neighbourhood park, hiking, trail running, mountain biking or simply walking in your garden at home can effectively lower your stress levels and decrease your blood pressure. It can also play a role in reducing your risk of chronic conditions like allergies, diabetes and heart disease, while at the same time boosting your mental health.</p>
      </div>
  </div>
   <!-- End Go green -->


                 <!-- Start Fill your bucket  ! -->
                 <div class="row section-mb">
     
                  <div class="column palign">
                      <h2>6. Fill your bucket </h2>
                      <p>Regardless of how busy you are, you need to find time to take care of yourself and do things that YOU find refreshing. When you are "filled up" by how you invest your time outside of work, you have a stronger buffer  against the drain that may exist in the office!</p>
                  </div>
                  <div class="column">
                      <img src="assets/img/fillyourbucket.PNG" class="img-responsive" alt="Fill your bucket">
                  </div>
              </div>
               <!-- End Fill your bucket  ! -->

                   <!-- Start Avoid multitasking and plan ahead -->
     <div class="row section-mb">
     
      <div class="column">
         <img src="assets/img/avoid.PNG" class="img-responsive" alt="Avoid multitasking and plan ahead">
      </div>
      <div class="column palign">
          <h2>7. Avoid multitasking and plan ahead</h2>
          <p>Despite what you might think, multitasking almost never boosts productivity.
              Instead, your brain slows down when asked to do more than one thing at a time, creating a sense of stress and anxiety. Limit your multitasking by planning, managing external expectations of your time and scheduling your day.</p>
      </div>
  </div>
   <!-- End Avoid multitasking and plan ahead -->

              <!-- Start Bust through burnout ! -->
              <div class="row section-mb">
     
                <div class="column palign">
                    <h2>8. Bust through burnout</h2>
                    <p>Now officially recognised by the World Health Organisation as an occupational syndrome, burnout is defined as "feelings of negativism or cynicism related to one's job; and/or reduced professional efficacy". Simply put, you get burnt out when you feel like you're putting more into your work than you're getting out of it.Sometimes this happens when a job isn't rewarding, but more often than not it's because you aren't taking care of yourself. Fighting burnout is a simple matter of self-care. You need to find good ways to seperate yourself from your work so that you can recharge and find balance.</p>
                    <div class="block_bg">
                      <h1><span class="heart-{{scorecolor}}">&#10084; </span> Quick wins</h1>
          
                      <p>
                        We care about your wellbeing, contact us if you need support. One of our counsellors will call you.
                        <a href="https://member.mhwellness.co.za/login">Health Information</a>
                      </p>
                      </div>
                </div>
                
                <div class="column">
                    <img src="assets/img/bustthroughburnout.PNG" class="img-responsive" alt="Bust through burnout">
                </div>
            </div>
             <!-- End Bust through burnout ! -->

             
                   <!-- StartDeal with difficult colleaguesd -->
     <div class="row section-mb">
     
      <div class="column palign">
         <img src="assets/img/dealwithdifficult.PNG" class="img-responsive" alt="Deal with difficult colleagues">
      </div>
      <div class="column palign">
          <h2>9. Deal with difficult colleagues</h2>
         <p>Since you spend as much time (sometimes more) with work colleagues as you do with friends and family, it is  important to keep these relationships in perspective and choose how to react. Research suggests that there are 7 major types of stressful colleagues, all of which have one thing in common: their  ability to induce stress in others, particularly their subordinates. Sadly, there is no universal formula to deal with a stress-inducing colleague, but the key to working with a tricky person is to take the time to understand them better. No matter how bad they might be, if nothing else they are consistent, so try to predict their behavioural patterns and manage them accordingly.</p>
      </div>
  </div>
   <!-- End Deal with difficult colleagues -->

              <!-- Start  Don't be guilty of presenteeismt ! -->
              <div class="row section-mb">
     
                <div class="column palign">
                    <h2>10. Don't be guilty of presenteeism</h2>
                    <p>Start becoming more aware of how your everyday health affects your productivity. "Presenteeism" refers to a situation where you are physically present at work but are not fully functioning because of health-related issues. A headache, backache, nausea, or fatigue aren't enough to keep you at home or in bed but can have a direct effect on your productivity levels. Estimates are that presenteeism can cut your productivity by up to 33%.</p>
                    <p>When all else fails.... Laugh out loud. Humour and laughter strenghthen your immune system, boost your energy, diminish pain, and protect you from the damaging effects of stress. And the best part about it is that this precious remedy is fun, free, and easy to use.
                    </p>
                </div>
                <div class="column">
                    <img src="assets/img/presenteeism.PNG" class="img-responsive" alt="Don't be guilty of presenteeism">
                </div>
            </div>
            <div class="section-mb section-mt">
              <h2 class="intro-heading-tab">The bottom line</h2>
              <p class="intro-tab">A health lifestyle doesn't need to be complicated or confusing. Sticking to the basics and making small sustainable changes will go a long way in 
                improving your overall health. Adopt the concept of "progress" and let go of the idea of a "perfect lifestyle". Expecting perfection guarantees you'll feel like a failure at least some 
                of the time. If in doubt, consider getting in touch with your doctor for more practical ways of incorporating healthy behaviours into your current lifestyle.
              </p>
            </div>     
  </div>
  <div class="button-holder">
    <button class="btn">
      <a href="{{pdfURL}}" target="_blank">Download PDF Report</a>
    </button>
    <button class="btn tab btn-more-rec" 
      [attr.data-index]="currentCounter" 
      data-case="eap">more recommendations</button>
  </div>
</div>