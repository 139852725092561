import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '../../shared/global-data';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { QuestionnaireService } from '../../shared/services/questionnare.service';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from "../../../app/shared/services/google-analytics-service.service";


@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
	score: string = "";
	scoreRate: string = "";
	labelScore: string = "";
	showElearning: boolean = true;
	isSars: boolean = false;
	owlOptions: OwlOptions = {
		items: 1,
		dots: true,
		nav: false
	};
	hdData: any = [];
	queId: String = '1161';



	isWellnessView: boolean = false;
	isSiteMomWellness = localStorage.getItem('loadMomWellnessStylesheet');

	constructor(
		   private router: Router, 
		   private globalData: GlobalDataService, 
		   private queServ: QuestionnaireService, 
		   private googleAnalyticsService: GoogleAnalyticsService) {
		let getHDPostsLS = localStorage.getItem('hdPosts');
		if (getHDPostsLS != null) {
			this.filterHDPostData(JSON.parse(getHDPostsLS));
		}
		// get HD posts
		this.getHDPosts().then((data) => {
			localStorage.setItem('hdPosts', JSON.stringify(data));
			this.filterHDPostData(data);
		});
	}

	ngOnInit() {

		this.getScore();

		this.elearningCheck();

		$('body').removeClass('logged-out-view');
		this.globalData.changeCurrentViewState('dashboard');

		this.queServ.getQuestionnaire(this.queId).subscribe(result => {
			if (result !== undefined) {
				localStorage.setItem('questionnaire', JSON.stringify(result));
			}
		});
	}
	loadView(page) {

		if (page == "e-learning" || page == "survey" || page == "video" || page == "assessment") {
			this.isWellnessView = false;
			$('body').addClass('mini-menu'); // Add mini dashboard menu
			this.router.navigate([page]);
			if(this.isSiteMomWellness){// Google Analytics Elearning Tags 
				this.elearningdashboardblockMomentumwellness();
			}else{
				this.elearningdashboardblockMHwellness();
			}
		} else {
			this.isWellnessView = true;
			this.globalData.changeCurrentViewState('well-being');
			//$('body').addClass('mini-menu');
			this.router.navigate([page]);
		}
	}


	filterHDPostData(data) {
		let filteredData = data.map(i => {
			let img = i._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url;
			return {
				id: i.id,
				image: img,
				title: i.title.rendered,
				descrip: i.excerpt.rendered,
				link: i.link
			}
		});
		this.hdData = filteredData;
	}

	async getHDPosts() {
		let response = await fetch(`https://www.hellodoctor.co.za/wp-json/wp/v2/posts/?per_page=3&_embed`);
		let data = await response.json();
		return data;
	}

	openHDPost(link) {
		window.open(link);
	}

	getScore() {
		var id = localStorage.getItem("userIdNumber");
		let idnumber = JSON.parse(id);
		this.queServ.getScoreQuestionnaire(idnumber).subscribe(result => {
			if (result !== "undefined") {
				if(result?.errorCode=="401")
				{
					this.score = "0";
					this.scoreRate = "No Rating"
					this.labelScore = "new";
				}
				else
				{
				this.score = result[0].score


				if (this.score <= "20") {
					this.scoreRate = "is good";
					this.labelScore = "green";
				} else if (this.score <= "45") {
					this.scoreRate = "could be better";
					this.labelScore = "old";
				} else if (this.score <= "100"){
					this.scoreRate = "is at risk";
					this.labelScore = "new";
				  }else{
					  this.score = "99";
					  this.scoreRate = "is at risk";
					this.labelScore = "new";
				  }
				}

			} else {
				this.score = "0";
				this.scoreRate = "No Rating"
				this.labelScore = "new";
			}

		});
	}

	elearningCheck() {
		$('body').addClass('mini-menu'); // Add mini dashboard menu

		let str = localStorage.getItem('ParentCode');
		let ParentCode = str.replace(/\s/g, '');

		if (ParentCode == "60088") { //SARS -South African Revenue Service
			this.showElearning = true;
			this.isSars = true;
		} else {
			this.showElearning = true;
			this.isSars = false;
		}
	}

	//Google analytics for MH wellness
	elearningdashboardblockMHwellness() {
		this.googleAnalyticsService.pushDirectEvent( 'Elearning Dashboard Click',  'Member MHWwellness ELearning Dashboard - Post Login',  'Member MHWwellness Elearning Dashboard - Post Login' );
		this.googleAnalyticsService.pushDirectEvent( 'Elearning Dashboard Click',  'Member MHWwellness ELearning Dashboard - Post Login',  'Member MHWwellness Elearning Dashboard - Post Login' );
	}
	//Google analytics for Momentum wellnes 
	elearningdashboardblockMomentumwellness() {
		this.googleAnalyticsService.pushDirectEvent( 'Elearning Dashboard Click',  'Member MomentumWellness ELearning Dashboard - Post Login',  'Member MomentumWellness Elearning Dashboard - Post Login' );
		this.googleAnalyticsService.pushDirectEvent( 'Elearning Dashboard Click',  'Member MomentumWellness ELearning Dashboard - Post Login',  'Member MomentumWellness Elearning Dashboard - Post Login' );
	}

}