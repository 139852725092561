import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { GlobalDataService } from '../../shared/global-data';

@Component({
  selector: 'app-reports',
  templateUrl: './reports-dashboard.component.html',
  styleUrls: ['./reports-dashboard.component.css']
})
export class ReportsDashboardComponent implements OnInit {

	isActive_occh: any;
	isActive_illhealth: any;
	isActive_cofs: any;
	isActive_wellevent: any;

	constructor(private router: Router, private lsService: StorageMap,
		private globalData: GlobalDataService) {}

	ngOnInit(): void {
		$('body').removeClass('logged-out-view');
		this.getData('hasOccHealth').then((data) => this.isActive_occh = data);
		this.getData('hasIllHealth').then((data) => this.isActive_illhealth = data);
		this.getData('hasCOFs').then((data) => this.isActive_cofs = data);
		this.getData('hasWellevent').then((data) => this.isActive_wellevent = data);
		
	}

	async getData(type){
		let data = await this.lsService.get(type).subscribe();
		return data;
	}

	goToPage(page,type){
		this.router.navigate(["/"+page, { dataroute: type }]);
	}	

}
