import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ElearningService } from './../../app/shared/services/elearning.service';
import { Location } from '@angular/common'
import { GoogleAnalyticsService } from "../shared/services/google-analytics-service.service";

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  videoId: string;
  topicID: string;
  videoName: any;
  videoUrl: string;
  videoHeader: string;
  topicmainheadernav: string;
  topicmaindescription: string;
  newVideoHeader: string;
  //watched object 
  watched: any;
  isWatched: boolean = false;

  isSiteMomWellness = localStorage.getItem('loadMomWellnessStylesheet');


  constructor(
    private router: Router,
    private queServ:ElearningService,
    private location: Location,
    private googleAnalyticsService: GoogleAnalyticsService, 
  ) { }

  ngOnInit(): void {
    $('body').addClass('mini-menu'); // Add mini dashboard menu
    $('.component-content').css("box-shadow", "unset");//Remove box Shadow from global component
    setTimeout(() => {
      this.topicmainheadernav = localStorage.getItem("TopicMainHeader");
      this.topicmaindescription = localStorage.getItem("TopicMainDescription");
      this.topicID = localStorage.getItem("TopicID");
      this.videoId = localStorage.getItem("VideoID");
      this.videoName = localStorage.getItem("VideoName");
      this.videoHeader = localStorage.getItem("VideoHeader");
      this.newVideoHeader = this.videoHeader.replace('Self-check :', '');
      this.videoUrl = localStorage.getItem("Video Url");
    }, 1200);
   
  }

 submitObj: any;


  mainQuestio(){
    this.router.navigate(['assessment']);
  }

  goBack(){
    this.location.back()
    this.submitVideo();
  }

  submitVideo(){
    let username  = localStorage.getItem("username");
    this.isWatched = true;

    this.submitObj = ({ username: username, 
      watched:[{
        "videoID": this.videoId,
        "topicID": this.topicID,
        "watched": this.isWatched
      }]
    });

    this.queServ.submitVideo(this.submitObj).subscribe(result=>{
      if(result !== undefined){
        this.watched = result.body.watched;
      }
    });
  }



  total_topic_videos_downloaded(){
    this.googleAnalyticsService.pushDirectEvent('Total Topic Videos Downloaded', 'Total Topic Videos Downloaded', 'Total Topic Videos Downloaded');  
    this.googleAnalyticsService.pushDirectEvent('Total Topic Videos Downloaded', 'Total Topic Videos Downloaded', 'Total Topic Videos Downloaded'); 
  }

}
