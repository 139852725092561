import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse,HttpEventType } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GlobalDataService } from '../../shared/global-data';
import * as Enums from '../../shared/enums';

const basicAuthHttpOptions = {
	headers: new HttpHeaders({
		'Content-Type':  'application/json',
		'Authorization': 'Basic ZXhjaGFuZ2U6ZXhjaGFuZ2VAMQ=='
	})
};

// fileData: File = null;
@Injectable({ providedIn: 'root' })
export class ReportsService {

	httpOptions: any = {};

	constructor(public http: HttpClient){}
	
	getReportsHierachy (groups): Observable<any> {
		let finalObj = {
    		groups: groups	
		}
		console.log('cool lets see finalObj before API req hit...');
		console.log(finalObj);
		return this.http.put(Enums.API_BASE_PATHS + Enums.API_PATHS.GET_REPORTS_HIERACHY, JSON.stringify(finalObj)).pipe(
			tap( success => console.log('success'),
   				error => console.log('reports hierachy did not work...API error!'))
		);
	}

	getCOFReports(idNumber): Observable<any>{
		return this.http.get(Enums.API_BASE_PATHS + Enums.API_PATHS.GET_COF_REPORTS +"/"+idNumber).pipe(
			tap( success => console.log('success'),
   				error => console.log('get COF reports did not work...API error!'))
		);		
	}

	getAzureAccessToken(clientId,tenantId): Observable<any> {
		return this.http.get(Enums.API_BASE_PATHS + Enums.API_PATHS.AZURE_TOKEN + "/" + clientId + "/" + tenantId + "/masterUser").pipe(
			tap( success => console.log('AZURE TOKEN API SUCCESS!!'),
   				error => console.log('get AZURE TOKEN did not work...API error!'))
		);		
	}

	generatePowerBIToken (embedTokenRequest): Observable<any> {	
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
				'Authorization': 'Bearer '+embedTokenRequest.accessToken
			})
		}		
		let goupid = embedTokenRequest.groupId;
		let reportid = embedTokenRequest.reportId;
		return this.http.post(`https://api.powerbi.com/v1.0/myorg/groups/${goupid}/reports/${reportid}/GenerateToken`, JSON.stringify({}), this.httpOptions).pipe(
			tap( success => console.log('BI REPORTS TOKEN API SUCCESS!!'),
   				error => console.log('get BI REPORTS did not work...API error!'))
		)		
	}

	private handleError<T> (operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			//this.logException(Enums.LEVELS.ERROR, operation + ':' + error.message);
			return of(result as T);
		};
	}
	private handleAuthError(err: any): Observable<HttpErrorResponse> {
		throw err;
	}	
	private log(message: string) {
		//console.log(`${message}`);
	}	
}