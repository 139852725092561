<!-- MH wellness footer -->
<div class="footer clearfix" [hidden]="isMomWellness || isSars">

	<div class="row-footer clearfix">
		<div class="flhs-content fLeft">&copy; 2024 METROPOLITAN</div>
		<div class="frhs-content fRight">
			<a href="https://www.mhwellness.co.za/default.aspx?xGX7Y5WBkjmsUlL7tVtZYYw8OudhAiSaMJwEH6YyvgI8IFCZDrEWQsevoLoPJ8tXhKtl+lkPHTJrPBsAHp5kkw==" target="_blank">OUR SERVICES</a>
			<a href="https://www.mhwellness.co.za/default.aspx?xGX7Y5WBkjkN5c5YaaxpPLMgVbydvRQemUomqytqXOsoDJQ1jS3Pt61wZ5xksDIIPPFJFDJ8cgtev7MiK3hknQ==" target="_blank">HEALTH INFORMATION</a>
			<a href="https://www.mhwellness.co.za/default.aspx?xGX7Y5WBkjn/hvG5xoSlEwoojhLHL4YXaEl0E8mAQ0qQBLLQR3oV2pbfv4DKl7KJHEj0ivZTTqF+EJRHFyAeig==" target="_blank">CONTACT US</a>
		</div>
	</div>
	<div class="bottom_popia_well">
		<a href="https://www.momentum.co.za/momentum/support/legal-and-compliance" target="_blank">Legal and Compliance</a>&nbsp;|&nbsp;<a href="https://www.momentum.co.za/momentum/support/security-and-fraud" target="_blank">Security and Fraud</a>&nbsp;|&nbsp;<a href="https://www.momentum.co.za/momentum/support/terms-and-conditions" target="_blank">Terms and Conditions</a>&nbsp;|&nbsp;<a href="https://www.momentum.co.za/momentum/support/privacy-policy" target="_blank">Privacy Policy</a>&nbsp;&nbsp;<a [hidden]="pData[0]?.isSaps ||loggedOut" href="https://content.momentum.co.za/mmsa/hellodoctor/pdf/Hello Doctor - STANDARD SUBSCRIPTION TERMS CONDITIONS - 1 March 2024.pdf" target="_blank">| Hello Doctor Terms & Conditions </a>
    </div>
	<div class="row-footer clearfix">
		<div class="flhs-content fLeft">&nbsp;</div>
		<div class="frhs-content fRight">
			<div class="footer-col">
				<span>021 480 4511</span>
				<span>INFO@MHG.CO.ZA</span>
				<span>&nbsp;</span>
				<span>&nbsp;</span>
			</div>
			<div class="footer-col">
				<span>PARC DU CAP, 7 MISPEL ROAD</span>
				<span>BELLVILLE</span>
				<span>CAPE TOWN</span>
				<span>7530</span>
			</div>
			<div class="footer-col">
				<span>PO BOX 2212</span>
				<span>BELLVILLE</span>
				<span>7535</span>
				<span>&nbsp;</span>
			</div>
		</div>				
	</div>
</div> <!-- ./ footer -->	

<!-- MOMENTUM Wellness Footer -->
<div class="footer clearfix" *ngIf="isMomWellness">
	<div class="top clearfix">
		<div class="fRight">
			<img src="assets/img/momentum_healthsolutions_logo-big.PNG" alt="Momentum Wellness Contact Us" />
		</div>
	</div>
	<div class="bottom">
		<!-- <p>Momentum OCSA (Pty) Ltd, trading as Momentum Wellness, is part of Momentum Metropolitan Holdings Limited.</p> -->
		<p>Momentum Wellness is part of Momentum Metropolitan Holdings Limited.</p>
	</div>
	<div class="bottom_popia">
		<a href="https://www.momentum.co.za/momentum/support/legal-and-compliance" target="_blank">Legal and Compliance</a>&nbsp;|&nbsp;<a href="https://www.momentum.co.za/momentum/support/security-and-fraud" target="_blank">Security and Fraud</a>&nbsp;|&nbsp;<a href="https://www.momentum.co.za/momentum/support/terms-and-conditions" target="_blank">Terms and Conditions</a>&nbsp;|&nbsp;<a href="https://www.momentum.co.za/momentum/support/privacy-policy" target="_blank">Privacy Policy</a>&nbsp;&nbsp;<a [hidden]="pData[0]?.isSaps ||loggedOut" href="https://content.momentum.co.za/mmsa/hellodoctor/pdf/Hello Doctor - STANDARD SUBSCRIPTION TERMS CONDITIONS - 1 March 2024.pdf" target="_blank">| Hello Doctor Terms & Conditions </a>
    </div>
</div>

<!-- SARS Wellness Footer -->
<div class="footer-sars clearfix" *ngIf="isSars">
	<div class="row-footer clearfix">
		<div class="bottom_popia_sars">		
			Toll free number - <a href="tel:+0800777770"><strong>0800777770</strong></a>  &nbsp; SMS - <a href="tel:+0834500508"> <strong>0834500508</strong></a>  &nbsp; <a href="mailto: eap@metropolitanhrm.co.za"> <strong>eap@metropolitanhrm.co.za</strong></a>
		</div>
    </div>
		<div class="row-footer clearfix">
		<div class="flhs-content fLeft">&copy; {{year}} METROPOLITAN</div>
		<div class="frhs-content fRight">
			<a href="https://www.mhwellness.co.za/default.aspx?xGX7Y5WBkjmsUlL7tVtZYYw8OudhAiSaMJwEH6YyvgI8IFCZDrEWQsevoLoPJ8tXhKtl+lkPHTJrPBsAHp5kkw==" target="_blank">OUR SERVICES</a>
			<a href="https://www.mhwellness.co.za/default.aspx?xGX7Y5WBkjkN5c5YaaxpPLMgVbydvRQemUomqytqXOsoDJQ1jS3Pt61wZ5xksDIIPPFJFDJ8cgtev7MiK3hknQ==" target="_blank">HEALTH INFORMATION</a>
			<a href="https://www.mhwellness.co.za/default.aspx?xGX7Y5WBkjn/hvG5xoSlEwoojhLHL4YXaEl0E8mAQ0qQBLLQR3oV2pbfv4DKl7KJHEj0ivZTTqF+EJRHFyAeig==" target="_blank">CONTACT US</a>
		</div>
	</div>

	<div class="bottom_popia_well">
		<a href="https://www.momentum.co.za/momentum/support/legal-and-compliance" target="_blank">Legal and Compliance</a>&nbsp;|&nbsp;<a href="https://www.momentum.co.za/momentum/support/security-and-fraud" target="_blank">Security and Fraud</a>&nbsp;|&nbsp;<a href="https://www.momentum.co.za/momentum/support/terms-and-conditions" target="_blank">Terms and Conditions</a>&nbsp;|&nbsp;<a href="https://www.momentum.co.za/momentum/support/privacy-policy" target="_blank">Privacy Policy</a>&nbsp;&nbsp;<a [hidden]="loggedOut" href="https://content.momentum.co.za/mmsa/hellodoctor/pdf/Hello Doctor - STANDARD SUBSCRIPTION TERMS CONDITIONS - 1 March 2024.pdf" target="_blank">| Hello Doctor Terms & Conditions </a>
    </div>
	<div class="bottom text-centre">
		<p>Call your EWP for counselling and support on any matters. It is free, confidential and available 24/7/365 in all SA official languages</p>
	</div>
	<div class="row-footer clearfix">
		<div class="flhs-content fLeft">&nbsp;</div>
		<div class="frhs-content fRight">
			<span class="fRight powered-text">Powered by Metropolitan Health Corporate</span>
		</div>				
	</div>
</div>

