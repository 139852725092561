<div class='search-tool'>
	<div class='search-input'>
		<input type='text' name='search' placeholder='Search'>
	</div>
	<div class='date-range-container'>
		
	</div>
</div>

<table class='data-table' cellpadding='0' cellspacing='0' border='0'>
	<tr class='header-cols'>
		<td>Case no</td>
		<td>Name</td>
		<td>Service type</td>
		<td>Status</td>
		<td>Start</td>
		<!--<td>Time</td>-->
		<td>&nbsp;</td>
	</tr>
	<tr class="row" *ngFor="let item of caseData; let i = index" [hidden]="isLoading">
		<td class='col'>{{ item.generatedId == "Auto Generated" ? "N/A yet" : item.generatedId }}</td>
		<!--<td class='col' *ngIf="!i.generatedId">0{{ i+1 }}</td>-->
		<td class='col'>{{ item.fullName  }}</td>
		<td class='col'>{{ item.ItemCode }}</td>
		<td class='col'>{{ item.Status }}</td>
		<td class='col'>{{ item.StartDate | date: 'dd MMMM yyyy' }}</td>
		<!--<td class='col'>{{ item.CreatedDate | date:"HH:mm" }}</td>-->
		<td class='col td-arrow-down' (click)="getFollowUpData(i,item.RequestID)">
			<div class='extended-content' [hidden]="!item.hideme">
				<table cellpadding='0' cellspacing='0' border='0'>
					<tr>
						<td>{{ item.generatedId == "Auto Generated" ? "N/A yet" : item.generatedId }}</td>
						<td>{{ item.fullName  }}</td>
						<td>{{ item.ItemCode }}</td>
						<td>{{ item.Status }}</td>
						<td>{{ item.StartDate | date: 'dd MMMM yyyy' }}</td>
						<!--<td>{{ item.CreatedDate | date:"HH:mm" }}</td>-->
						<td class='td-arrow-up'></td>
					</tr>
				</table>
				<div class='info-blocks clearfix'>
					<div class='counsellor'>
						<h4>Location of next session</h4>
						<p *ngIf="nextSessionLocation">{{ nextSessionLocation }}</p>
					</div>
					<div class='counsellor'>
						<h4>Case follow-up sessions</h4>
						<div class="timeline">
							<p *ngIf="caseFUSessions.length == 0">
								* There are currently no follow-up sessions available for this person
							</p>							
							<div class="case-date" *ngFor="let fus of caseFUSessions; let j = index">
								<div class="data" 
									(mouseover)="checkLocation(j)" 
									(mouseout)="deactivateActiveStates()" 
									[ngClass]="{ 'hover-active': isHoverActive == true }">
									<div class="status-img">
										<img src="../assets/img/icons/cfu/case_complete_circle.svg" 
											*ngIf="fus.Status == 'REALIZED'" 
											alt="" />
										<img src="../assets/img/icons/cfu/case_pending_circle.svg" 
											*ngIf="fus.Status != 'REALIZED'" 
											alt="" />											
									</div>
									<div class="date" *ngIf="fus.StartDate && fus.CreatedDate">
										{{ fus.StartDate | date: 'dd' }}/{{ fus.StartDate | date: 'MM' }}/{{ fus.StartDate | date: 'yyyy' }}
									</div>
									<div class="date" *ngIf="fus.CreatedDate && !fus.StartDate">
										*Date N/A
									</div>									
								</div>
								<div class="divider">
									<!--<img src="../assets/img/icons/cfu/case_complete_line.svg" 
										*ngIf="fus.RequestNumber == '212489'" 
										alt="" />-->
									<img *ngIf="j != caseFUSessions.length - 1" 
										src="../assets/img/icons/cfu/case_pending_line.svg" 
										alt="" />
								</div>
							</div>
						</div>					
					</div>
				</div>						
			</div>			
		</td>
	</tr>
</table>
<div class="alerts">
	<div class="loader" *ngIf="isLoading"></div>
	<p class="red-error-text" *ngIf="noData">There is no case data for this employee.</p>
</div>