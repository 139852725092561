import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wellness-cafe',
  templateUrl: './wellness-cafe.component.html',
  styleUrls: ['./wellness-cafe.component.css']
})
export class WellnessCafeComponent implements OnInit {

  	hdData: any = [];

  constructor() { 

    let getHDPostsLS = localStorage.getItem('hdPosts');
		if(getHDPostsLS != null){
			this.filterHDPostData(JSON.parse(getHDPostsLS));
		}
		// get HD posts
		this.getHDPosts().then((data) => {
			localStorage.setItem('hdPosts', JSON.stringify(data));		
			this.filterHDPostData(data);
		});
  }

  ngOnInit(): void {
  }

filterHDPostData(data) {
  let filteredData = data.map(i => {
    let img = i._embedded['wp:featuredmedia'][0].media_details.sizes.medium.source_url;
    let excerpt = i.excerpt.rendered;
    return {
      id: i.id,
      image: img,
      title: i.title.rendered.substring(0, 30).trim(),
      descrip: excerpt.substring(0, 175).trim(),
      link: i.link
    }
  });
  this.hdData = filteredData;
}

async getHDPosts() {
  let response = await fetch(`https://www.hellodoctor.co.za/wp-json/wp/v2/posts/?per_page=9&categories_exclude=30763&_embed`);
  let data = await response.json();
  return data;
}

openHDPost(link) {
  window.open(link);
}

}
