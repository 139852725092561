import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '../shared/global-data';
import { Router } from '@angular/router';
import { ElearningService } from '../shared/services/elearning.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { FormComponent } from '../components/shared/case/form/form.component';
import { style } from '@angular/animations';
declare var gtag

interface AnswerMapper {
  surveyQuestionID: number;
  topicID: number;
  answer: number;
  userName : string;
  answerID : number;
}

interface SubmitMapper {
  username: string;
  answers: FormArray
}


@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {

  survey_form: FormGroup;
  idNumber: string = '';
  firstName: string = '';
  lastName: string = '';
  fullName: string = '';
  
  isLoading: boolean = false;
  topiclist: any[];
  surveyList: any[];
 surveyCompleted : boolean = false;
  eLearningTopic = '';
  submitData: any;
  showQuestionArray: boolean = false;
  topicID: any;
  topicmainheadernav: string;
  topicmaindescription: string;




  constructor(private router: Router,
    private globalData: GlobalDataService,
    private elearningService: ElearningService,
    private fb: FormBuilder) {

    this.survey_form = this.fb.group({
      questionArray: this.fb.array([])
    });
  }



  ngOnInit(): void {

    this.topicID = localStorage.getItem("TopicID");
    this.topicmainheadernav = localStorage.getItem("TopicMainHeader");
    this.topicmaindescription = localStorage.getItem("TopicMainDescription");
    
    $('body').addClass('mini-menu'); // Add mini dashboard menu
    $('.component-content').css("box-shadow", "unset"); //Remove box Shadow from global component
    $('.component-content').css("background-color", "transparent"); //Remove box Shadow from global component
    $('.hp-buttons').css("display", "none"); //Remove HP buttons- from global component
    this.getTopics();

  }

  submitObj: SubmitMapper;

  get questionArray() {
    return this.survey_form.controls["questionArray"] as FormArray;
  }

  addQuestionController(questionID) {

   let userName  = localStorage.getItem("username")
   
    const questiongrp = this.fb.group({
      surveyQuestionID: [questionID],
      topicID: [this.topicID],
      answerID: [0],
      answer: ['', Validators.required],
      userName : [userName]
    });
    this.questionArray.push(questiongrp);
  }

  getTopics() {
    this.isLoading = true;
    this.elearningService.getTopics().subscribe(result => {
      if (result !== undefined) {
        $(window).scrollTop(0);
        this.topiclist = result.body.topics;
        console.log("topics....", this.topiclist);

        for (let index = 0; index < this.topiclist.length; index++) {
          const topic = this.topiclist[index];


          if (topic.topicID == this.topicID) {
            this.surveyList = result.body.topics[index].surveyQuestions;

            let counter = 0;
            this.surveyList.forEach(question => {
              this.addQuestionController(question.surveyQuestionID);

              counter++;
              if (counter == this.surveyList.length) {
                this.showQuestionArray = true;
              }

            });
          }



        };
      }
    });




    this.isLoading = false;
  }

  setValue(surveyIndex, answerValue) {
    
   this.questionArray.get(surveyIndex.toString()).get('answer').setValue(answerValue.toString());
  }

  

  saveSurvey() { 

    this.isLoading = true;
    let username = localStorage.getItem("username");

    
    this.submitObj = ({ username: username, answers: this.questionArray.value });
    this.elearningService.saveSurvey(this.submitObj).subscribe(result => {
      if (result !== undefined) {
        this.surveyCompleted = true;
        $(window).scrollTop(0); // Move to the top 
        this.elearningSurveySubmit(); // GA Elearning Survey Submit 
        this.total_windows_wellness_checks_completed()
      }

    });
  }

  backmBtn(){
    this.router.navigate(['e-learning']);
  }

  total_windows_wellness_checks_completed(){
    gtag('event', 'Total Wellness Windows Checks Completed', {'event_category': 'Total Wellness Windows Checks Completed', 'event_label': 'Total Wellness Windows Checks Completed'})  
  }

  elearningSurveySubmit(){
    this.topicID = localStorage.getItem("TopicID");
    gtag('event', 'Elearning Survey Submit - Topic '+this.topicID+' Click', {'event_category': 'Member MHWwellness ELearning Survey - Post Login', 'event_label': 'Member MHWwellness Elearning Survey - Post Login'})  
  }
  

}


