import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse,HttpEventType } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GlobalDataService } from '../../shared/global-data';
import * as Enums from '../../shared/enums';

@Injectable({
  providedIn: 'root'
})
export class ReghellodoctorService {

  httpOptions: any = {};

  constructor(public http: HttpClient) { }

  authenticate (dataObj): Observable<any> {
		return this.http.post(Enums.API_BASE_PATHS + Enums.API_PATHS.AUTHENTICATE, JSON.stringify(dataObj)).pipe(
			tap( success => console.log('success'),
   				error => console.log('authenticate EAP ...API error!'))
		);
	}
	
	verify (dataObj): Observable<any> {
    let bear = localStorage.getItem("hdregtoken");

		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
				'Authorization': 'Bearer '+bear
			})
		}	
		return this.http.post(Enums.API_BASE_PATHS + Enums.API_PATHS.HELLO_DOCTOR + "/verify", JSON.stringify(dataObj), this.httpOptions).pipe(
			tap( success => console.log('success'),
   				error => console.log('verify HD details did not work...API error!'))
		);
	}
	registerHDUser (regObj): Observable<any> {
    let bear = localStorage.getItem("hdregtoken");
		
		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
				'Authorization': 'Bearer '+bear
			})
		}	
		return this.http.post(Enums.API_BASE_PATHS + Enums.API_PATHS.HELLO_DOCTOR + "/register", JSON.stringify(regObj), this.httpOptions).pipe(
			tap( success => console.log('success'),
   				error => console.log('Register HD user did not work...API error!'))
		);
	}
	createHDCallback (callBackObj): Observable<any> {
		return this.http.post(Enums.API_BASE_PATHS + Enums.API_PATHS.HELLO_DOCTOR + "/initiate", JSON.stringify(callBackObj)).pipe(
			tap( success => console.log('success'),
   				error => console.log('Create HD callback did not work...API error!'))
		);
	}		
	private handleError<T> (operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			//this.logException(Enums.LEVELS.ERROR, operation + ':' + error.message);
			return of(result as T);
		};
	}
	private handleAuthError(err: any): Observable<HttpErrorResponse> {
		throw err;
	}	
	private log(message: string) {
		//console.log(`${message}`);
	}	
}
