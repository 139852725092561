import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { PASSWORD_REGEX } from '../../../shared/utilities';
import { SecurityService } from '../../../shared/services/security.service';
import { distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'app-forgotpass',
  templateUrl: './forgotpass.component.html',
  styleUrls: ['./forgotpass.component.css']
})
export class ForgotpassComponent implements OnInit {

	resetPassForm: FormGroup;
	isUserFound: boolean;
	isLoading: boolean = false;
	formSuccess: boolean = false;
	oldPassIncorrect: boolean;
	forgotpassActive: boolean = false;
	showPasswordHints: boolean = true;
	FP_linkSent: boolean;
	isPassHintsOpen: boolean = false;
	errMsg: any = '';
	passMatchTxt: string = '';

	constructor(private fb: FormBuilder, private sercurityServ: SecurityService,
		public currRoute: ActivatedRoute) {}

	ngOnInit() {
		$('body').addClass('logged-out-view');
		/* ------------------ BASE FORM ------------------- */
		this.resetPassForm = this.fb.group({
			username: ['', [Validators.required, Validators.minLength(4)]],
			oldpass: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
			newpass: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
		});
		this.currRoute.params.subscribe(params => {
			if(typeof params.id != "undefined"){
				this.activateForgotPass();
			}
		});
		this.resetPassForm.statusChanges.subscribe(val => {
			if(val == 'VALID'){
				//this.showPasswordHints = false;
				this.passMatchTxt = '';
			}
		});
		this.checkPasswords();
	}

	checkPasswords(){
		this.resetPassForm.get('oldpass').valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
			if(val.length < 8){
				this.passMatchTxt = 'Please enter a password with 8 characters or more.';
				this.resetPassForm.get('oldpass').setErrors({'incorrect': true});
			}			
		});
		this.resetPassForm.get('newpass').valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
			if(val.length < 8){
				this.passMatchTxt = 'Please enter a password with 8 characters or more.';
				this.resetPassForm.get('newpass').setErrors({'incorrect': true});
			}			
		});

		/*
		this.resetPassForm.get('oldpass').valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
			let getNewPass = this.resetPassForm.get('newpass').value;
			if((val == getNewPass) && val.length >= 8 && getNewPass.length >= 8){
				this.showPasswordHints = false;
			} else {
				if(getNewPass.length > 0){
					this.passMatchTxt = 'Your password does not match.';
				}
				if(val.length < 8){
					this.passMatchTxt = 'Please enter a password with 8 characters or more.'
				}
				this.showPasswordHints = true;
				this.resetPassForm.get('oldpass').setErrors({'incorrect': true});
			}
		});
		this.resetPassForm.get('newpass').valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
			let getOldPass = this.resetPassForm.get('oldpass').value;
			if((val == getOldPass) && val.length >= 8 && getOldPass.length >= 8){
				this.showPasswordHints = false;
			} else {
				if(getOldPass.length > 0){
					this.passMatchTxt = 'Your password does not match.';
				}
				if(val.length < 8){
					this.passMatchTxt = 'Please enter a password with 8 characters or more.'
				}				
				this.showPasswordHints = true;
				this.resetPassForm.get('newpass').setErrors({'incorrect': true});
			}
		});*/
	}

	activateForgotPass(){
		this.resetPassForm.controls['oldpass'].clearValidators();
		this.resetPassForm.controls['newpass'].clearValidators();
		this.resetPassForm.controls['oldpass'].updateValueAndValidity();
		this.resetPassForm.controls['newpass'].updateValueAndValidity();
		this.forgotpassActive = true;
		this.showPasswordHints = false;
	}

	resetPassword(){
		let getUser = this.resetPassForm.get('username').value;
		this.isUserFound = null;
		this.FP_linkSent = null;
		this.isLoading = true;
		this.sercurityServ.getUserData(getUser).subscribe(resp => {
			if(resp.statusCodeValue == 404){
				this.isUserFound = false;
				this.isLoading = false;
			} else {
				if(this.forgotpassActive){ // resend email based on username
					let accountIsLocked = resp.body[0].accountStatus.indexOf("LOCKED") > -1;
					if(accountIsLocked){ // first unlock account then reset password
						let getGuid = resp.body[0].uniqueId;
						this.sercurityServ.unlockAccount(getGuid).subscribe(resp => {
						    if(resp.statusCodeValue == 200){
								this.sercurityServ.forgotPassword(getUser).subscribe(resp => {
									this.isLoading = false;
									if(resp.statusCodeValue == 200){
										this.FP_linkSent = true;
									} else {
										this.FP_linkSent = false;
									}
								}, err => {
									this.isLoading = false;
									this.FP_linkSent = false;
								});
						    } else {
						        //this.failMsg();
						    }
						}, err => {
						    //this.failMsg();
						});
					} else { // account is NOT locked, continue as per normal
						this.sercurityServ.forgotPassword(getUser).subscribe(resp => {
							this.isLoading = false;
							if(resp.statusCodeValue == 200){
								this.FP_linkSent = true;
							} else {
								this.FP_linkSent = false;
							}
						}, err => {
							this.isLoading = false;
							this.FP_linkSent = false;
						});
					}
				} else { // full reset based on username and passwords
					let getUniqId = resp.body[0].uniqueId;
					let oldPass = this.resetPassForm.get('oldpass').value;
					let newPass = this.resetPassForm.get('newpass').value;
					this.isUserFound = true;

					let passwordRequestModel ={
						uniqueId: getUniqId,
						oldPassword: oldPass,
						newPassword: newPass
					}
					
					this.sercurityServ.resetPassword(passwordRequestModel).subscribe((resp) => {
						if(resp.statusCodeValue == 200){
							this.formSuccess = true;
						}
					}, err => {
						if(err.status == 404){
							this.oldPassIncorrect = true;
							this.isLoading = false;
							return
						}
						this.isUserFound = false;
						this.isLoading = false;
					});
				}
			}
		}, err => {
			this.isUserFound = false;
			this.isLoading = false;
		})
	}
}
