import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalDataService } from '../../../shared/global-data';
import { distinctUntilChanged } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.css']
})
export class CaseComponent implements OnInit {

	currTBlockStep: number;
	mainType: string = 'EAP';

	constructor(private route: ActivatedRoute, 
		private globalData: GlobalDataService,private lsService: StorageMap,) {}

	ngOnInit() {
		this.route.params.subscribe(data => {
			if(typeof data.dataroute != 'undefined'){
				setTimeout(() => {
					switch (data.dataroute) {
						case "casetable":
							$(".tab").eq(1).trigger("click");
							break;
						case "casecalendar":
							$(".tab").eq(2).trigger("click");
							break;						
						default:
							break;
					}
				}, 400);
			} else {
				setTimeout(() => { this.globalData.changeTBlockState(1) }, 400);
			}
		});
		this.globalData.tBlockStep.pipe(distinctUntilChanged()).subscribe(val => {
			if(val == 2){ // managerial referral
				this.mainType = 'EAP';
			} else if(val == 3){ // CISD
				this.mainType = 'CISD';
			} else {
				this.mainType = 'EAP';
			}
			
			this.lsService.get('profileData').subscribe((lsData: any) => {
				if(typeof lsData !== 'undefined'){
					this.mainType = lsData.isNedBank ? "EWP" : this.mainType;
				}
			});
		});
		// Tabs
		$("body").on("click", ".tab", function(){
			let getIndex: any = $(this).attr('data-index');
			$(".tab-header .active").removeClass('active');
			$(this).addClass('active');
			$(".tcontent").removeClass('active');
			$(".tcontent").eq(getIndex).addClass('active');
		});
	}
}
