export const PASSWORD_REGEX = '^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@_#$%^&*~?-])[a-zA-Z0-9!@_#$%^&*~?-]{8,16}$';

export function findInvalidControls(formName) {
	const invalid = [];
	const controls = formName.controls;
	for (const name in controls) {
		if (controls[name].invalid) {
			invalid.push(name);
		}
	}
	return invalid;
}

export function sortByCreatedDate(arr,fieldType){
	let sorted = arr.sort((a,b) => {
		let dateA = new Date(a[fieldType]);
		let dateB = new Date(b[fieldType]);
		let exp_MinusOne = dateA < dateB;
		let exp_One = dateA > dateB;
		if(fieldType == 'CreatedDate'){
			exp_MinusOne = dateA > dateB;
			exp_One = dateA < dateB;
		}
		if (exp_MinusOne) {
			return -1;
		}
		if (exp_One) {
			return 1;
		}
		return 0;
	});
	return sorted;
}

export function	verifyAuthId(id){
	let correct = true;
	let tempDate = new Date(id.substring(0, 2), id.substring(2, 4) - 1, id.substring(4, 6));
	let id_date = tempDate.getDate();
	let id_month = tempDate.getMonth();
	let id_year = tempDate.getFullYear();    
	if (!((id_year == id.substring(0, 2)) && (id_month == id.substring(2, 4) - 1) && (id_date == id.substring(4, 6)))) {
		correct = false;
	} else {
		correct = true;
	}
	var tempTotal = 0;
	var checkSum = 0;
	var multiplier = 1;
	for (var i = 0; i < 13; ++i) {
		tempTotal = parseInt(id.charAt(i)) * multiplier;
	if (tempTotal > 9) {
		tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
	}
	checkSum = checkSum + tempTotal;
		multiplier = (multiplier % 2 == 0) ? 1 : 2;
	}
	if ((checkSum % 10) != 0) {
		correct = false;
	} else {
		correct = true;
	}
	return correct;
}

export function filteredMultiArray(arr) {
	let flattened = [];
	let filteredArray = arr.map(item => {
		return item["body"]["results"].filter(item => {
			if(flattened.indexOf(item) <= -1){
				flattened.push(item);
			}
		});
	});
	return flattened.sort()
}