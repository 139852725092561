import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpClient, HttpResponse, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { GlobalDataService } from '../../shared/global-data';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

	mainToken: any;

	constructor(public http: HttpClient, private globalServ: GlobalDataService,
		private lsService: StorageMap){}	

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if(request.url.indexOf('security') > -1 || request.url.indexOf('authenticate') > -1
		 || request.url.indexOf('/synergy/userexist') > -1 || request.url.indexOf('sls') > -1){
			request = request.clone({ headers: request.headers.set('Authorization', 'Basic ' + btoa('exchange:Exchange@1')) });
		} else if(request.url.indexOf('/synergy/updateuser') > -1){ // temp solution for DEMO 22 May 2020!
			let tempBearer = `eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJrYWxlaiIsImF1dGgiOiJVU0VSIiwiZXhwIjoxNTkwNTIxMTY2fQ.zokORsEpFD9flDaLgCsP2TiwZskn2N5xk-NxcMWE5w4`;
			request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + tempBearer) });
		} else {
			if(request.url.indexOf('api.powerbi.com') <= -1 && request.url.indexOf('onrender') <= -1){ // skip over power BI url
				let getLSToken = JSON.parse(localStorage.getItem('grepsislwr29'));
				const bearerToken = getLSToken;
				if (bearerToken) {
					request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + bearerToken) });
				}
			}
		}
		if (!request.headers.has('Content-Type')) {
			request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
		}
		request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					//console.log('event--->>>', event);
				}
				return event;
		}));
	}
}