import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../../shared/services/reports.service';
import { fromEvent } from 'rxjs';
import { delay } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-cofs',
  templateUrl: './cofs.component.html',
  styleUrls: ['./cofs.component.css']
})
export class CofsComponent implements OnInit {

	cofData: any = [];
	isLoading: boolean = false;
	noData: boolean = false;
	isInvalidID: boolean = false;
	tabNames: any = [];

	constructor(private reportService: ReportsService, private lsService: StorageMap) { }

	ngOnInit(): void {
		let getCoflabels = JSON.parse(localStorage.getItem('cofLabels'));
		this.tabNames = getCoflabels;
		let that = this;
		$("body").on("click",".tab", function(e){
			let getIndex: any = $(this).attr('data-index');
			$(".tab-header .active").removeClass('active');
			$(this).addClass('active');
			$(".tcontent").removeClass('active');
			$(".tcontent").eq(getIndex).addClass('active');
			
			// clear input field and table data
			$(".search-input input").val('');
			that.cofData.length = 0;
			that.noData = false;
		});
		$("html, body").animate({ scrollTop: 0 }, "slow");
		setTimeout(() => { $(".tab").eq(0).trigger("click") }, 400);
		// set dynamic breadcrumb
		$('.dynamic-breadcrumb-text').text('Certificate of fitness');		
	}

	filterSearch(val){
		this.isInvalidID = false;
		this.cofData.length = 0;
		this.noData = false;
		if (val.length == 13) {
			if(this.verifyAuthId(val)){
				this.isLoading = true;
				this.reportService.getCOFReports(val).pipe(delay(2000)).subscribe(res => {
					let getCurrTabLabel = $(".cof-reports").find(".active").attr('data-name');
					console.log(getCurrTabLabel);
					//let labelHasData = res.body.results.filter(i => i.cofType == getCurrTabLabel).length > 0;
					let labelHasData = res.body.results[0].cofType;
					if(labelHasData == getCurrTabLabel){
						this.cofData = res.body.results;
					} else if(getCurrTabLabel == "PRE-EMPLOYMENT" && labelHasData == "TRAINEES"){
							this.cofData = res.body.results;
					} else {
						this.noData = true;
					}
					this.isLoading = false;
				}, err => {
					this.noData = true;
					this.isLoading = false;					
				});
			} else {
				this.isInvalidID = true;
			}
		}
	}

	verifyAuthId(id){
		let correct = true;
		let tempDate = new Date(id.substring(0, 2), id.substring(2, 4) - 1, id.substring(4, 6));
		let id_date = tempDate.getDate();
		let id_month = tempDate.getMonth();
		let id_year = tempDate.getFullYear();    
		if (!((id_year == id.substring(0, 2)) && (id_month == id.substring(2, 4) - 1) && (id_date == id.substring(4, 6)))) {
		    correct = false;
		} else {
		    correct = true;
		}
		let tempTotal = 0;
		let checkSum = 0;
		let multiplier = 1;
		for (var i = 0; i < 13; ++i) {
		    tempTotal = parseInt(id.charAt(i)) * multiplier;
		    if (tempTotal > 9) {
		        tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
		    }
		    checkSum = checkSum + tempTotal;
		    multiplier = (multiplier % 2 == 0) ? 1 : 2;
		}
		if ((checkSum % 10) != 0) {
		    correct = false;
		} else {
		    correct = true;
		}
		return correct;	
	}

	openFile(fileName){
		let byteArray = new Uint8Array(atob(fileName).split('').map(char => char.charCodeAt(0)));
		let blob = new Blob([byteArray], {type: 'application/pdf'});		
		let url = window.URL.createObjectURL(blob);
		window.open(url);
	}	
}
