import { Component, OnInit } from '@angular/core';
import { GlobalDataService } from '../shared/global-data';
import { QuestionnaireService } from '../shared/services/questionnare.service';
import { Router } from '@angular/router';
declare var gtag


@Component({
  selector: 'app-wellbeing-score',
  templateUrl: './wellbeing-score.component.html',
  styleUrls: ['./wellbeing-score.component.css']
})
export class WellbeingScoreComponent implements OnInit {

  score: string ="";
  scoreRate: string ="";
  labelScore:string="";

  constructor( private router: Router, private globalData: GlobalDataService, private queServ:QuestionnaireService,) { }

  ngOnInit(): void {
 
    this.getScore();
  }


  loadView(page){
    if(page == 'suggested-resource'){
      //this.globalData.changeTBlockState(6);
      this.globalData.changeCurrentViewState('suggested-resource');
     
      gtag('event', ' Number of Wellbeing score view evaluation', {'event_category': 'Number of Wellbeing score “view evaluation', 'event_label': 'Number of Wellbeing score “view evaluation'});	
    }
    else{
      this.globalData.changeTBlockState(1);
      this.globalData.changeCurrentViewState('questionnaire');
      gtag('event', 'Number of Wellbeing score update', {'event_category': 'Wellbeing score “update”', 'event_label': 'Wellbeing score “update'});		

    }
    this.router.navigate([page]);
    }

    getScore() {
      var id = localStorage.getItem("userIdNumber");
      let idnumber = JSON.parse(id);
      this.queServ.getScoreQuestionnaire(idnumber).subscribe(result => {
        if (result !== "undefined") {
          if(result?.errorCode=="401")
            {
              this.score = "0";	
              this.scoreRate = "No Rating"
              $('.firstime').hide();
              this.labelScore = "new";
            }
            else
            {
          this.score = result[0].score
  
          if (this.score <= "20") {
            this.scoreRate = "is good";
            this.labelScore = "green";
          } else if (this.score <= "45") {
            this.scoreRate = "could be better";
            this.labelScore = "old";
          } else if (this.score <= "100"){
            this.scoreRate = "is at risk";
            this.labelScore = "new";
          }else{
              this.score = "99";
              this.scoreRate = "is at risk";
            this.labelScore = "new";
          }
        }
  
        }else{
        this.score = "0";	
        this.scoreRate = "No Rating"
        $('.firstime').hide();
        this.labelScore = "new";
      }
  
      });
    }

}