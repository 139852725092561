import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import * as _util from '../../../shared/utilities';
import { SecurityService } from '../../../shared/services/security.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { SynergyService } from '../../../shared/services/synergy.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

	regForm: FormGroup;
	isLoading: boolean = false;
	userExists: boolean = false;
	currStep: number = 1;
	errorMsg: string = '';
	global_surname: any;
	global_name: any;
	isDis : any;
	constructor(private fb: FormBuilder, 
		private sercurityServ: SecurityService,
		private synServ: SynergyService) {}

	ngOnInit() {
		$('body').addClass('logged-out-view');
		/* ------------------ BASE FORM ------------------- */
		this.regForm = this.fb.group({
			empNumber: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_-]*$')]],
			firstname: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+(-[a-zA-Z]+)*$')]],
			surname: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]+(-[a-zA-Z]+)*$')]],
			contactNo: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
			email: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$')]],
			//username: ['', [Validators.required, Validators.minLength(4)]],
			idNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(13)]],
			passportNumber: [''],//['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_-]*$')]],//['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(13)]]
			searchType:null
		});		
		this.checkID();
		this.isDis = localStorage.getItem('loadMomWellnessStylesheet');
		//console.log(this.isDis)
	}

	checkID(){
		this.regForm.get('idNumber').valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
			if(val.length >= 13){
				if(!_util.verifyAuthId(val)){
					this.regForm.controls['idNumber'].setErrors({'incorrect': true});
					this.errorMsg = `Please enter a valid ID number`;
				} else {
					this.regForm.controls['idNumber'].setErrors(null);
					this.errorMsg = '';
				}
			}
		});
	}

	OnClick(data:any)
	{
		if(data=="passport")
		{
			let idNum = this.regForm.get("idNumber");
			idNum.clearValidators();
			idNum.updateValueAndValidity();

			let passportNumber = this.regForm.get("passportNumber");
			passportNumber.setValue('');
			passportNumber.setValidators(Validators.required);
			passportNumber.updateValueAndValidity();
		}
		else
		{
			let passportNumber = this.regForm.get("passportNumber");
			passportNumber.clearValidators();
			passportNumber.updateValueAndValidity();
			
			let idNum = this.regForm.get("idNumber");
			idNum.setValue('');
			idNum.setValidators([Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(13)]);
			idNum.updateValueAndValidity();
			this.checkID();
		}
	}

	register(){
		this.isLoading = true;
		this.errorMsg = '';
	
		let regObj = {
			accountStatus: "ACCOUNT_ACTIVE",
			cellularNumber: this.regForm.get('contactNo').value,
			clientNumber: this.regForm.get('empNumber').value,
			emailAddress: this.regForm.get('email').value,
			idNumber: this.regForm.get('idNumber').value,
			firstname: this.regForm.get('firstname').value,
			surname: this.regForm.get('surname').value,
			username: this.regForm.get('email').value,
			passportNumber: this.regForm.get('passportNumber').value,
			debtorCode : "",
			parentCode : "",
		}
		let id = this.regForm.get('idNumber')?.value==""?this.regForm.get('passportNumber').value:this.regForm.get('idNumber').value;
		// first check if user exists before registering
		let clientNumber = this.regForm.get('empNumber').value;
		let surname = this.regForm.get('surname').value;
		localStorage.setItem('ContactNumber', this.regForm.get('contactNo').value);
		localStorage.setItem("userIdNumber", id );
		localStorage.setItem("hdname",this.regForm.get('firstname').value);
		localStorage.setItem("hssurname", surname );

        this.synServ.doesUserExist(clientNumber,surname).subscribe(resp => {
            if(resp.statusCodeValue == 200){         
		
            	let checkResults = resp.body.results;
            	if(checkResults.length > 0){ // continue to register
					let empStatus = checkResults[0].CustomerStatus;
					regObj.debtorCode = checkResults[0].DebtorCode;
					regObj.parentCode = checkResults[0].ParentCode;
            		if(empStatus == 'A'){
				
						this.sercurityServ.registerUser(regObj).subscribe(resp => {
							this.isLoading = false;
							if(resp.statusCodeValue == 409){
								this.userExists = true;
		
							} else {
								
								// update synergy with email address
								let innerResults = checkResults[0];
								let emailAddy = this.regForm.get('email').value;
								let contactNo = this.regForm.get('contactNo').value;
								let CompanyCodeDebtorAccountID = innerResults.CompanyCodeDebtorAccountID;
								let emailObj = {
									ContactEmail: emailAddy,
									ContactMobileNo: contactNo,
									ID: CompanyCodeDebtorAccountID,
									CompanyCodeDebtorAccountID: CompanyCodeDebtorAccountID,
									TextFreeField24:this.regForm.get('idNumber').value					
								}
								this.synServ.updateEmailOnSynergy(emailObj).subscribe(resp => {
									this.userExists = false;
									this.currStep = 2;
								});
							}
						}, err => {
							this.isLoading = false;
							if(err.error.errorMessage.indexOf("cellularNumber is not") > -1){
								this.errorMsg = 'You have entered an invalid contact number. Please try again!';
							} else if(err.error.errorMessage.indexOf("identifiers already exists") > -1){
								this.errorMsg = 'This user already exists in our system. Please login';
							} else {
								this.errorMsg = 'This user already exists in our system. Please login';
							}
						});

				
						
            		} else {
						this.isLoading = false;
						this.errorMsg = `User is currently de-activated in our system. 
							Please contact 080 0111 223 to resolve the issue.`;						
            		}
            	} else {
		        	this.errorMsg = `This user does not exist on synergy.  
		        		Please contact 080 0111 223 to resolve the issue.`;
            	}
            	this.isLoading = false;
            } else {
            	this.isLoading = false;
	        	this.errorMsg = `This user does not exist on synergy.  
	        		Please contact 080 0111 223 to resolve the issue.`;          	
            }
        }, err => {
        	this.isLoading = false;
        	this.errorMsg = `This user does not exist on synergy.  
        		Please contact 080 0111 223 to resolve the issue.`;
        });

	}
}
