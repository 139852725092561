<div class="page fw-page" id="fw-page">
<body
	class="page-template-default page page-id-10143 page-child parent-pageid-9747 custom-background et-tb-has-template et-tb-has-footer et_button_custom_icon et_pb_button_helper_class et_fixed_nav et_hide_nav et_hide_primary_logo et_hide_fixed_logo et_secondary_nav_enabled et_secondary_nav_two_panels et_primary_nav_dropdown_animation_fade et_secondary_nav_dropdown_animation_fade et_header_style_left et_cover_background et_pb_gutter windows et_pb_gutters3 et_pb_pagebuilder_layout et_smooth_scroll et_no_sidebar et_divi_theme et-db et_minified_css chrome">
	<div id="page-container" style="padding-top: 116px;">
		<div id="et-boc" class="et-boc">
			<div id="et-main-area">
				<div id="main-content">
					<article id="post-10143" class="post-10143 page type-page status-publish hentry">
						<div class="entry-content">
							<div class="et-l et-l--post">
								<div class="et_builder_inner_content et_pb_gutters3">
									<div
										class="et_pb_section et_pb_section_3 et_pb_fullwidth_section et_section_regular">
										<div
											class="et_pb_module et_pb_fullwidth_menu et_pb_fullwidth_menu_0 et_pb_bg_layout_light  et_pb_text_align_right et_dropdown_animation_fade et_pb_fullwidth_menu--without-logo et_pb_fullwidth_menu--style-left_aligned">
										</div>
									</div>
									<!-- .et_pb_section -->
									<div class="et_pb_section et_pb_section_6 et_pb_with_background et_section_regular">
										<div class="et_pb_row et_pb_row_2">
											<div
												class="et_pb_column et_pb_column_4_4 et_pb_column_4  et_pb_css_mix_blend_mode_passthrough et-last-child">
												<div
													class="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light">
													<div class="et_pb_text_inner">
														<h1>
															Financial Wellness
															<p></p>
														</h1>
													</div>
												</div>
												<!-- .et_pb_text -->
											</div>
											<!-- .et_pb_column -->
										</div>
										<!-- .et_pb_row -->
									</div>
									<!-- .et_pb_section -->
									<div class="et_pb_section et_pb_section_9 et_section_regular">
										<div class="et_pb_with_border et_pb_row et_pb_row_3 et_pb_equal_columns">
											<div
												class="et_pb_column et_pb_column_1_2 et_pb_column_5  et_pb_css_mix_blend_mode_passthrough">
												<div
													class="et_pb_module et_pb_cta_0 et_pb_promo  et_pb_text_align_center et_pb_bg_layout_dark">
													<div class="et_pb_promo_description">
														<h2 class="et_pb_module_header">Try our Financial Calculators
														</h2>
														<div><button
																class="pum-trigger  popmake-10939 mw-red-btn mw-redbtn-right .mw-redbtn-right"
																data-do-default="" style="cursor: pointer;"
																(click)="loadModal('financial_calc')">VIEW
																NOW</button>
														</div>
													</div>
												</div>
											</div>
											<!-- .et_pb_column -->
											<div
												class="et_pb_column et_pb_column_1_2 et_pb_column_6  et_pb_css_mix_blend_mode_passthrough et-last-child">
												<div
													class="et_pb_module et_pb_cta_1 et_pb_promo  et_pb_text_align_center et_pb_bg_layout_dark">
													<div class="et_pb_promo_description">
														<h2 class="et_pb_module_header">Download Financial Tools </h2>
														<div><button
																class="pum-trigger  popmake-10900 mw-red-btn mw-redbtn-left"
																data-do-default="" style="cursor: pointer;"
																(click)="loadModal('financial_tools')">DOWNLOAD
																NOW</button>
														</div>
													</div>
												</div>
											</div>
											<!-- .et_pb_column -->
										</div>
										<!-- .et_pb_row -->
										<div class="container et_pb_row_4">
											<div class="et_pb_column ">
												<div class="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left ">
													<div class="et_pb_text_inner">
														<h1><strong>Introduction</strong></h1>
														<p>All of our financial advice packages have been researched,
															reviewed and vetted by a team of experienced chartered
															accountants and lawyers. The packages have been
															professionally translated and are available in English,
															Afrikaans, Xhosa and Sesotho. Each advice package can be
															accessed via phone, email and/or the internet.&nbsp;
														</p>
														<h1 style="padding-top: 8px;"><strong>A Financial Consultant on Call:</strong></h1>
														<p>Everyone experiences the frustrations of staying on top of
															their finances and managing their money. When clients need
															professional guidance in their daily lives, they can pick up
															a phone and get immediate assistance from an experiences
															financial counselor able to guide them through day-to-day
															financial issues and challenges. Telephonic legal advice is
															available in 80% of South Africa’s official languages.
														</p>
														<p>This ad hoc financial support service allows everyone to get
															much needed financial support at any time during business
															hours. Assistance is given on a broad range of financial
															topics, including debt counseling, managing debt and
															budgets, black-listings, retirement, loans and associated
															litigation.
														</p>
													</div>
												</div>
												<!-- .et_pb_text -->
											</div>
											<!-- .et_pb_column -->
										</div>
										<!-- .et_pb_row -->
									</div>
									<!-- .et_pb_section -->
									<div class="et_pb_section et_pb_section_10 et_section_regular">
										<div class="et_pb_row et_pb_row_5">
											<div
												class="et_pb_column et_pb_column_4_4 et_pb_column_9  et_pb_css_mix_blend_mode_passthrough et-last-child">
												<div
													class="et_pb_module et_pb_text et_pb_text_3  et_pb_text_align_left et_pb_bg_layout_light">
													<div class="et_pb_text_inner">
														<h1>Financial advice packages</h1>
														<p>All of our financial advice packages have been researched,
															reviewed and vetted by a team of experiences chartered
															accountants and lawyers. The packages have been
															professionally translated and are available in English,
															Afrikaans, Xhosa and Sesotho. Each advice package can be
															accessed via phone, email and/or the internet. The packages
															include the following:
														</p>
														<p>The<span style="color: #666666;">&nbsp;<strong>‘What am I
																	Worth?</strong>’</span>&nbsp;advice topics and
															packages allow clients to learn the difference between an
															asset and a debt and to undertake a realistic assessment of
															their net worth. This includes spreadsheets that assist
															clients to calculate their net worth, s that they understand
															their financial starting point.
														</p>
														<p>The&nbsp;<strong>‘Time is Money’</strong><span
																style="color: rgba(0, 40, 86, 0.85);"><strong>&nbsp;</strong></span>range
															of advices topics and support packages allow clients to
															understand important financial concepts such as inflation,
															interest and compound interest. Practical examples of the
															effects of interest and inflation are provided and clients
															are provided with access to online interest and inflation
															calculators.
														</p>
														<p>The<span
																style="color: rgba(0, 40, 86, 0.85);"><strong>&nbsp;</strong></span><strong>‘Financial
																Planning’</strong><span
																style="color: rgba(0, 40, 86, 0.85);"><strong>&nbsp;</strong></span>support
															modules explore the client’s short-, medium- and long-term
															goals. This includes insights into the client’s underlying
															reasons for wanting money, the difference between financial
															‘wants’ and ‘needs’, and tools for setting realistic
															financial goals.
														</p>
														<p>The<strong>&nbsp;‘<span
																	style="color: rgba(0, 40, 86, 0.85);"><span
																		style="color: #3a3a3a;">Financial Health and
																		Budgeting’</span></span></strong><span
																style="color: #3a3a3a;">&nbsp;</span>services allow
															clients to receive detailed advice and support in relation
															to, inter alia, the following issues:
														</p>
														<ul>
															<li>What is a budget?</li>
															<li>How budgets can work for you</li>
															<li>Where budgets can go wrong?</li>
															<li>Identifying where your money goes</li>
															<li>Learning to prioritise: wants vs needs</li>
															<li>Cutting back – areas to focus on</li>
															<li>Ideas to increase income</li>
															<li>Exercises and tips for drawing up a budget</li>
															<li>Completing your own budget, including easy tools and
																spreadsheets
															</li>
														</ul>
														<p>The&nbsp;<span style="color: #666666;"><strong>‘Taking
																	Control of your Debt’</strong>&nbsp;</span>advice
															topics and packages provide detailed insights into the
															following financial issues and concepts:
														</p>
														<ul>
															<li>Why debt can be dangerous</li>
															<li>Some harsh facts about debt</li>
															<li>How much debt is too much?</li>
															<li>Avoiding debt, where possible</li>
															<li>Tips for using a credit card</li>
															<li>Explaining ‘black-listing’, credit scores &amp; their
																impact on you
															</li>
															<li>Managing over-indebtedness without resorting to
																debt-counselling
															</li>
														</ul>
														<p>The&nbsp;<strong>‘Debt Counselling under the
																NCA’</strong>&nbsp;financial support services provide
															clients with a detailed account of the debt of the debt
															relief procedures available under the National Credit Act.
														</p>
														<p>This includes an individual assessment of whether
															debt-counselling is appropriate, a step-by-step guide to the
															procedure, and detailed information relating to the costs
															and consequences of the debt relief procedures. Where
															appropriate clients will be referred for debt relief and
															counselling services via a national network of debt
															counselors (at NCR approved rates). To minimize the clients
															costs a Form 16 is completed on the client’s behalf (based
															on information provided by the client) and is provided to
															the client for submission to the debt counselor.
														</p>
														<p>The ‘Financing you House &amp; Car’ advice topics canvass
															everyday financial issues such as the advantages &amp;
															disadvantages of mortgages, what mortgages cost, different
															types of mortgages, what mortgage levels are affordable, and
															the advantages &amp; disadvantages of car financing.
														</p>
														<p>On the critical issue of<strong>&nbsp;‘Savings and Planning
																for the Future’</strong>&nbsp;clients will receive
															detailed guidance and counseling packages on issues such as:
														</p>
														<ul>
															<li>Why is it important to save?</li>
															<li>How should I be saving?</li>
															<li>How to start saving – managing money, debit orders and
																emergency funds?
															</li>
															<li>How much should I be saving?</li>
															<li>Types of saving accounts – banking options, stokvels and
																burial societies.
															</li>
															<li>Pyramid schemes explained – saving requires patience not
																promises
															</li>
															<li>What’s the best savings option for you – includes saving
																exercises and toolkit calculators.
															</li>
														</ul>
														<p>The&nbsp;<strong>‘Understanding Banks and
																Finance’</strong>&nbsp;range of counseling topics
															explains the different types of bank accounts to clients and
															gives them insight into how banks, credit card providers
															&amp; other financial institutions work. The information
															covers advice on how to reduce bank charges and interest, as
															well as canvassing traditional and alternative financing
															options such as bank loans, micro-lenders, retail store
															cards, personal loans and informal arrangements.
														</p>
														<p>The&nbsp;<strong>‘Planning for
																Retirement’&nbsp;</strong>counseling topics and packages
															explain the importance of retirement savings and give
															clients a sense of how much they will need for retirement,
															and what current savings are required to meet their desired
															income levels. These topics are complimented b y a
															retirement toolkit that incorporates retirement savings
															calculators and planning tools.
														</p>
														<p>The&nbsp;<strong>‘Debt and the Law’&nbsp;</strong>advice
															topics and support packages explain the legal consequences
															for a debt default and the procedures involved. These
															include:
														</p>
														<p>What to do when you receive a letter of demand or summons?
														</p>
														<p>Garnishee orders – How are they obtained? What are the
															consequences? How can an order be removed?
														</p>
														<p>The court litigation process – in simple terms</p>
														<p>What are the consequences of a judgement against your name?
															This includes easy-to-understand explanations of execution
															of judgements, rescission of judgments and payment
															restructuring plans.
														</p>
														<p>In addition to the above focused advice and support areas,
															Origin Systems will provide a
															detailed<strong>&nbsp;“Financial Fitness
																Report”&nbsp;</strong>and counseling package. Before
															receiving this report, the client will undergo a
															comprehensive interview process (undertaken online or
															telephonically) during which the client’s financial
															situation will be understood and his/her areas of financial
															risk identified. Based on the information collected, a
															detailed report customized to the client’s unique
															circumstances will be compiled and sent to the client. This
															report covers financial issues such as those set out above,
															but in a composite form and adapted to the client’s
															particular situation.
														</p>
														<h1>Online financial calculators</h1>
														<p>The above financial advice packages are provided together
															with a range of online financial calculators, including the
															following:
														</p>
														<ul style="padding-bottom: 50px;">
															<li>A retirement planning calculator</li>
															<li>A savings calculator</li>
															<li>An interest and loan repayment calculator</li>
															<li>An inflation calculator</li>
														</ul>
													</div>
												</div>
												<!-- .et_pb_text -->
											</div>
											<!-- .et_pb_column -->
										</div>
										<!-- .et_pb_row -->
									</div>
									<!-- .et_pb_section -->
								</div>
								<!-- .et_builder_inner_content -->
							</div>
							<!-- .et-l -->
						</div>
						<!-- .entry-content -->
					</article>
					<!-- .et_pb_post -->
				</div>
				<!-- #main-content -->
			</div>
			<!-- #et-main-area -->
		</div>
		<!-- #et-boc -->
	</div>
</body>
</div>