<div class="component-content-video ">
  <h3 class="block-title">Home<span class="arrow">&nbsp;>&nbsp;</span> <span class="current-header"><span  class="current-header"> elearning > {{topicmainheadernav}} > Video</span></span>
  </h3>
  <hr>

  <h4>{{newVideoHeader}}</h4>

  <video width="1127.45" (click)="total_topic_videos_downloaded()"
    src="{{videoUrl}}#t=3"
    controls></video>

  <div *ngIf="!isSiteMomWellness else back" (click)="mainQuestio()">
      <button  class="btn posistion_btn"><a class="font_btn" target="_blank">Continue</a></button>
  </div>

  
<ng-template #back>
  <button  (click)="goBack()" class="btn posistion_btn"><a class="font_btn" >Complete</a></button>
</ng-template>
 
</div>
