import { Component, OnInit } from '@angular/core';
import { CASE_DATA } from './case-data'; 
import { GlobalDataService } from '../../../../shared/global-data';
import { CasesService } from '../../../../shared/services/cases.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { take, distinctUntilChanged } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { PR_CODES } from '../../../../shared/enums';
import * as _util from '../../../../shared/utilities';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {

	hideme: any = {};
	caseData: any = [];
	isLoading: boolean = false;
	noData: boolean = false;
	lsData: any = [];
	caseFUSessions: any = [];
	nextSessionLocation: string = '';
	setType_global: string;
	prCodes: Object = PR_CODES;
	isHoverActive: boolean = false;
	caseDetailsData: any = [];

	constructor(private globalData: GlobalDataService, 
		private caseServ: CasesService,
		private lsService: StorageMap) { }

	ngOnInit() {
		this.globalData.tBlockStep.pipe(distinctUntilChanged()).subscribe(val => {
			if(val != null && location.href.indexOf('case') > -1 && val != 4){
				this.loadCaseData(val.toString());
			}
		});
		// case search input
		$("body").on("keyup",".search-input input", function(){
			let getVal = $(this).val().toString().toLowerCase();
			$('.data-table .row').each(function(i,item){
				if($(this).find(".col").not(".td-arrow-down").text().toLowerCase().indexOf(getVal) <= -1){
					$(this).hide();
				} else {
					$(this).show();
				}
			});
		});
	}

	storeALLCaseDetails(respData,type){
		let getRequestIds = respData;
		let observables = getRequestIds.map(id => this.caseServ.getCaseDetails(id));
		forkJoin(observables).subscribe(data => {
			this.caseDetailsData.push(data);
			this.lsService.set(type,data).subscribe();
		}, err => {
			console.log('we had a concat RXJS error along the way...');
		})
	}

	getFollowUpData(index,requestID){
		this.caseFUSessions.length = 0;
		this.nextSessionLocation = '';
		this.caseData[index].hideme = !this.caseData[index].hideme;
		if(this.caseData[index].hideme){
			this.lsService.watch(this.setType_global).subscribe(cDetails => {
				let formatData: any = cDetails;
				let getData = formatData.filter(c => {
					let res = c.body.results;
					if(res.length > 0){
						return res;			
					}
				});
				let mergedArray = _util.filteredMultiArray(getData);				
				let getReqIDdata = mergedArray.filter(rId => rId.RelatedRequestID == requestID);
				if(getReqIDdata.length > 0){
					this.caseFUSessions = getReqIDdata.reverse();
					// set Next Session location
					let openLocations = getReqIDdata.filter(l => l.Status != "REALIZED")[0];
					if(openLocations != null){
						this.nextSessionLocation = openLocations.TextFreeField1;
					}			
				}
			});
		}
	}

	checkLocation(index){
		let getIdxData = this.caseFUSessions[index];
		if(getIdxData.Status != 'REALIZED'){
			if(typeof getIdxData.TextFreeField1 != "undefined"){
				this.isHoverActive = true;
				this.nextSessionLocation = getIdxData.TextFreeField1;
			}
		}
	}

	deactivateActiveStates(){
		this.isHoverActive = false;		
	}

	loadCaseData(type){
		this.isLoading = true;
		this.noData = false;
		this.caseData.length = 0;
		let setType;
		switch (type) {
			case "1":
				setType = 'eap';
				break;
			case "2":
				setType = 'managerial';
				break;
			case "3":
				setType = 'cisd';
				break;							
			default:
				// code...
				break;
		}
		this.setType_global = setType;
		this.lsService.watch('maincases_'+this.setType_global).subscribe(cases => {
			let data: any = cases;
			if(typeof data != "undefined"){
				if(data.length > 0){
					let filteredData = data.map(i => {
						return {
							fullName: i.TextFreeField10 +" "+ i.TextFreeField11,
							ItemCode: this.prCodes[i.ItemCode],
							generatedId: i.TextFreeField3,
							Status: i.Status,
							StartDate: i.StartDate,
							CreatedDate: i.CreatedDate,
							RequestID: i.RequestID,
							ResourceID: i.ResourceID
						}
					});
					filteredData = _util.sortByCreatedDate(filteredData,'CreatedDate');
					this.caseData = filteredData;
					this.isLoading = false;
					let getRequestIds = filteredData.map(i => i.RequestID);
					this.storeALLCaseDetails(getRequestIds,this.setType_global);
				} else {
					this.noData = true;
				}
			} else {
				this.isLoading = false;
				this.noData = true;				
			}
		});
	}
}
