import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  windowObjectRef: any;
  isBrowser: any;
  
 
  constructor(@Inject(PLATFORM_ID) private platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.windowObjectRef = (<any>window);
    }
  }

  public pushDirectEvent(category, action, label) {
    if (this.isBrowser) {
      setTimeout(function () {
        console.log('Google analytics direct event (Category : ' + category + ' , Action : ' + action + ', Label : ' + label + ')');
        (<any>window).ga('send', 'event', category, action, label);
      }, 10);
    }
  }
  
  public dataLayerPageViewEvent(pageName, eventName) {
    if (this.isBrowser) {
      setTimeout(function () {
        console.log('Google analytics SPA page view (CurrentPage : ' + pageName + ' , Event : ' + eventName + ')');
        (<any>window).dataLayer.push({
          'currentPage': pageName,
          'event': eventName
        });
      }, 10);
    }
  }

  public pushDirectPageViewEvent(pagePath) {
    if (this.isBrowser) {
      setTimeout(function () {
        console.log('Google analytics SPA page view (CurrentPage : ' + pagePath + ')');
        (<any>window).ga('set', 'page', pagePath);
        (<any>window).ga('send', 'pageview');
      }, 10);
    }
  }

  public pushDirectPageViewWithCustomMetricsEvent(pagePath,uniqueId,sessionID) {
    if (this.isBrowser) {
      setTimeout(function () {
        console.log('Google analytics SPA page view (CurrentPage : ' + pagePath + ')');
        (<any>window).ga('set', 'page', pagePath);

        if(uniqueId){
          (<any>window).ga('set', 'userId', uniqueId);
          (<any>window).ga('set', 'dimension1', uniqueId);
        }
        if(sessionID){
          (<any>window).ga('set', 'sessionId', sessionID);
          (<any>window).ga('set', 'dimension2', sessionID);
        }

        (<any>window).ga('send', 'pageview');
      }, 10);
    }
  }

  public pushDataLayerEvent(eventName) {
    if (this.isBrowser) {
      setTimeout(function () {
        console.log('Google analytics SPA Tag Manager Event (Event : ' + eventName + ')');
        (<any>window).dataLayer.push({
          'event': eventName
        });
      }, 10);
    }
  }

  public pushDataLayerVariable(variableName, variableValue) {
    if (this.isBrowser) {
      setTimeout(function () {
        console.log('Google analytics SPA Tag Manager Variable (' + variableName + ' : ' + variableValue + ')');
        (<any>window).dataLayer.push({
          variableName: variableValue
        });
      }, 10);
    }
  }

}
