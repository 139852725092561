<div class="page fw-page" id="legal-page">
    <body
        class="page-template-default page page-id-10133 page-child parent-pageid-9747 custom-background et-tb-has-template et_button_custom_icon et_pb_button_helper_class et_fixed_nav et_hide_nav et_hide_primary_logo et_hide_fixed_logo et_secondary_nav_enabled et_secondary_nav_two_panels et_primary_nav_dropdown_animation_fade et_secondary_nav_dropdown_animation_fade et_header_style_left et_cover_background et_pb_gutter windows et_pb_gutters3 et_pb_pagebuilder_layout et_smooth_scroll et_no_sidebar ">
        <div id="page-container" style="padding-top: 111px; margin-top: -1px;" class="et-animated-content">
            <div id="et-boc" class="et-boc">
                <!-- #top-header -->
                <div id="et-main-area">
                    <div id="main-content">
                        <article id="post-10133" class="post-10133 page type-page status-publish hentry">
                            <div class="entry-content">
                                <div class="et-l et-l--post">
                                    <div class="et_builder_inner_content et_pb_gutters3">
                                        <div
                                            class="et_pb_section et_pb_section_6 et_pb_with_background et_section_regular">
                                            <div class="et_pb_row et_pb_row_2">
                                                <div
                                                    class="et_pb_column et_pb_column_4_4 et_pb_column_4  et_pb_css_mix_blend_mode_passthrough et-last-child">
                                                    <div
                                                        class="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light">
                                                        <div class="et_pb_text_inner">
                                                            <h1>Legal Services</h1>
                                                        </div>
                                                    </div>
                                                    <!-- .et_pb_text -->
                                                </div>
                                                <!-- .et_pb_column -->
                                            </div>
                                            <!-- .et_pb_row -->
                                        </div>
                                        <!-- .et_pb_section -->
                                        <div class="et_pb_section et_pb_section_9 et_section_regular">
                                            <div class="et_pb_with_border et_pb_row et_pb_row_3">
                                                <div
                                                    class="et_pb_column et_pb_column_1_2 et_pb_column_5  et_pb_css_mix_blend_mode_passthrough">
                                                    <div
                                                        class="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left et_pb_bg_layout_light">
                                                        <div class="et_pb_text_inner">
                                                            <h1>AVAILABLE TOOLS</h1>
                                                            <p style="font-size: 0.82vw;">Get access to legal contracts
                                                                and documents online.</p>
                                                            <button
                                                                class="pum-trigger  popmake-10963 mw-red-btn mw-redbtn-left"
                                                                data-do-default="" style="cursor: pointer;"
                                                                (click)="loadModal('legal_tools')">DOWNLOAD
                                                                NOW</button>
                                                        </div>
                                                    </div>
                                                    <!-- .et_pb_text -->
                                                </div>
                                                <!-- .et_pb_column -->
                                            </div>
                                            <!-- .et_pb_row -->
                                            <div class="et_pb_with_border et_pb_row et_pb_row_4">
                                                <div
                                                    class="et_pb_column et_pb_column_3_5  et_pb_css_mix_blend_mode_passthrough">
                                                    <div class="et_pb_module et_pb_text et_pb_text_3">
                                                        <div class="et_pb_text_inner">
                                                            <h1>Whenever you need legal advice or professional guidance
                                                                in
                                                                your daily life, you can pick up a phone and get
                                                                immediate
                                                                assistance from a fully qualified and experienced
                                                                attorney.
                                                            </h1>
                                                            <p>In essence, this ad hoc legal support service allows
                                                                everyone
                                                                to have their own personal lawyer on call and available
                                                                at
                                                                any time during business hours. This is to provide ad
                                                                hoc
                                                                advice on legal issues such as divorce, maintenance,
                                                                custody, criminal matters, claims for payment, breach of
                                                                contract, property disputes and a wide range of further
                                                                legal issues.</p>
                                                            <p>&nbsp;</p>
                                                            <h1><span
                                                                    style="font-family: Lato; font-weight: normal; font-size: large;"><strong>Legal
                                                                        Contracts</strong></span></h1>
                                                            <div class="grid-50">
                                                                <p><b>Legal contracts offer individuals essential
                                                                        protection
                                                                        in everyday life. Our service provides legal
                                                                        contracts that are:</b></p>
                                                                <ul>
                                                                    <li class="article">uniquely tailored to your
                                                                        circumstances;</li>
                                                                    <li class="article">backed by a team of experienced
                                                                        attorneys and advocates;</li>
                                                                    <li class="article">fully completed and ready for
                                                                        signature on delivery;</li>
                                                                    <li class="article">delivered with advice on how to
                                                                        sign
                                                                        and use the document concerned;</li>
                                                                    <li class="article">delivered within 24 hours of a
                                                                        contract request being made.</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- .et_pb_text -->
                                                </div>
                                                <!-- .et_pb_column -->
                                            </div>
                                            <!-- .et_pb_row -->
                                        </div>
                                        <!-- .et_pb_section -->
                                        <div class="et_pb_section et_pb_section_10 et_section_regular">
                                            <div class="et_pb_with_border et_pb_row et_pb_row_5">
                                                <div
                                                    class="et_pb_column et_pb_column_4_4 et_pb_column_9  et_pb_ng serve_mix_blend_mode_passthrough et-last-child">
                                                    <div
                                                        class="et_pb_module et_pb_text et_pb_text_4  et_pb_text_align_left et_pb_bg_layout_light">
                                                        <div class="et_pb_text_inner">
                                                            <h1><strong>Written Legal Advice Packages</strong></h1>
                                                            <p>The following advice packages are included in the legal
                                                                support service:</p>
                                                            <p><strong>Debt Relief – I can’t pay my debts. What can I
                                                                    do?
                                                                    –&nbsp;<a
                                                                        href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1007&amp;Y=ec237b4df290f97ca42fe489ec53602c" target="_blank">Access
                                                                        Now</a></strong></p>
                                                            <p>This advice package collects the information necessary to
                                                                give you appropriate and meaningful advice, aimed at
                                                                relieving your debt-related stress. It includes a
                                                                step-by-step guide to the available processes, the
                                                                consequences of applying for debt relief, and details of
                                                                your nearest debt counsellor. If appropriate, an
                                                                application
                                                                for debt relief is completed on your behalf, ready for
                                                                submission to a debt counsellor.</p>
                                                            <p><strong>Small Claims Court: How to claim money –&nbsp;<a
                                                                        href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1023&amp;Y=f61d862cf148655a1cd089d4580b33f0" target="_blank">Access
                                                                        Now</a></strong></p>
                                                            <p>The Small Claims Court kit allows you to deal quickly and
                                                                cheaply with civil claims of less than R12,000. Based on
                                                                the
                                                                information collected from you, a detailed letter of
                                                                demand,
                                                                summons and particulars of claim are drafted, which can
                                                                then
                                                                be used immediately to institute the claim. The
                                                                litigation
                                                                documents are customised to the unique circumstances of
                                                                the
                                                                case. You are also told where to find your nearest Small
                                                                Claims Court and a step-by-step guide to the process is
                                                                included so that you have no difficulty in enforcing
                                                                your
                                                                claims.</p>
                                                            <p><strong>Getting an uncontested divorce –&nbsp;<a
                                                                        href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1003&amp;Y=da19726318b7d53112e90df3164f340f" target="_blank">Access
                                                                        Now</a></strong></p>
                                                            <p>This advice package provides detailed information on the
                                                                procedure to be followed in obtaining an uncontested
                                                                divorce
                                                                order. You receive all of the information necessary to
                                                                obtain a divorce order and are also provided with a
                                                                divorce
                                                                summons – completed to your exact requirements and
                                                                taking
                                                                into account your specific circumstances.</p>
                                                            <p><strong>How to claim maintenance –&nbsp;<a
                                                                        href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1004&amp;Y=3ad4c7eb2ec22a77408a94f3a7145836" target="_blank">Access
                                                                        Now</a></strong></p>
                                                            <p>This service provides you with comprehensive advice on
                                                                how to
                                                                go about claiming maintenance for yourself or for your
                                                                children. The maintenance package completes an
                                                                application
                                                                for maintenance on your behalf, taking into account your
                                                                unique circumstances and requirements. Maintenance Court
                                                                locations and procedures are also explained to you.</p>
                                                            <p><strong>Stopping domestic violence –&nbsp;<a
                                                                        href="https://phelawellness.co.za/members-legal.php#"
                                                                        data-et-has-event-already="true" target="_blank">Access
                                                                        Now</a></strong></p>
                                                            <p>This advice package provides you with all of the
                                                                information
                                                                and documents you require to apply for a domestic
                                                                violence
                                                                protection order. All necessary documents are completed
                                                                with
                                                                reference to your unique circumstances and delivered
                                                                ready
                                                                for signature. The documents are accompanied by a
                                                                step-by-step guide to the process to be followed in
                                                                obtaining a protection order.</p>
                                                            <p><strong>Marriage options: Do I need an ANC? –&nbsp;<a
                                                                        href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1019&amp;Y=eac08b570efc0c38a3dded845ed6ba08" target="_blank">Access
                                                                        Now</a></strong></p>
                                                            <p>This support package provides key information about
                                                                different
                                                                matrimonial property regimes. It explains concepts such
                                                                as
                                                                “married in community of property”; “married out of
                                                                community of property”; an “antenuptial contract”; and
                                                                “the
                                                                accrual system”. Further important aspects covered are
                                                                the
                                                                effects of an antenuptial contract (ANC) and why it is
                                                                generally essential to sign an ANC before getting
                                                                married.
                                                            </p>
                                                            <p><strong>Report a deceased estate –&nbsp;<a
                                                                        href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1020&amp;Y=a902946226be0421c0a4f24073b86aea" target="_blank">Access
                                                                        Now</a></strong></p>
                                                            <p>The process of reporting the death and registering the
                                                                deceased’s estate requires the completion of a range of
                                                                documents and careful adherence to the applicable
                                                                procedures. This advice package provides you with a
                                                                detailed
                                                                description of the process to be followed in reporting a
                                                                death and in ensuring the seamless registration of a
                                                                deceased estate.</p>
                                                            <p><strong>Registering your child’s birth –&nbsp;<a
                                                                        href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1013&amp;Y=c5000a8a48470488d016d07bf96aa03e" target="_blank">Access
                                                                        Now</a></strong></p>
                                                            <p>This online interview and advice session gives you a
                                                                detailed
                                                                account of the procedures you need to follow to register
                                                                your child’s birth. You can also receive information on
                                                                what
                                                                to do if you have missed the 30-day deadline or if you
                                                                would
                                                                like to register the child with a surname different to
                                                                that
                                                                of the child’s father or mother.</p>
                                                        </div>
                                                    </div>
                                                    <!-- .et_pb_text -->
                                                    <div
                                                        class="et_pb_with_border et_pb_module et_pb_text et_pb_text_5  et_pb_text_align_left et_pb_bg_layout_light">
                                                        <div class="et_pb_text_inner">
                                                            <h1><strong>Emergency Legal and Bail Assistance</strong>
                                                            </h1>
                                                            <p>In the event that you are arrested, or require emergency
                                                                legal advice, you will have 24-hour telephonic access to
                                                                our
                                                                specialist legal call centre. In the event of an arrest,
                                                                a
                                                                litigation attorney will be dispatched immediately to
                                                                the
                                                                police station or court concerned, so that bail
                                                                arrangements
                                                                can be made without delay. The specialist attorney will
                                                                charge you a pre-negotiated, fixed and discounted rate
                                                                of
                                                                R2,000 for all of the necessary consultations,
                                                                prosecutor
                                                                and/or police negotiations and a bail hearing.</p>
                                                        </div>
                                                    </div>
                                                    <!-- .et_pb_text -->
                                                    <div
                                                        class="et_pb_with_border et_pb_module et_pb_text et_pb_text_6  et_pb_text_align_left et_pb_bg_layout_light">
                                                        <div class="et_pb_text_inner">
                                                            <h1><strong>Identity Theft &amp; Fraud Prevention
                                                                    Services</strong></h1>
                                                            <p>If you are the victim of fraud and/or identity theft (in
                                                                any
                                                                form), you will receive immediate call centre support
                                                                from
                                                                our attorneys and free assistance via a referral
                                                                network,
                                                                that facilitates bank account and card freezes and
                                                                database
                                                                services offered by the SA Fraud Prevention Services.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <!-- .et_pb_text -->
                                                </div>
                                                <!-- .et_pb_column -->
                                            </div>
                                            <!-- .et_pb_row -->
                                        </div>
                                        <!-- .et_pb_section -->
                                        <!-- .et_pb_section -->
                                    </div>
                                    <!-- .et_builder_inner_content -->
                                </div>
                                <!-- .et-l -->
                            </div>
                            <!-- .entry-content -->
                        </article>
                        <!-- .et_pb_post -->
                    </div>
                    <!-- #main-content -->
                </div>
                <!-- #et-main-area -->
            </div>
            <!-- #et-boc -->
        </div>
        <style id="et-builder-module-design-tb-12463-10133-cached-inline-styles">
            .et_pb_row_3 {
                background-position: top center;
                background-image: url(https://www.momentumwellness.co.za/wp-content/uploads/2019/08/mw-legal-action-bg.jpg);
                border-width: 1px;
                border-color: rgba(51, 51, 51, 0.39)
            }
        </style>
    </body>
</div>
