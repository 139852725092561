import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalDataService } from '../../shared/global-data';
import { ReportsService } from '../../shared/services/reports.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { DomSanitizer} from '@angular/platform-browser';
import * as pbi from 'powerbi-client';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

	report: pbi.Embed;
	@ViewChild('reportContainer') 
	reportContainer: ElementRef;
	clientId : string = '0b5f8f94-bb22-4a24-b156-20fa1fb0047e';
	tenantId : string = '366b8d18-99ea-436a-824b-1ca89c369476';
	embedToken : string;

	pData: any = [];
	tabNames: any = [];
	reportIsLoading: boolean = false;
	reportTechError: boolean = false;
	reportUrl: string = "";
	incomingType: string = "";
	powerbi: any;
	reportIsAvailable: boolean = false;

	constructor(private globalService: GlobalDataService,
		private lsService: StorageMap,
		private route: ActivatedRoute,
		private sanitizer: DomSanitizer,
		private reportService: ReportsService) { }

	ngOnInit(): void {
	
		this.globalService.changeCurrentViewState('report');
		this.lsService.get('profileData').subscribe((lsData) => {
			if(typeof lsData !== 'undefined'){
				this.pData.push(lsData);
				setTimeout(() => {
					// Load PowerBI report by Tab selection
					let that = this;
					$(".reports-tabs .tab").on("click", function(e){
						let getIndex: any = $(this).attr('data-index');
						let getKey: any = $(this).attr('data-name');
						let getDataClass: any =  $(this).parent().parent().attr('data-class');
						$(".tab-header .active").removeClass('active');
						$(this).addClass('active');
						$(".tcontent").removeClass('active');
						$(".tcontent").eq(getIndex).addClass('active');
						that.loadBIReport(getKey,getDataClass);
					});
				}, 400);
			}
		});
		this.route.params.subscribe(data => {
			if(typeof data.dataroute != 'undefined'){
				this.tabNames = [];
				setTimeout(() => {
					switch (data.dataroute) {
						case "occ_health":

							// update breadcrumb
							$('.dynamic-breadcrumb-text').text('Occupational Health Reports');

							let getOcclabels = JSON.parse(localStorage.getItem('occHealthLabels'));
							let newLabels_occ = getOcclabels.map((item,index) => {
								let newName;								
								if(item.indexOf("EXTERNAL-DEPLOYMENT") > -1){ newName = "Pre Employment/Training"}
								else if(item.indexOf("EXTERNAL") > -1){ newName = "External Deployment"}
								else { newName = item }
								return {
									labelName: newName,
									apiName: item
								}
							});
							let getViewTabIndex = getOcclabels.findIndex(this.getViewTab);
							newLabels_occ.push(newLabels_occ.splice(getViewTabIndex, 1)[0]);
							this.tabNames = newLabels_occ;
							this.incomingType = 'occHealthData';
							break;
						case "illhealth":

							// update breadcrumb
							$('.dynamic-breadcrumb-text').text('Ill Health/ In-Capacity/ Disability Reports');

							let getIllHealthlabels = JSON.parse(localStorage.getItem('illHealthLabels'));
							let changeLabelText = getIllHealthlabels.map((item,index) => {
								let newName;
								if(item.indexOf("Arrival") > -1){ newName = "Arrival Status"}
								else if(item.indexOf("Case") > -1){ newName = "Case Outcomes"}
								else if(item.indexOf("Workflow") > -1){ newName = "View of Workflow Statuses"}
								else { newName = item }
								return {
									labelName: newName,
									apiName: item
								}
							});
							
							this.tabNames = changeLabelText;
							this.incomingType = 'illHealthData';
							break;						
						default:
							break;

							case "wellevent":
								// update breadcrumb
							$('.dynamic-breadcrumb-text').text('Wellness Event Report');

							let getWellEventlabels = JSON.parse(localStorage.getItem('wellnessEventLabels'));
							let changeLabelTexted = getWellEventlabels.map((item,index) => {
								let newName;
								if(item.indexOf("Arrival") > -1){ newName = "Arrival Status"}
								else if(item.indexOf("Case") > -1){ newName = "Case Outcomes"}
								else if(item.indexOf("Workflow") > -1){ newName = "View of Workflow Statuses"}
								else { newName = item }
								return {
									labelName: newName,
									apiName: item
								}
							});
							
							this.tabNames = changeLabelTexted;
							this.incomingType = 'wellnessEventData';
							break;					
						
					}
					setTimeout(() => { $(".tab").eq(0).trigger("click") }, 600);
				}, 400);
			} else {
			}
		});
		$("html, body").animate({ scrollTop: 0 }, "slow");
		this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);

		
	}

	getViewTab(val){ return val == "View of Workflow Statuses"}

	loadBIReport(key,dataClassName){
		let getLSData = JSON.parse(localStorage.getItem(dataClassName));
		//let getReportLink = getLSData[key][0].reportLink;
		let groupId = getLSData[key][0].groupId;
		let reportId = getLSData[key][0].reportId;
		this.viewReport(groupId,reportId);
	}

	viewReport(groupId,reportId) {
		this.reportIsLoading = true;
		this.reportTechError = false;
		this.reportIsAvailable = false;
		let reportContainer = this.reportContainer.nativeElement;
		let settings: pbi.IEmbedSettings = {
			filterPaneEnabled: false,
			navContentPaneEnabled: true
		};
		this.reportService.getAzureAccessToken(this.clientId,this.tenantId).subscribe(result => {
			let azureAccessToken = result.accessToken;
			let embedTokenRequest = {
				accessToken : azureAccessToken,
				groupId: groupId,
				reportId : reportId
			}
			this.reportService.generatePowerBIToken(embedTokenRequest).subscribe(powerBiResult => {
				let config: pbi.IEmbedConfiguration = {
					type: 'report',
					tokenType: pbi.models.TokenType.Embed,
					accessToken : powerBiResult.token,
					embedUrl: 'https://app.powerbi.com/reportEmbed',
					pageView: "fitToWidth",
					id: reportId,
					settings: settings
				};
				this.powerbi.reset(reportContainer);
				let report = this.powerbi.embed(reportContainer, config);
				report.off("loaded");
				this.reportIsLoading = false;
				setTimeout(() => { this.reportIsAvailable = true }, 10000);
			}, err => {
				this.reportIsLoading = false;
				this.reportTechError = true;
			});
		}, err => {
			this.reportIsLoading = false;
			this.reportTechError = true;
		});
	}

	viewFullScreen(){
		let reportContainer = this.reportContainer.nativeElement;
		let embedContainer = reportContainer;
		let report = this.powerbi.get(embedContainer);
		report.fullscreen();
	}
}
