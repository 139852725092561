<div class="row">
    <div class="col s12 m6 l3">
      <div class="card hoverable" *ngFor="let item of hdData">
        <div [id]="item.id">
          <div class="card-image"><img [src]="item.image" [alt]="item.title" [title]="item.title"></div>
          <div class="card-content"><span class="card-title" style="font-size:13px;">
              <h4 innerHTML="{{ item.title }}"></h4>
            </span>
            <span class="card-description">
              <p innerHTML="{{ item.descrip }}"></p>
            </span>
            <div class="read-more-btn">
              <button class="btn" (click)="openHDPost(item.link)">Read more</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>