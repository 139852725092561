import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse,HttpEventType } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GlobalDataService } from '../../shared/global-data';
import * as Enums from '../../shared/enums';

// fileData: File = null;
@Injectable({ providedIn: 'root' })
export class SynergyService {

	constructor(public http: HttpClient){}
	
	findUser (email): Observable<any> {
		let params = `?email=${email}`;	
		return this.http.get(Enums.API_BASE_PATHS + Enums.API_PATHS.SYN_FINDUSER + params).pipe(
			tap( success => console.log('success'),
   				error => console.log('get Syn user data did not work...API error!'))
		);
	}
	updateEmailOnSynergy (emailObj): Observable<any> {
		return this.http.post(Enums.API_BASE_PATHS + Enums.API_PATHS.SYN_UPDATEUSER, JSON.stringify(emailObj)).pipe(
			tap( success => console.log('success'),
   				error => console.log('update email addy on synergy did not work...API error!'))
		);
	}	
	doesUserExist (clientNumber,surname): Observable<any> {
		let params = `?account=${clientNumber}&surname=${surname}`;
		return this.http.get(Enums.API_BASE_PATHS + Enums.API_PATHS.USER_EXISTS + params).pipe(
			tap( success => console.log('success'),
   				error => console.log('get Syn user data did not work...API error!'))
		);
	}
	createCounsCallback (counsObj): Observable<any> {
		return this.http.post(Enums.API_BASE_PATHS + Enums.API_PATHS.CASE_CREATE + "/counsellorcb", JSON.stringify(counsObj)).pipe(
			tap( success => console.log('success'),
   				error => console.log('Create counsellor callback did not work...API error!'))
		);
	}		
	private handleError<T> (operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			//this.logException(Enums.LEVELS.ERROR, operation + ':' + error.message);
			return of(result as T);
		};
	}
	private handleAuthError(err: any): Observable<HttpErrorResponse> {
		throw err;
	}	
	private log(message: string) {
		//console.log(`${message}`);
	}	
}