<div class="login-banner">
	<!--<img src="../assets/img/health_profilebanner.png" alt="">-->
</div>
<div class="health-profile-hero">
	<h1>change your password?</h1>
	<p>Complete the form below. For security reasons you will receive an email link for verification.</p>
</div>
<div class="reg-form">
	<!--<h3>FORGOT PASSWORD</h3>-->
	
	<div class="form" *ngIf="!formSuccess">
		<form class="resetPassForm" [formGroup]="resetPassForm">
			<div class="input-row clearfix">
				<input type="text" formControlName="username" name="username" placeholder="Username">
				<input 
					type="password" 
					formControlName="oldpass" 
					name="oldpass" 
					placeholder="Old Password" 
					[ngClass]="{ 'success-password-match' : showPasswordHints == false }" 
					*ngIf="!forgotpassActive">
				<input 
					type="password" 
					formControlName="newpass" 
					name="oldpass" 
					placeholder="New Password" 
					[ngClass]="{ 'success-password-match' : showPasswordHints == false }" 
					*ngIf="!forgotpassActive">
			</div>
		</form>
		<p class="red-error-text">{{ passMatchTxt }}</p>
		<p class="passhints" *ngIf="showPasswordHints == true" (click)="isPassHintsOpen = !isPassHintsOpen">
			<span *ngIf="isPassHintsOpen == false">></span> 
			<span *ngIf="isPassHintsOpen == true">v</span> 
			Password hints
		</p>
		<p *ngIf="isPassHintsOpen == true" class="password-warning">
			<strong>Please ensure your password contains:</strong><br>
			- at least 10 characters<br>
			- of which one is a capital letter<br>
			- and one is a special character e.g: !@#$%&*_
		</p>		
		<p class="red-error-text" *ngIf="isUserFound == false">
			We cannot find your username in our system. Please contact us at 080 0111 223.</p>
		<p class="red-error-text" *ngIf="oldPassIncorrect == true">Your old password is incorrect. Please try again.</p>
		<p class="green-success-text" *ngIf="FP_linkSent">
			Success! We have sent a link to your email inbox. 
			Please click on the link to reset your password.
		</p>
		<p class="red-error-text" *ngIf="FP_linkSent == false">
			We could not send you a link to reset your password. Please contact 080 0111 223.
		</p>		

		<div class="loader" *ngIf="isLoading"></div>
		<div>
			<button class="submit-btn btn" 
				(click)="resetPassword()" 
				[disabled]="resetPassForm.status == 'INVALID'" 
				[hidden]="isLoading">Submit</button>
		</div>
		<!--<div class="login-options" [hidden]="isLoading">
			<a (click)="activateForgotPass()">I forgot my password</a>
		</div>-->
		<div class="login-options">
			<a routerLink="/login">&lsaquo; Back to login</a>
		</div>				
	</div>
	<div class="step" *ngIf="formSuccess">
		<div class="success-block">
			<i class="icon i-check_circle"></i>
			<h4>Success</h4>
			<p>Your password has been reset! Please <a routerLink="/login">click here</a> to login</p>
		</div>
	</div>
</div>