<!-- <p>survey works!</p> -->
<h3 class="block-title">Home<span class="arrow">&nbsp;>&nbsp;</span> <span class="current-header">e-learning</span><span  class="current-header">  > {{topicmainheadernav}} > Wellness Window Check</span>
</h3>


<div class="component-content q-container">
   <div *ngIf="!surveyCompleted">

   <div>
      <span>
         <h3 class="headerTopic">Wellness Window Check</h3><br>
         <p class="descriptionTopic">Even though we’re on the outside, we’d still like to have a glance inside of you to understand more about how you felt about this wellness programme and how best we can support you in the next one. Using the emoticons below, please answer the following questions. Remember that all answers are strictly depersonalised and confidential, so speak freely!</p>
      </span>

   </div>
   <div class="main-content">
      <form [formGroup]="survey_form" (ngSubmit)="onSubmitAnswers()">
         <div class="row" *ngIf="showQuestionArray" formArrayName="questionArray"><br>
            <div class="column" *ngFor="let item of surveyList; let i = index" [formGroupName]="i">
               
               <div class="quiz">
                  <span>
                     <h3 class="align">Question {{i+1}}</h3><br>
                     <h6 style="color: black; font-size: 15px; font-weight: 100; padding-left: 60px;">{{item.surveyQuestion}} </h6>
                  </span>



                  <div class="rating">
				  
				  <table align="center">
				  <tr>
				  <td style="width:25%" >
                     <div class="emoji" >
                     <input type="radio" value="4" id="{{i}}" formControlName="answer" >
                     <label (click)="setValue(i,4)" for="star1" >
                        <img src="../../../assets/img/icons/elearning/e-learning-icons_excellent.svg">
                        <h4>Excellent</h4>
                     </label>
                     </div>
					 </td>
					 <td style="width:25%">
                        <div class="emoji">
                           <input type="radio" value="3" id="{{i}}" formControlName="answer">
                           <label (click)="setValue(i,3)" for="star2">
                              <img src="../../../assets/img/icons/elearning/e-learning-icons_good.svg">
                              <h4>Good</h4>
                           </label>
                        </div>
						</td>
						<td style="width:25%">
                        <div class="emoji">
                           <input type="radio" value="2" id="{{i}}" formControlName="answer">
                           <label (click)="setValue(i,2)" for="star3">
                              <img src="../../../assets/img/icons/elearning/e-learning-icons_fair.svg">
                              <h4>Fair</h4>
                           </label>
                        </div>
						</td>
						<td style="width:25%">
                        <div class="emoji">
                           <input type="radio" value="1" id="{{i}}" formControlName="answer">

                           <label (click)="setValue(i,1)" for="star4">
                              <img src="../../../assets/img/icons/elearning/e-learning-icons_poor.svg">
                              <h4>Poor</h4>
                           </label>
                        </div>
						</td>
						</tr>
						</table>
                     
                  </div>


               </div>
               
            </div>
         </div>
      </form>




   </div>

   <div class="submit">
      <button class="backmBtn" type=""  (click)="backmBtn()">back</button>
      <button class="submBtn" type="submit" (click)="saveSurvey()" [disabled]="survey_form.status == 'INVALID'">Submit</button>


   </div>

</div>

   <div class="surveyCompleted" *ngIf="surveyCompleted">
      <h4>Wellness Window Check completed</h4>
      <p>Thank you for taking the time to invest in yourself. Life is a journey;
         head back to the [HOME PAGE], choose another topic and continue moving forward.</p>
          <div class="scorebox">
              <button class="nxtTopic" (click)="backmBtn()"> Next Topic </button>
          </div>
  </div>
</div>