<div class="tab-container">
	<div class="tab-header">
		<div class="tab active" data-index="0">New {{ mainType }} case</div>
		<div class="tab" data-index="1" data-case="eap">All {{ mainType }} Cases</div>
		<div class="tab" data-index="2">{{ mainType }} Calendar</div>
	</div>
	<div class="tab-content">
		<div class="tcontent tab-1 active">
			<!-- form comp comes here -->
			<app-form></app-form>			
		</div>
		<div class="tcontent tab-2">
			<app-table></app-table>
		</div>
		<div class="tcontent tab-3">
			<!-- inject calendar widget here -->
			<app-calendar></app-calendar>
		</div>			
	</div>
</div>