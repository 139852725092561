import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalDataService } from './../../shared/global-data';
import { distinctUntilChanged } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-legal-services',
  templateUrl: './legal-services.component.html',
  styleUrls: ['./legal-services.component.css']
})
export class LegalServicesComponent implements OnInit {

  constructor(private router: Router, private globalData: GlobalDataService, 
		private lsService: StorageMap) {
	}

  ngOnInit(): void {
    this.globalData.changeCurrentViewState('legal');
  }
  loadModal(type){
    this.globalData.changeCurrentModalState(type);
	}

}
