<div class="home-top reports-dashboard-page clearfix">
	
	<div class="reports-header">
		<h1>South African Police Service Dashboard</h1>
	</div>

	<div class="cards clearfix"> 

		<div class="card" (click)="goToPage('report','occ_health')" *ngIf="isActive_occh">
			<img src="assets/img/icons/reports/occ_health_v2.svg" class="icon icon_occhealth">
			<h4>Occupational Health Reports</h4>
			<p>Access all your SHE, pre-employment/training and external deployment reports and view workflow statuses.</p>
			<button class="btn">View more</button>
		</div>		
		
		<div class="card" (click)="goToPage('report','illhealth')" *ngIf="isActive_illhealth">
			<img src="assets/img/icons/reports/illhealth.svg" class="icon">
			<h4>Ill Health/ In-Capacity/ Disability Reports</h4>
			<p>View arrival statuses, progress of assessments, case outcomes and view workflow statuses.</p>
			<button class="btn">View more</button>
		</div>

		<div class="card" (click)="goToPage('cofs','cofs')" *ngIf="isActive_cofs">
			<img src="assets/img/icons/reports/certificate.svg" class="iconwell">
			<h4>Certificates of Fitness</h4>
			<p>Search, view and download all certificates of fitness.</p>
			<button class="btn">View more</button>
		</div>	
		
		<div class="card" (click)="goToPage('report','wellevent')" *ngIf="isActive_wellevent">
			<img src="assets/img/icons/reports/wellness-event-reporting.svg" class="iconwell">
			<h4>Wellness Event Reports </h4>
			<p>View wellness day information per province.</p>
			<button class="btn">View more</button>
		</div>	

	</div>

</div>
