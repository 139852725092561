import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GlobalDataService {

	getPathName: any = location.pathname;
	sanitizedUrl = this.getPathName.replace('/','');

	// top block step number
	private tBlockStepSource = new BehaviorSubject(null);		
	tBlockStep = this.tBlockStepSource.asObservable();

	// current view
	private currentViewSource = new BehaviorSubject(this.sanitizedUrl);		
	currView = this.currentViewSource.asObservable();

	// popup modal type
	private modalSource = new BehaviorSubject(null);		
	currModalType = this.modalSource.asObservable();

	// check SUPER GROUP / 3-month period state
	private tokenSource = new BehaviorSubject(null);
	tokenval = this.tokenSource.asObservable();

	// check Logged In state
	private loggedInSource = new BehaviorSubject(false);
	isLoggedIn = this.loggedInSource.asObservable();	

	// share profile data
	private profileDataSource = new BehaviorSubject(null);
	profileData = this.profileDataSource.asObservable();

	// share synergy data
	private synergyDataSource = new BehaviorSubject(null);
	synergyData = this.synergyDataSource.asObservable();	

	// share main case data
	private mainCaseDataSource = new BehaviorSubject(null);
	mainCaseData = this.mainCaseDataSource.asObservable();

	// get lifestyle score
	private lifeStyleScore = new BehaviorSubject(null);
	score = this.lifeStyleScore.asObservable();

	constructor(){}

	// TOP BLOCK STEP NUMBER STATE
	TblockStepState(state: number){
		this.tBlockStepSource.next(state);
	}
	changeTBlockState(state: number){
		this.TblockStepState(state);
	}

	// CURRENT VIEW STATE
	currViewState(state: string){
		this.currentViewSource.next(state);
	}
	changeCurrentViewState(state: string){
		this.currViewState(state);
	}	

	// CURRENT MODAL STATE
	currModalState(state: string){
		this.modalSource.next(state);
	}
	changeCurrentModalState(state: string){
		this.currModalState(state);
	}

	// CHANGE TOKEN STATE
	tokenValue(token: string){
		this.tokenSource.next(token);
	}
	changeTokenVal(token: string){
		this.tokenValue(token);
	}

	// CHANGE LOGGED IN STATE
	loggedInValue(state: boolean){
		this.loggedInSource.next(state);
	}
	changeLoggedInState(state: boolean){
		this.loggedInValue(state);
	}

	// CHANGE PROFILE DATA STATE
	ProfileDataValue(data: any){
		this.profileDataSource.next(data);
	}
	changeProfileDataState(data: any){
		this.ProfileDataValue(data);
	}	

	// CHANGE SYNERGY DATA STATE
	SynergyDataValue(data: any){
		this.synergyDataSource.next(data);
	}
	changeSynergyDataState(data: any){
		this.SynergyDataValue(data);
	}

	// CHANGE MAIN CASE DATA STATE
	MainCaseDataValue(data: any){
		this.mainCaseDataSource.next(data);
	}
	changeMainCaseDataState(data: any){
		this.MainCaseDataValue(data);
	}
	
	// LIFESTYLE SCORE
	currentScore(state: number){
		this.lifeStyleScore.next(state);
	}
	getScore(state: number){
		this.currentScore(state);
	}
}