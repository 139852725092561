import { environment } from '../../environments/environment';

export let API_BASE_PATHS = environment.apiUrl;
export let API_HLC_PATHS = environment.hlcURL;
export let HLC_CLIENT_ID = environment.clientId;
export let HLC_CLIENT_SECRET = environment.clientSecret;
export let NED_BANK_ID = environment.ned_bank_id;
export let PERIGON_SURVEY_URL = environment.perigon_survey_url;
export enum API_PATHS {
	AUTHENTICATE = '/v1/account/eap/authenticate',
	LOGIN = '/v1/security/eap/validate',
	SECURITY_USER = '/v1/security/eap/user',
	SYN_FINDUSER = '/v1/security/eap/finduser',
	SYN_UPDATEUSER = '/v1/security/eap/updateuser',
	USER_EXISTS = '/v1/security/eap/userexist',
	GETCASES = '/eap/v1/synergy/mycases',
	CASE_CREATE = '/eap/v1/synergy/create',
	CASE_DETAILS = '/eap/v1/synergy/casedetail',
	HELLO_DOCTOR = '/eap/v1/hellodoctor',
	DECRYPT_TOKEN = '/v1/sls/eap/decrypttoken',
	QUESTIONNAIRE = '/eap/v1/questionnaire',
    QUESTIONSCORE = '/eap/v1/questionnaire/getQuestionnaireResults',
    GET_REPORTS_HIERACHY = '/eap/v1/sapsreportconfig/hierarchy',
	AZURE_TOKEN = '/eap/v1/azure',
	POWER_BI_TOKEN = '/eap/v1/powerBI/generateToken',
	GET_COF_REPORTS = '/eap/v1/synergy/documents/id',
	GET_TOPICS_ELEARNING ="/eap/v1/elearning/topics",
	GET_VIDEO_URL_ELEARNING ="/eap/v1/elearning/videos",
	GET_PDF_URL_ELEARNING ="/eap/v1/elearning/documents",
	POST_QUESTION_ELEARNING ="/eap/v1/elearning/user/quiz",
	CHECK_TOPIC_PROGRESS = "/eap/v1/elearning/user/progress",
	SAVE_SURVEY = "/eap/v1/elearning/user/survey",
	POST_WATCHED_VIDEO = '/eap/v1/elearning/user/videos/watched',
	GET_WATCHED_VIDEOS = '/eap/v1/elearning/user/videos/watched',
	// POST_HLC_TOKEN = "/api/token.php",
	// POST_HLC_REDIRECT="/api/auth.php"
	POST_HLC_TOKEN = "/access_token",
	POST_HLC_REDIRECT="/auth"
}

export enum ENVIRONMENT_VARS {
	GOOGLE_ANALYTICS_CODE = 'UA-xxxxxxxx-x',
}

export enum PR_CODES {
	EAPP057 = 'Mental Health',
	EAPP061 = 'Trauma',
	EAPP056 = 'Legal',
	EAPP052 = 'Financial',
	EAPP058 = 'Relationship',
	EAPP051 = 'Family',
	EAPP062 = 'Work related',
	EAPP053 = 'Health & lifestyle'
}