import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse,HttpEventType } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GlobalDataService } from '../../shared/global-data';
import * as Enums from '../../shared/enums';
import { Router } from '@angular/router';

// fileData: File = null;
@Injectable({ providedIn: 'root' })
export class QuestionnaireService {
	httpOptions: any = {};
	mainToken: any;

    constructor(public http: HttpClient,private router: Router){}
    
    submitQuestionnaire (SubmitQuestionnaireReq): Observable<any> {
		return this.http.post(Enums.API_BASE_PATHS + Enums.API_PATHS.QUESTIONNAIRE + "/submitQuestionnaire", JSON.stringify(SubmitQuestionnaireReq)).pipe(
			tap(data => console.log("done")),
            catchError(this.handleError('QUESTIONNAIRE: submit questionnaire', 'undefined'))
		);
    }
    
    getQuestionnaire (questionnaireId): Observable<any> {
		return this.http.get(Enums.API_BASE_PATHS + Enums.API_PATHS.QUESTIONNAIRE + "/getQuestionnaire/"+questionnaireId).pipe(
			tap(data => console.log("done")),
                catchError(this.handleError('QUESTIONNAIRE: Get questionnaire', 'undefined'))
		);
	}	

    getScoreQuestionnaire(idnumber): Observable<any> {
	
		let bear = localStorage.getItem("grepsislwr29");
		
		if(bear == null){
			this.router.navigate(['/login']);   
		}

		this.httpOptions = {
			headers: new HttpHeaders({
				'Content-Type':  'application/json',
				'Authorization': 'Bearer '+bear
			})
		}	
		
		return this.http.get(Enums.API_BASE_PATHS + Enums.API_PATHS.QUESTIONSCORE +"/"+idnumber+"/1161", this.httpOptions).pipe(
			tap(data => console.log("done")),
                catchError(this.handleError('QUESTIONNAIRESCORE: Get SCore questionnaire', 'undefined'))
		);
	}	
	
	private handleError<T> (operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			//this.logException(Enums.LEVELS.ERROR, operation + ':' + error.message);
			return of(result as T);
		};
	}
	private handleAuthError(err: any): Observable<HttpErrorResponse> {
		throw err;
	}	
	private log(message: string) {
		//console.log(`${message}`);
	}	
}