<div class="component-content q-container">
  <div class="title">
    <span>
      <h3 style="font-weight: 100;">Lifestyle, health and stress check</h3><br>
      <h6 style="color: gray; font-size: 15px; font-weight: 100;">Answer the questions below: </h6>
    </span>
  </div>
  <div class="main-content">
    <form [formGroup]="questionnaire_form">
      <div *ngIf="showQuestionArray" formArrayName="questionArray">
        <div class="row" *ngFor="let questionnaire of questionnaireList ; let q = index" [formGroupName]="q"><br>
          <div class="column" *ngIf="displayQuestion(questionnaire)">

            <div class="quiz" [ngClass]="{ validation_red: !questionnaire.isAnswered && isSubmitted}">
            
              <div>
                <span class="question">

                  <p>{{questionnaire.questionId}}.{{questionnaire.description}}</p>

                </span>
                <div class="answr" *ngFor="let queAnswers of questionnaire.answers; let i=index">
                  <div *ngIf="questionnaire.isRadio == 'Y'">
                  <input type="radio" id="{{q}}" class="radio{{q}}" [value]="queAnswers.answerId" (change)="onRadioChange($event, q, i)" formControlName="answerId">
                  {{queAnswers.description}} 
                </div>
                <div class="answer{{q}}" *ngIf="questionnaire.isRadio == 'N'">
                  <input type="checkbox" id="{{q}}" class="check{{q}}" [value]="queAnswers.answerId" (change)="onCheckboxChange($event, q , i)" formControlName="answerId">
                  {{queAnswers.description}} 
                  
                </div>
              
                </div>
              </div>
          
            </div>

          </div>
        </div>
      </div>
      <div class="submit">
        <button class="submBtn" type="submit" (click)="submitTest()" [hidden]="isLoading">Submit</button>
        <div class="loader" style="margin-left: 60px;" *ngIf="isLoading"></div>
      </div>
    </form>
  </div>
</div>