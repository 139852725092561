import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GlobalDataService } from './shared/global-data';
import { StorageMap } from '@ngx-pwa/local-storage';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

    loggedOut: boolean = false;
    removeWhiteBg: boolean;
    isReportDashboardandCofs: boolean = false;
    //rawCSSUrl: string = '/assets/css/override_momentumwellness.css';
    sanitizedThemeUrl: any;
    pData: any = [];

    constructor(private globalData: GlobalDataService, private router: Router,
        private lsService: StorageMap){

        /* lets get profile data */
        this.globalData.profileData.subscribe(gsData => {
            this.lsService.get('profileData').subscribe((data: any) => {
                if(typeof data !== 'undefined'){
                    this.pData.push(data);
                    let url = location.href;
                    if(url.indexOf('setpassword') > -1){
                        this.loggedOut = true;
                        this.globalData.changeLoggedInState(false);               
                    } else if(typeof data == 'undefined'){
                        this.loggedOut = true;
                        this.globalData.changeLoggedInState(false);
                        this.router.navigate(['/login']);                
                    } else {
                        if(data.isSaps){
                            this.router.navigate(['/reports-dashboard']);
                        } else {
                            this.router.navigate(['/dashboard']);                  
                        }
                        this.globalData.changeLoggedInState(true);                
                    }
                } else {
                    if(gsData != null){
                        this.pData.push(gsData);
                    }                    
                }
            });
        });        

        /* check current view */
        this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((data) => {
            if(data["url"] == '/dashboard'){
                this.removeWhiteBg = true;
                $('body').addClass('dashboard');
            } else if (data["url"] == '/questionnaire'){
                this.removeWhiteBg = true;
            } else if(data["url"] == '/reports-dashboard'){
                // hide top blocks altogether for main dashboard
                this.isReportDashboardandCofs = true;
            } else if(data["url"] == '/e-learning' || data["url"] == '/survey' || data["url"] == '/video'){
                // hide top blocks altogether for main dashboard
                this.isReportDashboardandCofs = true;
            } else {
                this.removeWhiteBg = false;
                $('body').removeClass('dashboard');
                this.isReportDashboardandCofs = false;
            }                
        });
        /* check current login state */
        this.globalData.isLoggedIn.subscribe(val => {
            if(!val){
                this.loggedOut = true;       
            } else {
                this.loggedOut = false;
            }
        });
    }
}
