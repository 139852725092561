<div class="login-banner">
	<!--<img src="../assets/img/health_profilebanner.png" alt="">-->
</div>
<div class="health-profile-hero">
	<h1>my health profile</h1>
	<p>To continue please login below.</p>
</div>
<div class="login-form">
	<form [formGroup]="loginForm">
		<div class="profileUsername input-row">
			<i class="icon i-profile"></i>
			<label>{{profileUsername}}</label>
			<input type="text" formControlName="username" name="username">
		</div>
		<div class="profilepassword input-row">
			<i class="icon i-password"></i>
			<label>Password</label>
			<input type="password" formControlName="password" name="password">
		</div>
		<!-- Momentum Wellness Custom -->
		<div class="i-next submit-btn" [hidden]="isLoading">
			<button class="btn" (click)="loginMomentumwellnessNext()"  >Next</button>
		</div>
		<div class="i-submit submit-btn" [hidden]="isLoading">
			<button class="btn" (click)="login()" [disabled]="loginForm.status == 'INVALID'">Login</button>
		</div>
		<p class="error red-error-text" *ngIf="errMsg.length > 4">{{ errMsg }}</p>
		<div class="loader" *ngIf="isLoading"></div>
		<div class="login-options" [hidden]="isLoading">
			<a routerLink="/register">Sign up?</a>
			<div>
				<a [routerLink]="['/renewpass','1Gh0928kLN']">I forgot my password</a> 
				<span>|</span> 
				<a routerLink="/renewpass">Reset my password</a>
			</div>
		</div>
	</form>
</div>