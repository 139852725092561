<div class="loader" *ngIf="isLoading"></div>
<div class="component-content-elearning-question" >
    <h3 class="block-title">Home<span class="arrow">&nbsp;>&nbsp;</span> <span class="current-header">e-learning</span><span  class="current-header">  > {{topicmainheadernav}} > Self-check</span>
    </h3>
    <hr>
 
  <br>
  <form  [formGroup]="eidFormSubmission">
    <div class="row row_m" *ngIf="questionMain && showQuestionArray" formArrayName="questionArray">
        <h4>Self-check : {{videoHeader}}</h4>
       
        <div class="column"  *ngFor="let item of questionlist; let i = index" [formGroupName]="i">
          
            <div class="topic-main">
                <h2>Question {{item.questionNumber}}</h2>
                <h4>{{item.question}}</h4>
            <p>
                <span class="example-list-section">
                    <ul *ngFor="let  answers of item.questionChoices; let j = index">
                       <li [ngClass]="{validation_green: answers.isCorrectChoice == 'Y' && assessmentreview , validation_red: answers.isCorrectChoice == 'N' && assessmentreview && answers || assessmentreviewnull && answers}">
                          
                        <label>
                            <input type="radio" id="{{i}}" value="{{answers.choiceId}}" formControlName="answer">{{answers.choice}}
                        </label>
       
                      </li>
                    </ul>
               
                  </span>
            </p>
     
                
            </div>
            
        </div>
        <div class="buttonQuestion">
            <a routerLink='/video' class="btn-wh">Back</a>&nbsp; 
            <button mat-raised-button  
            *ngIf="assessmentreview"
            (click)="submitQuestion()" class="btn">Finish</button>
             <button mat-raised-button 
             [disabled]="eidFormSubmission.value == false"
            *ngIf="!assessmentreview" (click)="reviewQuestions()" class="btn">Submit</button>
           
           
        </div>
        
    </div>
  </form>
  <!-- {{ eidFormSubmission.value | json }} -->
    <div class="assessmentCompleted" *ngIf="assessmentCompleted">
        <h4 class="completed">Self-check complete </h4>
        <p>Congratulations! {{randomtxt}}</p>
            <div class="scorebox">
                <p>Total score:</p>
                <hr>
               <span class="score"> {{totalQuizQuestionsCorrect}}/{{totalQuizQuestions}}</span>
               
            </div>
            <p>
                <button (click)="nextQuestion()" mat-raised-button 
                class="btn nextbtn"
                >Next</button>
            </p>
    </div>
   
</div>
