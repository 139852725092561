import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse,HttpEventType } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GlobalDataService } from '../../shared/global-data';
import * as Enums from '../../shared/enums';

// fileData: File = null;
@Injectable({ providedIn: 'root' })
export class CasesService {

	constructor(public http: HttpClient){}
	
	getCases (type): Observable<any> {
		let apiPath;
		let getGUID = JSON.parse(localStorage.getItem('guid1'));
		if(type == 'eap' || type == 'sr'){
			apiPath = Enums.API_PATHS.GETCASES + "/employee/" + getGUID;
		}
		if(type == 'managerial' || type == 'fr'){
			apiPath = Enums.API_PATHS.GETCASES + "/managerreferal/" + getGUID;
		}
		if(type == 'cisd'){
			apiPath = Enums.API_PATHS.GETCASES + "/cisd/" + getGUID;
		}				
		return this.http.get(Enums.API_BASE_PATHS + apiPath).pipe(
			tap( success => console.log('success'),
   				error => console.log('get Cases by GUID did not work...API error!'))
		);
	}
	getCaseDetails (requestID): Observable<any> {
		let apiPath = Enums.API_PATHS.CASE_DETAILS + "/" + requestID; 				
		return this.http.get(Enums.API_BASE_PATHS + apiPath).pipe(
			tap( success => console.log('success'),
   				error => console.log('get Cases Details did not work...API error!'))
		);
	}	
	createCase (createObj,type): Observable<any> {
		let apiPath;
		if(type == 'sr'){
			apiPath = '/sr';
		} else if(type == 'fr'){
			apiPath = '/fr';
		} else {
			apiPath = '/cisd';
		}
		return this.http.post(Enums.API_BASE_PATHS + Enums.API_PATHS.CASE_CREATE + apiPath, JSON.stringify(createObj)).pipe(
			tap( success => console.log('success'),
   				error => console.log('create case did not work...API error!'))
		);
	}			
	private handleError<T> (operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			//this.logException(Enums.LEVELS.ERROR, operation + ':' + error.message);
			return of(result as T);
		};
	}
	private handleAuthError(err: any): Observable<HttpErrorResponse> {
		throw err;
	}	
	private log(message: string) {
		//console.log(`${message}`);
	}	
}