<div class="faq-container clearfix">
	<!-- LEFT FAQs -->
	<div class="faq faq-left fLeft">
		<h3>Frequently asked questions</h3>
		<!-- question -->
		<div class="question">
			<div class="title">1. Who is eligible for {{isNedBank?'EWP':'EAP'}}?</div>
			<div class="answer">All employees and their dependents living in the same household.</div>
		</div>
		<!-- question -->
		<div class="question">
			<div class="title">2. Where will I be referred to?</div>
			<div class="answer">You can be referred to an affiliated therapist close to where you live or work.</div>
		</div>				
		<!-- question -->
		<div class="question">
			<div class="title">3. Can I speak in a language that I am comfortable in?</div>
			<div class="answer">Yes, our care centre can assist you in the 11 official languages</div>
		</div>
		<!-- question -->
		<div class="question">
			<div class="title">4. Is the {{isNedBank?'EWP':'EAP'}} service really confidential?</div>
			<div class="answer">All {{isNedBank?'EWP':'EAP'}} records and services are treated with the strictest confidentiality. The personal information you share with your counsellor is confidential, unless you sign a release of information or if the law requires disclosure (eg if there is a threat of serious harm to yourself or others, or if there are suspicions of child abuse).</div>
		</div>
		<!-- question -->
		<div class="question">
			<div class="title">5. Do I have to wait until a problem is really serious before I can call the {{isNedBank?'EWP':'EAP'}}?</div>
			<div class="answer">No, problems are most effectively handled before they become serious. The key is to ask for assistance before the problem begins to affect your home and work performance.</div>
		</div>
		<!-- question -->
		<div class="question">
			<div class="title">6. Why use the {{isNedBank?'EWP':'EAP'}}?</div>
			<div class="answer">When life challenges become overwhelming, they have the potential to interfere with your day-to-day performance. That's when you need to seek support from {{isNedBank?'EWP':'EAP'}}.</div>
		</div>												
	</div>
	<!-- RIGHT FAQs -->
	<div class="faq faq-right fRight">
		<h3>More information about {{isNedBank?'EWP':'EAP'}}</h3>
		<p>Our employee assistance programme aims to support employees and their families so that they can manage work, mental and/or behavioural health and life challenges while assisting organization leadership to manage the productivity and performance issues related to an employee's life challenges.</p>
		<p>This programme includes:</p>
			<ul>
				<li><i class="icon i-check"></i> Telephone counselling 24/7 365 days a year</li>
				<li><i class="icon i-check"></i> Face-to-Face Counselling</li>
				<li><i class="icon i-check"></i> Managerial Consultation</li>
				<li><i class="icon i-check"></i> Legal and Financial Advice</li>
				<li><i class="icon i-check"></i> Trauma Debriefing and Counselling</li>
				<li><i class="icon i-check"></i> Return to work counselling</li> 
			</ul>
		<div *ngIf="!isNedBank" class="contact-eap">
			<i class="icon i-contact"></i>
			<h6>Contact the EAP</h6>
			<p class="mh-email">
				Call your dedicated toll free line or send an email to eap@metropolitanhrm.co.za
			</p>
			<p class="mom-email">
				Call your dedicated toll free line or send an email to BeWell@momentum.co.za
			</p>			
		</div>
		<div *ngIf="isNedBank" class="contact-eap">
			<i class="icon i-contact"></i>
			<h6>Contact the EWP</h6>
			<p class="mom-email">
				Call your dedicated toll free line or send an email to <br/>
				RSA email: ewp@momentum.co.za<br/>
				NAR email: NARwellbeing@momentum.co.za
			</p>			
		</div>
		<div class="contact-eap">
			<i class="icon i-operational_hours"></i>
			<h6>{{isNedBank?'EWP':'EAP'}} operational hours</h6>
			<p>Counsellors are available 24 hours a day, 7 days a week to confidentially discuss your concerns and ensure that you receive the assistance you need.</p>
		</div>
		<!--div class="contact-eap">
			<i class="icon i-counseling"></i>
			<h6>Face-to-Face counselling session</h6>
			<p>blah blah blah blah blah blah blah blah</p>
		</div-->								
	</div>
</div>