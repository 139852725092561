<div class="component-content-elearning" id="top">
    <h3 class="block-title">Home<span class="arrow">&nbsp;>&nbsp;</span> <span class="current-header">e-learning</span> <span *ngIf="mainTopicVideoView" class="current-header">  >  {{topicmainheadernav}}</span>   </h3>
    <hr>
    <div *ngIf="mainBanner" >

        <owl-carousel-o [options]="customOptions" *ngIf="!isSiteMomWellness else momentum_wellness_banner">
            <ng-template carouselSlide>
                <div class="slide">
                    <img src="../../../assets/img/icons/elearning/metropolitan-e-learning-banner_2.png">
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="slide">
                    <img src="../../../assets/img/icons/elearning/metropolitan-e-learning-banner_2-01.png">
                </div>
            </ng-template>
        </owl-carousel-o>

        <ng-template #momentum_wellness_banner>
            <owl-carousel-o [options]="customOptions" >
                <ng-template carouselSlide>
                    <div class="slide">
                        <img src="../../../assets/img/icons/elearning/Momentum-Wellness-e-learning-banner.jpg">
                    </div>
                </ng-template>
            </owl-carousel-o>
        </ng-template>
        <p class="pc">Each of the programmes below have been created with you, and your specific lifestyle, in mind. They all offer actionable insights into how best you can adapt your behaviour to create the healthiest, and happiest, version of yourself. Choose the programme you are most interested in and follow the prompts from there. Check back here to view your progress, and to choose the next one.</p>
    
    </div>
  <br>
  <div class="loader" *ngIf="isLoading"></div> 
    <div class="row row_m" *ngIf="mainTopicList" >
        <div class="column" *ngFor="let item of topiclist let i = index">
                <div class="loader" *ngIf="isLoading"></div> 
            <img class="topic-image" src="{{item.srcImg}}" alt="">
            <div class="topic-main elearning-card-arrow">
                <h4>{{item.header}}</h4>
            <p>
                {{item.description}}
            </p>
            <div  id="aBtnGroup" class="btn-group">
                <button [disabled]="item.videos.length < 3" id="{{item.topicID}}" class="btn" (click)="videoview($event, item.header ,item.description)" value="{{item.topicID}}">Start</button>
            </div>
                
            </div>
            
        </div>
    </div>

</div>

    <br>
    <br>
    <div class="mainTopicVideoView" *ngIf="mainTopicVideoView">
   
        <div class="row">
            <h2 class="headerTopic"> {{topicmainheadernav}}</h2>
            <p class="descriptionTopic">{{topicmaindescription}}</p>

            <div *ngIf="isSiteMomWellness else mhwellness" >
               
                    <div class="column-view video_arrow" *ngFor="let video of topicVid let o = index" >
                        <div *ngIf="watchedVidesIdArray.includes(video.videoID) ? false: true"  (click)="mainVideo(video.videoID, video.videoName, video.videoNumber, video.header)"  class="topic-main-view">
                            <div class="loader" *ngIf="isLoading"></div> 
                            <h4 class="videotitle">{{video.header}}</h4>
                            <div>
                                <video [style.width]="isSiteMomWellness ? '89%' : '' "  id ="{{video.videoID}}" class="{{video.videoName}} "
                                src="{{video.videoFinalUrl}}#t=3"
                                ></video>
                            </div>
                    
                        </div>
                        
                        <div *ngIf="watchedVidesIdArray.includes(video.videoID) ? true: false" class="score_completed">
                            <h4 class="videotitle pcompleted">{{video.header}}</h4>
                            <h4 class="completed">Video completed </h4>
                        
                        </div>
                    </div>
            </div>
            
            
            <ng-template #mhwellness>
            <div class="column-view video_arrow" *ngFor="let video of topicVid let o = index" >
                <div *ngIf='(topicVid)?.length === 0'>No results</div>

                <div *ngIf="video.videoProgress < 100 !isSiteMomWellness"  (click)="mainVideo(video.videoID, video.videoName, video.videoNumber, video.header)"  class="topic-main-view">
                    <div class="loader" *ngIf="isLoading"></div> 
                    <h4 class="videotitle">{{video.header}}</h4>
                    <div>
                        <video [style.width]="isSiteMomWellness ? '89%' : '' "  id ="{{video.videoID}}" class="{{video.videoName}} "
                        src="{{video.videoFinalUrl}}#t=3"
                        ></video>
                    </div>
            
                </div>

                
                <div *ngIf="video.videoProgress == 100 && !isSiteMomWellness" class="score_completed">
                    <h4 class="videotitle pcompleted">{{video.header}}</h4>
                    <h4 class="completed">Self-check complete </h4>
                    <div class="scorebox">
                        
                        <hr>
                        <span>Total score:</span>  <span class="score">  {{video.totalQuizQuestionsCorrect}}/{{video.totalQuizQuestions}}</span>
                    </div>
                </div>
                <span *ngIf="video.videoProgress < 100 && !isSiteMomWellness"  class="quesitonCount"> <hr> Self-check  {{video.videoNumber}} - {{topicVid.length}} Questions</span>
                <span  class="downloadPrint"> <a *ngIf="video.videoProgress == 100 && !isSiteMomWellness" class="downloadPrint_completed" href="{{video.videoPdfUrl}}" (click)="total_PDF_downloaded()" target="_blank">Download/Print - {{video.knowledgePDFName}}</a> </span>
                
            </div>
        </ng-template>

        

        </div>
     
        <div *ngIf="!isSiteMomWellness" id="surveyCheck" class="column mb video_arrow" (click)="loadview()" >
            <img class="survey-image" src="../../../assets/img/icons/elearning/windowcheck.jpg" alt="">
            <div class="topic-main">
                    <h4>Wellness Window Check</h4>
                <p>
                    We’d like to have a glance at how you feel about this wellness programme.
                </p>
            
            </div>
            
        </div>

        
        
    </div>

    <div>

    </div>
    <a (click)="elearningHome()" *ngIf="mainBackButton" [ngClass]=" isSiteMomWellness ? 'btn-wh-momwellness' : 'btn-wh' ">Back</a>
