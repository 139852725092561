import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalDataService } from '../../../shared/global-data';
import { distinctUntilChanged } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-topblocks',
  templateUrl: './topblocks.component.html',
  styleUrls: ['./topblocks.component.css']
})
export class TopblocksComponent implements OnInit {

	isCaseView: boolean = false;
	isWellBeingView: boolean = false;
	isFaq: boolean = false;
	activeStep: number = 1;
	isLifeStyleIconActive: boolean = null;
	isScoreActive: boolean = false;
	headerType: string = '';
	scoreHeaderConc: string = '';
	queHeaderConc: string = 'Lifestyle-score';
	isScoreHeaderCheck: boolean = false;
	isQueHeaderCheck: boolean = false;
	headerTitleControl: boolean = false;
	isSaps: boolean = false;
	isNedBank: boolean = false;

	constructor(private router: Router, private globalData: GlobalDataService, 
		private lsService: StorageMap) {
	}

	ngOnInit() {
		//Test commit
		this.globalData.tBlockStep.pipe(distinctUntilChanged()).subscribe(val => {
			this.activeStep = val;
			
		});
		this.globalData.currView.subscribe(view => {
	        if(view == 'case'){
	        	this.globalData.changeTBlockState(this.activeStep);
				this.isCaseView = true;
				this.isFaq = false;
				$('body').addClass('mini-menu');
	        } else if(view == 'faqs'){
	        	this.isFaq = true;
	        	$('body').addClass('mini-menu');
			}
			else if(view == 'well-being'){
				this.isWellBeingView = true;
				this.headerType = 'Wellbeing-score';
				this.isScoreHeaderCheck = false;
				this.isScoreActive = true;
				this.isQueHeaderCheck = false;
				this.headerTitleControl = false;
				this.globalData.changeTBlockState(1);
				$('body').addClass('mini-menu');
			}else if(view == 'dashboard' || view == 'legal' || view == 'financial'){
				this.isWellBeingView = false;
				this.isCaseView = false;
				this.isScoreHeaderCheck = false;
				this.isScoreActive = false;
				this.isFaq = false;
				$('body').removeClass('mini-menu');
			}else if(view == 'questionnaire'){
				this.isQueHeaderCheck = true;
				this.headerType = 'Update';
				this.isWellBeingView = true;
				this.isScoreHeaderCheck = false;
				this.scoreHeaderConc ='';
				this.isScoreActive = false;
			}else if(view == 'well-being-score'){
				this.isQueHeaderCheck = true;
				this.isWellBeingView = true;
				this.isScoreHeaderCheck = false;
				this.scoreHeaderConc ='';
				this.isScoreActive = false;
				$('body').addClass('mini-menu');
			}else{
				this.headerType = 'Wellbeing-score';
				this.globalData.changeTBlockState(1);
			}		
		});

		

        /* lets get profile data */
        this.globalData.profileData.subscribe(gsData => {
            this.lsService.get('profileData').subscribe((lsData) => {
				if(typeof lsData !== 'undefined'){
					this.isLifeStyleIconActive = lsData["activateLifestyleIcon"];
					this.isSaps = lsData["isSaps"];
					this.isNedBank = lsData["isNedBank"];
				} else {
					if(gsData != null){
						this.isLifeStyleIconActive = gsData["activateLifestyleIcon"];										
					}                    
				}
            });
		})
		
		this.globalData.tBlockStep.pipe(distinctUntilChanged()).subscribe(val => {
			if(val == 5) {
				this.headerType = 'Wellness cafe';
				this.isScoreHeaderCheck = false;
				this.isQueHeaderCheck = false;
				this.isScoreActive = false;
				this.headerTitleControl = true;
				this.router.navigate(["wellness-cafe"]);
			}else if(val == 6) {
				this.headerType = 'Suggested resource';
				this.isScoreHeaderCheck = false;
				this.isQueHeaderCheck = false;
				this.isScoreActive = false;
				this.headerTitleControl = true;
				this.router.navigate(["/suggested-resource"]);
			}else if(val == 1 && this.isWellBeingView && this.headerTitleControl) {
				this.scoreHeaderConc= 'Wellbeing-score';
				this.isScoreHeaderCheck = true;
				this.isQueHeaderCheck = false;
				this.headerType = 'Overview';
				this.router.navigate(["well-being-score"]);
			}else if(val == 1 && this.isScoreActive) {
				this.headerType = 'Wellbeing-score';
				//this.router.navigate(["improve-health"]);//same component can be used for score
			}
		});
	}

	loadView(page){
		if(page == "case"){
			this.isCaseView = true;
			this.globalData.changeCurrentViewState('case');
			$('body').addClass('mini-menu');
		}
		else{
			this.isCaseView = false;
		}
		
		this.router.navigate([page]);
	}

	loadModal(type){
		this.globalData.changeCurrentModalState(type);
	}

	changeStep(index){
		this.activeStep = index;
		this.globalData.changeTBlockState(index);
		this.lsService.get('profileData').subscribe((lsData) => {
			if(lsData !=undefined)
			this.isNedBank = lsData["isNedBank"]
		})
	}

	openLink(){
		window.open('https://metropolitan.2bwell.co.za', "_blank");
	}
   
	openWellbeing(){
        this.globalData.changeCurrentViewState('well-being-score');
        this.router.navigate(['well-being-score']);
	}

}
