<div class="main-menu{{isSars}} fLeft"  [ngClass]="{ 'saps-data' : pData[0]?.isSaps == true }">
	<div class="brand-logo">
		<div class="logo-container" *ngIf="pData[0]?.isSaps">
			<img src="assets/img/saps_logo.svg" alt="SAPS" class="saps-logo">
		</div>
		<div class="emp-info">
			<h5>Hello {{ pData[0]?.name }}!</h5>
			<p>Employee number: <strong>{{ pData[0]?.empNo }}</strong></p>
			<p *ngIf="pData[0]?.idNumber.length==13">ID number: <strong>{{ pData[0]?.idNumber }}</strong></p>
			<p *ngIf="pData[0]?.idNumber.length!=13">Passport number: <strong>{{ pData[0]?.passportNumber }}</strong></p>
		</div>		
	</div>
	<div class="shortcut-links" [hidden]="pData[0]?.isSaps">
		<div class="shortcut s-mycase loadView" (click)="goToPage('/case','casetable')">
			<i class="icon i-mcase"></i> <span>My Case</span>
		</div>
		<div class="shortcut s-mycal loadView" data-loadview="mycalendar" 
			(click)="goToPage('/case','casecalendar')">
			<i class="icon i-mcal"></i> <span>My Calendar</span>
		</div>
		<!--
		<div class="shortcut s-mycouns loadView" data-loadview="mycounsellors">
			<i class="icon i-mcouns"></i> <span>My Counsellors</span>
		</div>
		<div class="shortcut s-mydocs loadView" data-loadview="mydocuments">
			<i class="icon i-mdocs"></i> <span>My Documents</span>
		</div>-->
	</div>
	<div class="bottom-tools" [hidden]="pData[0]?.isSaps">
		<div class="blink help loadModal" data-loadmodal="help"
			(click)="loadModal('help')">
			<i class="icon i-help"></i>
			<span>Help?</span>
		</div>
		<div class="blink faqs loadView" data-loadview="faqs" (click)="goToPage('/faqs',null)">
			<i class="icon i-faq"></i>
			<span>FAQs</span>
		</div>
		<div class="blink faqs loadView" 
			data-loadview="faqs" 
			(click)="openReportLink()" 
			*ngIf="pData[0]?.showReports == true">
			<i class="icon i-mcase"></i>
			<span>Reports</span>
		</div>		
	</div>
</div>