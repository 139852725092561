import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalDataService } from '../../../shared/global-data';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, of } from 'rxjs';
import { delay, share } from 'rxjs/operators';

@Component({
  selector: 'app-leftmenu',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.css']
})
export class LeftmenuComponent implements OnInit {

    pData: any = [];
    isSars: string;
    isSiteMomWellness = localStorage.getItem('loadMomWellnessStylesheet');


    constructor(private router: Router, private globalService: GlobalDataService,
        private lsService: StorageMap) { }

    ngOnInit() {
        /* lets get profile data */
        this.globalService.profileData.subscribe(gsData => {
            this.lsService.get('profileData').subscribe((lsData) => {
                if(typeof lsData !== 'undefined'){
                    this.pData.push(lsData);
                } else {
                    if(gsData != null){
                        this.pData.push(gsData);
                    }                    
                }
            });
        });

        this.sarsCheck() 
    }

    goToPage(page,data){
        let sanitizedUrl = page.replace("/","");
        this.globalService.changeCurrentViewState(sanitizedUrl);
        this.router.navigate([page, { dataroute: data }]);
    }

    openReportLink(){
        window.open("https://dashboard.phelawellness.co.za/app/#Login", "_blank");
    }

    loadModal(type){
        this.globalService.changeCurrentModalState(type);
    }

    
    sarsCheck(){
		let str = localStorage.getItem('ParentCode');
		let ParentCode = str.replace(/\s/g, '');
    
        if(this.isSiteMomWellness && ParentCode == "60088"){
            this.isSars = "";
        }else if(ParentCode == "60088" && !this.isSiteMomWellness) { //SARS -South African Revenue Service
            this.isSars = "-sars";
		} else {
            this.isSars = "";
		}
	}
}