<!--<link *ngIf="pData[0]?.loadMomWellnessStylesheet" rel="stylesheet" [href]='rawCSSUrl | safe'>-->

<app-header></app-header> <!-- HEADER -->
<div class="row application-container">

	<app-modal></app-modal> <!-- GLOBAL MODAL -->

	<div class="content-container clearfix">
		<app-leftmenu [hidden]="loggedOut"></app-leftmenu> <!-- MENU -->
		<div class="rhs-content fRight">
			<!-- TOP BLOCKS -->
			<app-topblocks [hidden]="loggedOut || isReportDashboardandCofs"></app-topblocks>
			<div class="loader"></div>
			<div class="component-content" 
				[ngClass]="{ 'no-bg': removeWhiteBg == true }">
				<!--<div class="loader"></div>-->
				<router-outlet></router-outlet> <!-- MAIN ROUTER -->
			</div><!-- ./ Content container -->
		</div> <!-- ./ content container -->			
	</div> <!-- ./ Main Application Container -->
	<app-footer [loggedOut]="loggedOut"></app-footer> <!-- FOOTER -->	
</div> <!-- ./ Row -->	