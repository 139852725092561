<div class="page cofs-page">
	<div class="content">
		<div class="tab-container cof-reports">
			<div class="tab-header">
				<div *ngFor="let label of tabNames; let i = index" 
					class="tab" 
					[attr.data-index]="i"
					[attr.data-name]="label.apiLabel">{{ label.uiLabel }}</div>
			</div>
		</div>
		<div class='search-tool'>
			<div class='search-input'>
				<input 
					type='text' 
					name='search' 
					placeholder='Search by ID number' 
					maxlength="13"  
					(keyup)="filterSearch($event.target.value)" 
					[disabled]="isLoading">
			</div>
			<p class="red-error-text" *ngIf="isInvalidID">
				You have entered an invalid ID number. Please try again
			</p>
		</div>
		<table class='data-table cof-data-table' 
			cellpadding='0' 
			cellspacing='0' 
			border='0' 
			[hidden]="cofData.length <= 0">
			<tr class='header-cols'>
				<td>Date</td>
				<td>File name</td>
				<td>Id number</td>
				<td>Auth number</td>
				<td>&nbsp;</td>
			</tr>
			<tr class="row" *ngFor="let item of cofData; let i = index">
				<td class='col'>{{ item.completion | date: 'dd MMMM yyyy' }}</td>
				<td class='col'>{{ item.fileName }}</td>
				<td class='col'>{{ item.idNumber }}</td>
				<td class='col'>{{ item.authNumber }}</td>
				<td class='col'>
					<button class="btn" (click)="openFile(item.fileData)">view</button>
				</td>
			</tr>
		</table>
		<div class="alerts">
			<div class="loader" *ngIf="isLoading"></div>
			<p class="red-error-text" *ngIf="noData">
				We could not find any certificates for your ID number.
			</p>
			<p class="red-error-text" *ngIf="noData">
				Please try a different ID number or contact 0800111223 or email support.mhwellness@mhg.co.za for assistance.
			</p>			
		</div>
	</div>
</div>