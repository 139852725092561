<div class="overlay" *ngIf="isModalActive"></div>
<div class="modal" *ngIf="isModalActive">
	<div class="mod-header" *ngIf="isRequestCallBack">
		<h5>Request a callback</h5>
		<span class="close-modal" (click)="closeModal()">x</span>
	</div>
	<div class="mod-header" *ngIf="isFinancial">
		<h5>Financial Calculators</h5>
		<span class="close-modal" (click)="closeModal()">x</span>
	</div>
	<div class="mod-header" *ngIf="isLegal">
		<h5>Available Tools: Legal Services</h5>
		<span class="close-modal" (click)="closeModal()">x</span>
	</div>
	<div class="mod-header" *ngIf="isFinancialTools">
		<h5>Financial Tools</h5>
		<span class="close-modal" (click)="closeModal()">x</span>
	</div>
	<p class="intro-p" *ngIf="showServBlock">Select the service you would like to request below:</p>
	<div class="service-blocks clearfix">
		<!-- HELLO DOCTOR CALLBACK -->
		<div class="serv-block" *ngIf="showServBlock" style="height: 110px;">
			<div class="img-container">
				<img src="../assets/img/hd_logo.png" alt="Hello Doctor">
			</div>
			<p class="p_hd">Request for Hello Doctor callback</p>
			<button class="btn modal_btn" (click)="setCallType('hd')">Request a call</button>
		</div>
		<!-- EAP COUNSELLOR CALLBACK -->
		<div class="serv-block" *ngIf="showServBlock">
			<div class="callback_init">
				<div class="img-container">
					<!-- <img src="../assets/img/eap_counsellor.png" class="eap_logo" alt="EAP Counsellor"> -->
					<p style="color: #102F6C;font-size: 15px;"><strong>{{isNedBank?'EWP':'EAP'}}</strong> Counsellor</p>
				</div>
				<p class="p_eap">Request for {{isNedBank?'EWP':'EAP'}} counsellor callback</p>
				<button class="btn modal_btn" (click)="setCallType('eap')">Request a call</button>
			</div>
		</div>
		<!-- dynamic data controlled by JS: confirm details -->
		<div class="callback_detail" *ngIf="showCallbackDetail">

			<h5 class="emergency-content" *ngIf="isEmergency">Emergency</h5>
			<p class="emergency-content" *ngIf="isEmergency">
				Request for service by employee / manager when {{isNedBank?'EWP':'EAP'}} emergency arises
			</p>

			<p>Please confirm / edit your details below before sending</p>
			<form [formGroup]="callbackForm">
				<div class="input-row id_number" *ngIf="showIdNumber">
					<label>Id number</label>
					<input type="text" formControlName="idNumber" name="idNumber">
				</div>
				<div class="input-row">
					<label>Contact number</label>
					<input type="text" formControlName="contactNo" name="contactNo">
				</div>
			</form>

			<div class="loader" *ngIf="isLoading"></div>
			<p class="red-error-text" *ngIf="errorMsg.length > 1">{{ errorMsg }}</p>

			<div class="buttons" [hidden]="isLoading">
				<button class="btn" [disabled]="callbackForm.status == 'INVALID'"
					(click)="requestCallBack()">Send</button>
			</div>
		</div>
		<!-- dynamic data controlled by JS: success screen -->
		<div class="callback_success" *ngIf="isSuccessfull">
			<div class="success-header">
				<span>Request successfully sent!</span>
			</div>
			<p>Your request has successully been submitted to our <em>{{ ctx_name }}</em> call
				center and a <em>{{ ctx_person }}</em> will be in contact.</p>
			<p *ngIf="eap_ref_no.length > 0">
				Your reference number for this call is <strong>{{ eap_ref_no }}</strong>
			</p>
			<button class="btn close-modal" (click)="closeModal()">Ok</button>
		</div>
		<!-- financial wellness calculator -->
		<div class="financial_wellness" *ngIf="isFinancialModalActive">
			<ul>
				<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=FINC004&amp;Y=b5452cc1e3234469e94e04596a9681ed"
							target="_blank" rel="noopener noreferrer">Retirement Calculator</a></span></li>
				<li><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=FINC003&amp;Y=faa09e9b4ddbbda5f1aeab57daeab74a"
						target="_blank" rel="noopener noreferrer"><span>Savings
							Calculator</span></a></li>
				<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=FINC001&amp;Y=f75d54b1546f3d3c5aeb63866ba9f573"
							target="_blank" rel="noopener noreferrer">Interest Calculator</a></span></li>
				<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=FINC002&amp;Y=c1b4b1cf70dc33e2a9b9fb5b3b8ac10a"
							target="_blank" rel="noopener noreferrer">Inflation Calculator</a></span></li>
			</ul>
		</div>
		<!-- financial wellness tools -->
		<div class="financial_wellness_tools" *ngIf="isFinancialToolsModalActive">
			<div class="one_half">
				<ul>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1027&amp;Y=e2f2ae6ef635988bf30ca27ee0b10d62"
								target="_blank" rel="noopener noreferrer">“Time is money” – Interest &amp;
								Inflation</a></span></li>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1028&amp;Y=aa0d5053b27cabcd0e1b0292bfff9348"
								target="_blank" rel="noopener noreferrer">Achieving Financial Goals</a></span></li>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1029&amp;Y=18ec992cc0b5468e7bde5798e36c89f7"
								target="_blank" rel="noopener noreferrer">Budgets and Financial Health</a></span></li>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1030&amp;Y=02de248a6f7eba4f18bc759957489e44"
								target="_blank" rel="noopener noreferrer">Debt and court cases: What to do.</a></span>
					</li>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1031&amp;Y=e7c063f396a38a7e64ae1f4e8743d06a"
								target="_blank" rel="noopener noreferrer">Financial Fitness Report</a></span></li>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1032&amp;Y=6680ee048395eb41906ada5f46e5c331"
								target="_blank" rel="noopener noreferrer">Financing your House and Car</a></span></li>
				</ul>
			</div>
			<div class="one_half last_child">
				<ul style="list-style: circle; padding-left: 40px; padding-top: 10px;">
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1033&amp;Y=aee1b0cd657c4180e4eeacfd632fcd53"
								target="_blank" rel="noopener noreferrer">Fixing extreme over-indebtedness</a></span>
					</li>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1034&amp;Y=33694961234b87411a26d87bf2df0545"
								target="_blank" rel="noopener noreferrer">Planning for Retirement</a></span></li>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1035&amp;Y=2b04fa71bc4d47887b0865680fe57ded"
								target="_blank" rel="noopener noreferrer">Savings: Planning for the future</a></span>
					</li>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1036&amp;Y=085ebd6b6b66cffc646b9a82b4add9ea"
								target="_blank" rel="noopener noreferrer">Taking control of your debt</a></span></li>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1037&amp;Y=3bd60569af6dddc40b8e5605c5777ccd"
								target="_blank" rel="noopener noreferrer">What am I worth?</a></span></li>
				</ul>
			</div>
		</div>
		<!-- Legal tools -->
		<div class="legal_tools" *ngIf="isLegalToolsModalActive">
			<p><span style="font-size: 10pt;">The following legal contracts and documents are included (click on the
					links below to access the documents):</span></p>
			<div class="one_half">
				<ul style="list-style: circle; padding-left: 40px; padding-top: 10px;">
					<li><span style="font-size: 10pt;"><a
								href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1015&amp;Y=537321b0381c3aeb04279bafefdbb31a"
								target="_blank" rel="noopener noreferrer">Last will and testament</a></span></li>
					<li><span style="font-size: 10pt;"><a
								href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1010&amp;Y=67634262fbf233259646afea64a5f27c"
								target="_blank" rel="noopener noreferrer">Domestic employment agreement</a></span></li>
					<li><span style="font-size: 10pt;"><a
								href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1008&amp;Y=34ce2c12886e5c625e83c746df69cee8"
								target="_blank" rel="noopener noreferrer">Antenuptial contract</a></span></li>
					<li><span style="font-size: 10pt;"><a
								href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1017&amp;Y=b1841b2c7fd6189da383425e87b027e1"
								target="_blank" rel="noopener noreferrer">Residential lease</a></span></li>
					<li><span style="font-size: 10pt;"><a
								href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1016&amp;Y=84bce10d09d5e854f3a6a8e53f5b7a78"
								target="_blank" rel="noopener noreferrer">Commercial lease</a></span></li>
					<li><span style="font-size: 10pt;"><a
								href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1018&amp;Y=f250d6832bc52b7b8ef7b90113a60df7"
								target="_blank" rel="noopener noreferrer">Loan agreement</a></span></li>
					<li><span style="font-size: 10pt;"><a
								href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1022&amp;Y=9e90498d1e8b943f071669d27a1f2c7d"
								target="_blank" rel="noopener noreferrer">Sale of property</a></span></li>
				</ul>
			</div>
			<div class="one_half last_child">
				<ul>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1014&amp;Y=6f7feac7ce2cbfbdee6cf9580cedc925"
								target="_blank" rel="noopener noreferrer">Independent contractor agreement</a></span>
					</li>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1009&amp;Y=408e99eb92a4ad0dd2fe4318f2e60c0a"
								target="_blank" rel="noopener noreferrer">Deed of suretyship</a></span></li>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1007&amp;Y=ec237b4df290f97ca42fe489ec53602c"
								target="_blank" rel="noopener noreferrer">Acknowledgment of debt</a></span></li>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1025&amp;Y=263dc404ba0626775e1307282c92b5a7"
								target="_blank" rel="noopener noreferrer">Trust deed</a></span></li>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1012&amp;Y=3b531b0e7401d24737b33266d4ec7e45"
								target="_blank" rel="noopener noreferrer">General power of attorney</a></span></li>
					<li><span><a href="https://embed.originsystems.co.za/php/launch.php?ccof=PHL001&amp;ddfc=D1021&amp;Y=6cffbf28c747ec49ccbb42da4c273dff"
								target="_blank" rel="noopener noreferrer">Sale of motor vehicle</a></span></li>
				</ul>

			</div>

		</div>
	</div>
</div>