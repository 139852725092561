import { Component, OnInit, ViewChild } from '@angular/core';
import * as _moment from 'moment';
import { StorageMap } from '@ngx-pwa/local-storage';
import { GlobalDataService } from '../../../../shared/global-data';
import { PR_CODES } from '../../../../shared/enums';
import * as _util from '../../../../shared/utilities';
import { forkJoin } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { MatCalendar } from '@angular/material/datepicker';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

	hideme: any = {};
	calData: any = [];
	todayTitle: string = 'Today';
	today: string = _moment().format('dddd');
	caseFUSessions: any = [];
	nextSessionLocation: string = '';
	setType_global: string = 'eap';
	isLoading: boolean = true;
	noTodayCases: boolean = false;
	prCodes: Object = PR_CODES;
	widgetDate: any = _moment(new Date()).format('MMMM Do YYYY');
	isHoverActive: boolean = false;
	dataForWidget: any = [];
	daysOfWeek = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'];

	@ViewChild('calendar', {static: false}) calendar: MatCalendar<Date>;
	selectedDate = new Date();
	startAt = new Date();
	year: any;
	DayAndDate: string;	

	constructor(private lsService: StorageMap,
		private globalData: GlobalDataService) {
		this.getAllCalendarDates();
	}

	ngOnInit() {
		let that = this;
		this.globalData.tBlockStep.pipe(distinctUntilChanged()).subscribe(val => {
			if(val == 2){ // MANAGERIAL REF
				this.setType_global = 'managerial';
			} else if(val == 3){ // CISD
				this.setType_global = 'cisd';
			} else { // EMPLOYEE
				this.setType_global = 'eap';
			}
			this.getFromSource(this.setType_global);
			this.getAllCalendarDates();
			$('.has-date').removeClass('has-date');
		});

		// track calendar widget month-toggle buttons
		$("body").on("click", ".mat-icon-button", function(){
			that.trackMonth();
		});

		$("body").on("click",".mat-calendar-body-active", function(){
			let getDate = $(this).attr("aria-label");
			that.onSelect(new Date(getDate));
		});

	} /* end onInit */

	monthSelected(event){
		this.trackMonth();
	}

	trackMonth(){
		setTimeout(() => {
			let getCurrMonth = $(".mat-calendar-table").find("tbody > tr > td").eq(0).text();
			this.populateCalendar(getCurrMonth.toLowerCase());
		}, 400);
		this.calData.length = 0;
		this.noTodayCases = true;		
	}

	onSelect(event){
		this.widgetDate = _moment(event).format('MMMM Do YYYY');
		let jsDate = new Date(event);
		this.today = this.daysOfWeek[jsDate.getDay() -1];
		let getSelectedDateData = this.dataForWidget.filter(i => {
			let mom_startDate = _moment(i.StartDate).format('DD MMMM YYYY');
			let mom_selected = _moment(event).format('DD MMMM YYYY');
			if(mom_startDate == mom_selected){
				return i;
			}
		});
		if(getSelectedDateData.length > 0){
			this.getFromSource(this.setType_global);			
		} else {
			this.calData.length = 0;
			this.noTodayCases = true;
			this.isLoading = false;
		}
	}

	populateCalendar(currMonth){
		let allDates = this.dataForWidget.filter(item => {
			let startDate = item.StartDate.toLowerCase();
			if(startDate.indexOf(currMonth) > -1){
				return item;
			}
		});
		if(allDates.length > 0){ // populate dates with has-date class
			let datesOnly = allDates.map(i => new Date(i.StartDate).getDate().toString());
			$(".mat-calendar-body-cell-content").each(function(){
				if(datesOnly.indexOf($(this).text()) > -1){
					$(this).parent().addClass('has-date');
				}
			})			
		}
		this.isLoading = false;
	}

	myDateFilter = (d: Date): boolean => {
		const day = d.getDay();
		// Prevent Saturday and Sunday from being selected.
		return day !== 0 && day !== 6;
	}	

	getAllCalendarDates(){
		let that = this;
		this.lsService.watch(this.setType_global).subscribe(cDetails => {
			let caseDetsObservable: any = cDetails;
			if(typeof caseDetsObservable != "undefined"){
				let filterData = caseDetsObservable.filter(i => {
					if(i.body.results.length > 0){
						return i;
					}
				}).map(x => x.body.results);
				let mergedData = [].concat.apply([], filterData);
				this.dataForWidget = mergedData;
				that.trackMonth();
				that.onSelect(new Date());		
			} else {
				this.noTodayCases = true;
				this.isLoading = false;
			}
		});
	}

	getFromSource(caseDetailType){
		this.isLoading = true;
		this.calData.length = 0;
		this.noTodayCases = false;
	    /* Get MAIN Case data from LS and listen via Global Data state obs */
	    this.globalData.mainCaseData.subscribe(gsData => {
	    	this.lsService.get("maincases_"+caseDetailType).subscribe((mainCaseResp: any) => {
	    		if(typeof mainCaseResp !== 'undefined'){
			        this.lsService.get(caseDetailType).subscribe((caseDetailResp: any) => {
			            if(typeof caseDetailResp !== 'undefined'){
							let localData: any = caseDetailResp.map(i => i.body.results);
							let filteredData_ls = localData.reduce((filtered,item, index) => {
								if(item.length > 0){
									let getRecordByCurrDate = item.filter(d => _moment(d.StartDate).format('MMMM Do YYYY') == this.widgetDate);
									let res = getRecordByCurrDate;
									if(res.length > 0){
										let profileData = mainCaseResp.filter(r => r.RequestID == res[0].RelatedRequestID);
										filtered.push({
											idx: index,
											caseNo: profileData[0].TextFreeField3,
											location: res[0].TextFreeField1,
											RequestID: res[0].RequestID,
											StartDate: res[0].StartDate,
											serviceType: this.prCodes[profileData[0].ItemCode],
											Status: res[0].Status,
											CreatedDate: res[0].CreatedDate				
										});
									}
								}
								return filtered;
							}, []);
							if(filteredData_ls.length > 0){
								this.noTodayCases = false;
								this.calData = filteredData_ls.slice(0,2);
							} else {
								this.noTodayCases = true;
							}
							this.isLoading = false;
						} else {
							this.isLoading = false;
							this.noTodayCases = true;					
						}
			        }); /* end Case Detail LS call */
		        }
	    	}); /* end Main Case LS call */
	    });    	
	}

	checkLocation(index){
		let getIdxData = this.caseFUSessions[index];
		if(getIdxData.Status != 'REALIZED'){
			if(typeof getIdxData.TextFreeField1 != "undefined"){
				this.isHoverActive = true;
				this.nextSessionLocation = getIdxData.TextFreeField1;
			}
		}
	}

	deactivateActiveStates(){
		this.isHoverActive = false;		
	}

	getFollowUpData(itemIndex, RequestID){
		this.nextSessionLocation = '';
		this.caseFUSessions.length = 0;
		this.calData[itemIndex].hideme = !this.calData[itemIndex].hideme;
		if(this.calData[itemIndex].hideme){
			this.isLoading = true;
			this.lsService.watch(this.setType_global).subscribe(cDetails => {
				let formatData: any = cDetails;
				let getData = formatData.filter(c => {
					let res = c.body.results;
					if(res.length > 0){
						return res;			
					}
				})[0]["body"]["results"];
				let getReqIDdata = getData.filter(rId => rId.RelatedRequestID == RequestID);
				if(getReqIDdata.length > 0){
					this.caseFUSessions = getReqIDdata;
					// set Next Session location
					let openLocations = getReqIDdata.filter(l => l.Status != "REALIZED")[0];
					if(openLocations != null){
						this.nextSessionLocation = openLocations.TextFreeField1;
					}					
				}
				this.isLoading = false;
			});
		}
	}
}
