<!-- NEW EMBEDED REPORT DATA, eg for SAPS -->
<div class="reports" *ngIf="pData[0]?.isSaps">
		<div class="tab-container reports-tabs" [attr.data-class]="incomingType">
			<div class="tab-header">
				<div *ngFor="let label of tabNames; let i = index" 
					class="tab" 
					[attr.data-index]="i"
					[attr.data-name]="label.apiName">{{ label.labelName }}</div>
			</div>
		</div>
		<div class="embeded-container">
			<p class="red-error-text" *ngIf="reportTechError">
				*We could not load the report due to a technical error.
			</p>
			<p class="red-error-text" *ngIf="reportTechError">
				Please contact 0800111223 or email support.mhwellness@mhg.co.za to resolve the issue.
			</p>
			<div class="loader" *ngIf="reportIsLoading"></div>
			<div *ngIf="reportIsAvailable" class="fullscreen-button-container">
				<button class="btn" (click)="viewFullScreen()">View fullscreen</button>
			</div>
			<div [hidden]="reportIsLoading" 
				id="report-container"  
				#reportContainer></div>
		</div>
</div>

<!-- DISPLAY OLD PHELA REPORT LINK -->
<div *ngIf="pData[0]?.isSaps == false">
	<iframe width="800" height="600" src="https://dashboard.phelawellness.co.za/app/#Login" frameborder="0" allowFullScreen="true"></iframe>
</div>
