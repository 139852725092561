import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse,HttpEventType } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GlobalDataService } from '../../shared/global-data';
import * as Enums from '../../shared/enums';

const basicAuthHttpOptions = {
	headers: new HttpHeaders({
		'Content-Type':  'application/json',
		'Authorization': 'Basic ZXhjaGFuZ2U6ZXhjaGFuZ2VAMQ=='
	})
};

// fileData: File = null;
@Injectable({ providedIn: 'root' })
export class SecurityService {
	httpOptions: any = {};
	constructor(public http: HttpClient){}
	
	authenticate (authObj): Observable<any> {
		authObj["rememberMe"] = true;
		return this.http.post(Enums.API_BASE_PATHS + Enums.API_PATHS.AUTHENTICATE, JSON.stringify(authObj)).pipe(
			tap( success => console.log('success'),
   				error => console.log('authenticate did not work...API error!'))
		);
	}
	login (loginObj): Observable<any> {		
		return this.http.post(Enums.API_BASE_PATHS + Enums.API_PATHS.LOGIN, JSON.stringify(loginObj)).pipe(
			tap( success => console.log('success'),
   				error => console.log('login did not work...API error!'))
		);
	}
	getUserData (username): Observable<any> {
		let path = `?userName=${username}`;
		return this.http.get(Enums.API_BASE_PATHS + Enums.API_PATHS.SECURITY_USER + "/query" + path).pipe(
			tap( success => console.log('success'),
   				error => console.log('Get user data did not work...API error!'))
		);
	}
	registerUser (userObj): Observable<any> {		
		return this.http.post(Enums.API_BASE_PATHS + Enums.API_PATHS.SECURITY_USER + "/create", JSON.stringify(userObj)).pipe(
			tap( success => console.log('success'),
   				error => console.log('registerUser did not work...API error!'))
		);
	}
	decryptToken (token): Observable<any> {
		let path = `?token=${token}`;
		return this.http.get(Enums.API_BASE_PATHS + Enums.API_PATHS.DECRYPT_TOKEN + path).pipe(
			tap( success => console.log('success'),
   				error => console.log('Decrypt token did not work...API error!'))
		);
	}	
	setNewPassword (passwordRequestModel): Observable<any> {
		return this.http.patch(Enums.API_BASE_PATHS + Enums.API_PATHS.SECURITY_USER + "/resetpassword", JSON.stringify(passwordRequestModel)).pipe(
			tap( success => console.log('success'),
   				error => console.log('registerUser did not work...API error!'))
		);
	}
	resetPassword (passwordRequestModel): Observable<any> {	
		return this.http.patch(Enums.API_BASE_PATHS + Enums.API_PATHS.SECURITY_USER + "/changepassword", JSON.stringify(passwordRequestModel)).pipe(
			tap( success => console.log('success'),
   				error => console.log('registerUser did not work...API error!'))
		);
	}
	forgotPassword (username): Observable<any> {
		let path = `/forget?userName=${username}`;
		return this.http.get(Enums.API_BASE_PATHS + Enums.API_PATHS.SECURITY_USER + path).pipe(
			tap( success => console.log('success'),
   				error => console.log('forgot password did not work...API error!'))
		);
	}	
	unlockAccount (uniqueId): Observable<any> {
		let path = `/unlock/${uniqueId}`;	
		return this.http.patch(Enums.API_BASE_PATHS + Enums.API_PATHS.SECURITY_USER + path, {}).pipe(
			tap( success => console.log('success'),
   				error => console.log('unlock account did not work...API error!'))
		);
	}
	authenticateHLC (authObj): Observable<any> {
		//authObj["rememberMe"] = true;
		let httpOption = {
			headers: new HttpHeaders({
				'Access-Control-Allow-Origin':'*',
				'Access-Control-Allow-Methods':'*',
				'Access-Control-Allow-Headers':'*'
			})
		}
		return this.http.post(Enums.API_HLC_PATHS + Enums.API_PATHS.POST_HLC_TOKEN, authObj,httpOption).pipe(
			tap( success => console.log('success'),
   				error => console.log('authenticate did not work...API error!'))
		);
	}
	goToHLC (obj:any,token:string): Observable<any> {
		//authObj["rememberMe"] = true;
		
		let httpHead = {
			headers: new HttpHeaders({
				'Access-Control-Allow-Origin':'*',
				'Access-Control-Allow-Methods':'*',
				'Access-Control-Allow-Headers':'*',
				'Authkey':token
			})
		}
		return this.http.post(Enums.API_HLC_PATHS + Enums.API_PATHS.POST_HLC_REDIRECT , JSON.stringify(obj), httpHead).pipe(
			tap( success => console.log('success'),
   				error => console.log('goToHLC...API error!'))
		);
	}	
	private handleError<T> (operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			//this.logException(Enums.LEVELS.ERROR, operation + ':' + error.message);
			return of(result as T);
		};
	}
	private handleAuthError(err: any): Observable<HttpErrorResponse> {
		throw err;
	}	
	private log(message: string) {
		//console.log(`${message}`);
	}	
}